import PropTypes from "prop-types";
// @mui
import {
  Stack,
  InputAdornment,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Label from "../../../../components/label";
// components
import { useForm } from "react-hook-form";
import Iconify from "../../../../components/iconify";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  subSectApi,
  staffNameApi,
  assignStaffNameApi,
  getCityListAPI,
  gotraApi,
  getDetailList,
  getCountryListAPI,
} from "src/service";
import { useAuthContext } from "src/auth/useAuthContext";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
// ----------------------------------------------------------------------

UserTableToolbar.propTypes = {
  isFiltered: PropTypes.bool,
  filterName: PropTypes.string,
  filterRole: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterRole: PropTypes.func,
  onResetFilter: PropTypes.func,
  optionsRole: PropTypes.arrayOf(PropTypes.string),
};

export default function UserTableToolbar({
  isFiltered,
  filterRole,
  filter,
  loading,
  setFilter,
  optionsRole,
  onFilterName,
  onFilterRole,
  onResetFilter,
  totalUsers,
  filterDetail,
  staffNumber,
  handleFilterApply,
}) {
  const [startId, setStartId] = useState("");
  const [endId, setEndId] = useState("");
  const contxt = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [subsect, setSubsect] = useState([]);
  const [valueFilter, setValueFilter] = useState(null);
  const [show, setShow] = useState(false);
  const [staffName, setstaffName] = useState([]);
  const [assignStaffName, setAssignStaffName] = useState([]);
  const [callStaffName, setCallStaffName] = useState([]);
  const [gotraList, setGotraList] = useState([]);
  const [staffActive, setStaffActive] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [selectedDateTime2, setSelectedDateTime2] = useState();
  const [selectedDateTimeA, setSelectedDateTimeA] = useState();
  const [selectedDateTime2A, setSelectedDateTime2A] = useState();
  const [selectedDateTimeStart, setSelectedDateTimeStart] = useState();
  const [selectedDateTimeEnd, setSelectedDateTimeEnd] = useState();
  const [selectedDateTimeUSStart, setSelectedDateTimeUSStart] = useState();
  const [selectedDateTimeUEEnd, setSelectedDateTimeUEEnd] = useState();
  const [cityList, setCityList] = useState([]);
  const [incomeList, setIncomeList] = useState([]);
  const [heightOpt, setHeightOpt] = useState([]);
  const [Sect, setSectOpt] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [email, setEmail] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openOptions, setOpenOptions] = useState(false);
  const handleOptionsOpen = () => setOpenOptions(true);
  const handleOptionsClose = () => setOpenOptions(false);
  const timerRef = useRef(null);
  const navigate = useNavigate();
  const validateDates = () => {
    if (new Date(selectedDateTimeUSStart) > new Date(selectedDateTimeUEEnd)) {
      setError("End Date for US should be after Start Date for US.");
      enqueueSnackbar("End Date for US should be after Start Date for US.", {
        variant: "error",
      });
      return false;
    }
    if (new Date(selectedDateTime) > new Date(selectedDateTime2)) {
      setError("End Date should be after Start Date.");
      enqueueSnackbar("End Date should be after Start Date.", {
        variant: "error",
      });
      return false;
    }
    if (new Date(selectedDateTimeA) > new Date(selectedDateTime2A)) {
      setError("End Verify Date should be after Start Verify Date.");
      enqueueSnackbar("End Verify Date should be after Start Verify Date.", {
        variant: "error",
      });
      return false;
    }
    if (new Date(selectedDateTimeStart) > new Date(selectedDateTimeEnd)) {
      setError("End UADate should be after Start UADate.");
      enqueueSnackbar("End UADate should be after Start UADate.", {
        variant: "error",
      });
      return false;
    }

    setError("");
    return true;
  };

  //padhadhikari pages
  const openPaymentRequestPage = () => {
    navigate("/padhadhikariPayReqHistory");
  };

  const openTransactionHistoryPage = () => {
    navigate("/padhadhikariTranHistory");
  };

  const handlefilterValueChange = (e) => {
    const newValue = e.target.value;
    setValueFilter(newValue);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      onFilterName(e);
    }, 200);
  };

  const getCountryOption = async () => {
    const response = await getCountryListAPI();
    setCountryOption(response?.data?.data);
  };

  useEffect(() => {
    getDetail();
    getCountryOption();
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (validateDates()) {
      setFilter({
        ...filter,
        startUEDate: selectedDateTimeUSStart,
        endUEDate: selectedDateTimeUEEnd,
        startDate: selectedDateTime,
        startVerifyDate: selectedDateTimeA,
        endDate: selectedDateTime2,
        endVerifyDate: selectedDateTime2A,
        endUADate: selectedDateTimeEnd,
        startUADate: selectedDateTimeStart,
      });
    }
  }, [
    selectedDateTimeUSStart,
    selectedDateTimeUEEnd,
    selectedDateTime,
    selectedDateTimeA,
    selectedDateTime2,
    selectedDateTime2A,
    selectedDateTimeEnd,
    selectedDateTimeStart,
  ]);

  const jainType = [
    { label: "Maheswari Jain", value: "Maheswari Jain" },
    { label: "Digamber Jain", value: "Digamber Jain" },
    { label: "Shwetamber Jain", value: "Shwetamber Jain" },
    { label: "Taran tarn", value: "Taran tarn" },
    { label: "Khandelwal Jain", value: "Khandelwal Jain" },
    { label: "Agrawal Jain", value: "Agrawal Jain" },
    { label: "Gujrati Jain", value: "Gujrati Jain" },
    { label: "Marwadi Jain", value: "Marwadi Jain" },
    { label: "Baniya Jain", value: "Baniya Jain" },
    { label: "Saraf Jain", value: "Saraf Jain" },
    { label: "Gupta Jain", value: "Gupta Jain" },
    { label: "Other Cast", value: "Other Cast" },
  ];

  const notJain = [
    { label: "Hindu", value: "Hindu" },
    { label: "Muslim", value: "Muslim" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Sindhi", value: "Sindhi" },
    { label: "Isai", value: "Isai" },
  ];

  const specialCase = [
    // { label: "Surajmukhi", value: "Surajmukhi" },
    { label: "Out of india ", value: "Out of india " },
    // { label: "High profile", value: "High profile" },
    { label: "Divyang", value: "Divyang" },
    {
      label: "2nd Marriage (Divorce,widow)",
      value: "2nd Marriage (Divorce,widow)",
    },
    {
      label: "Not working(10thpass , 12thpass, House Wife)",
      value: "Not working(10thpass , 12thpass, House Wife)",
    },
  ];

  const ageArray = [];
  for (let index = 14; index < 81; index++) {
    ageArray.push({ label: index + " yrs", value: index });
  }
  const users = [
    { label: "all", value: "all" },
    { label: "Test Users", value: "test-users" },
    { label: "Real Users", value: "real-users" },
  ];
  const days = [
    { label: "all", value: "all" },
    { label: "paid", value: "paid" },
    { label: "unpaid", value: "unpaid" },
    { label: "today", value: "today" },
    { label: "yesterday", value: "yesterday" },
    { label: "Before Yesterday", value: "beforeYesterday" },
    { label: "Last 3 Days", value: "lastThreeDays" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },
  ];
  const approveArry = [
    { label: "all", value: "all" },
    { label: "Approved", value: "approved" },
    { label: "Not Approved", value: "not-approved" },
    { label: "View", value: "live" },
    { label: "Not View", value: "not-live" },
  ];
  const gender = [
    { label: "all", value: "all" },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const name_search = [
    { label: "all", value: "all" },
    { label: "With Name", value: "with name" },
    { label: "Without Name", value: "without name" },
  ];

  const maritalStatus = [
    { label: "all", value: "all" },
    { label: "Unmarried", value: "Unmarried" },
    { label: "Widow/widower", value: "Widow/Widower" },
    { label: "Divorced", value: "Divorced" },
    { label: "Awaiting divorce", value: "Awaiting divorce" },
  ];
  const physicalStatus = [
    { label: "all", value: "all" },
    { label: "Normal", value: "narmal" },
    { label: "Basic Physical Problem", value: "basicPhysicalProblem" },
    { label: "Some Medical Problem", value: "someMedicalProblem" },
    { label: "Physically Challenged", value: "physicallyChallenged" },
  ];
  const lastActive = [
    { label: "Last 15 Days", value: "15Days" },
    { label: "Last 1 Month", value: "1Month" },
  ];
  const ratings = [
    { label: "all", value: "all" },
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    // { label: "5", value: "5" },
    // { label: "6", value: "6" },
  ];
  const call = [
    { label: "all", value: "all" },
    { label: "call", value: "for_call" },
    { label: "no call", value: "no_call" },
    { label: "Not-Connected", value: "not_connected" },
    { label: "Not-Activity", value: "no_activity" },
  ];
  const app_id = [
    { label: "App_id", value: "not_null" },
    { label: "Null", value: "null" },
  ];
  const payment = [
    { label: "all", value: "all" },
    { label: "Payment", value: "done" },
    { label: "No Payment", value: "not_done" },
  ];
  const dosh = [
    { label: "all", value: "all" },
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
    { label: "Ansik Mangal", value: "Anshik" },
    { label: "DON'T KNOW", value: "DON`T KNOW" },
  ];
  const dataAssign = [
    { label: "Assigned Data", value: "assign" },
    { label: "Not Assigned Data", value: "not_assign" },
  ];

  const NonIndian = [{ label: "Non-Indian", value: "true" }];

  const methods = useForm({});

  useEffect(() => {
    getstaffName();
    getAssignStaffName("");
    // DiffrentUser();
  }, []);

  const { watch, getValues } = methods;

  const selectSubSect = async (sub) => {
    let res = await subSectApi(sub);
    if (res?.data?.status) {
      setSubsect(res?.data?.data);
    }
  };

  const getGotraBySubsect = async (data) => {
    const res = await gotraApi(data);
    setGotraList(res?.data?.data);
  };

  const getcityList = async (value) => {
    let arr = [];
    let res = await getCityListAPI(value);
    res?.data?.data?.forEach((city) => {
      arr.push({ english: city?.name_english, value: city?.name_english });
    });
    setCityList(arr);
  };

  const getstaffName = async () => {
    var res = await staffNameApi();
    const staffNamesArray = res?.data?.data || [];
    const newStaffObject = {
      mobile: null,
      id: "",
      nameEnglish: "No Staff",
      nameHindi: null,
    };
    staffNamesArray.unshift(newStaffObject);
    setstaffName(staffNamesArray);
  };

  const getAssignStaffName = async () => {
    var res = await assignStaffNameApi();
    const sortedDataArray = res?.data?.data?.sort((a, b) => {
      if (!a.nameEnglish) return 1;
      if (!b.nameEnglish) return -1;
      return a.nameEnglish.localeCompare(b.nameEnglish);
    });
    setAssignStaffName(sortedDataArray);
    setCallStaffName(sortedDataArray);
    setStaffActive(sortedDataArray);
  };

  const getDetail = async () => {
    let res = await getDetailList();
    setIncomeList(res?.data?.data?.incomeList?.english);
    setHeightOpt(res?.data?.data?.height?.english);
    setSectOpt(res?.data?.data?.sects);
  };

  return (
    <>
      {filterDetail && (
        <>
          <div>
            <Stack
              style={{
                position: "fixed",
                width: "-webkit-fill-available",
                top: 60,
                background: "#fff",
                zIndex: 999999,
                padding: "14px 10px 10px 10px",
              }}
              spacing={2}
              alignItems="center"
              direction={{
                xs: "column",
                sm: "row",
              }}
              sx={{ px: 0, pb: 1, mt: -1 }}
            >
              {contxt?.user?.sections?.OnlineFilter["Start I'd & end I'd."] && (
                <Grid item lg={3} xs={6} md={3} sm={6}>
                  <TextField
                    value={startId}
                    label="Name"
                    onChange={(e) => {
                      setStartId(e.target.value);
                      setFilter({ ...filter, startId: e.target.value });
                    }}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              )}

              {contxt?.user?.sections?.OnlineFilter["Start I'd & end I'd."] && (
                <Grid item lg={3} xs={6} md={3} sm={6}>
                  <TextField
                    value={endId}
                    label="Email ID"
                    onChange={(e) => {
                      setEndId(e.target.value);
                      setFilter({ ...filter, endId: e.target.value });
                    }}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              )}

              {contxt?.user?.sections?.OnlineFilter["Start I'd & end I'd."] && (
                <Grid item lg={3} xs={6} md={3} sm={6}>
                  <TextField
                    value={endId}
                    label="Mobie No."
                    onChange={(e) => {
                      setEndId(e.target.value);
                      setFilter({ ...filter, endId: e.target.value });
                    }}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              )}
              {contxt?.user?.sections?.OnlineFilter["Start I'd & end I'd."] && (
                <Grid item lg={3} xs={6} md={3} sm={6}>
                  <TextField
                    value={endId}
                    label="Passoword"
                    onChange={(e) => {
                      setEndId(e.target.value);
                      setFilter({ ...filter, endId: e.target.value });
                    }}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              )}
              <Button
                variant="contained"
                color="success"
                startIcon={<AddIcon />}
                sx={{
                  padding: "5px 15px",
                  fontSize: "14px",
                  borderRadius: "4px",
                  backgroundColor: "#4caf50",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                  boxShadow: "none",
                  minWidth: "auto",
                }}
              >
                Add
              </Button>

              {contxt?.user?.sections?.OnlineFilter["Search"] && (
                <div style={{ position: "relative", width: "50%" }}>
                  <TextField
                    fullWidth
                    value={valueFilter}
                    // value={filterName}
                    onChange={handlefilterValueChange}
                    // onChange={onFilterName}
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <Iconify
                              icon="eva:search-fill"
                              sx={{ color: "text.disabled" }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  <Label
                    sx={{ position: "absolute", right: 10, top: 14 }}
                    onClick={onResetFilter}
                  >
                    {/* {totalUsers} */}
                  </Label>
                </div>
              )}
              {isFiltered && (
                <Button
                  color="error"
                  sx={{ flexShrink: 0 }}
                  onClick={onResetFilter}
                  startIcon={<Iconify icon="mdi:cancel-circle-outline" />}
                >
                  Clear
                </Button>
              )}
            </Stack>
          </div>
          <div style={{ padding: "10px 15px 5px 15px" }}>
            <Stack
              spacing={2}
              alignItems="center"
              direction={{
                xs: "column",
                sm: "row",
              }}
              sx={{ px: 0 }}
            >
              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    native
                    fullWidth
                    select
                    name=""
                    label="Time"
                    onChange={(e) => {
                      setFilter({ ...filter, other_cast_type: e.target.value });
                    }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                  ></TextField>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    native
                    fullWidth
                    select
                    name=""
                    label="Gender"
                    onChange={(e) => {
                      setFilter({ ...filter, other_cast_type: e.target.value });
                    }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                  ></TextField>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    native
                    fullWidth
                    select
                    name=""
                    label="Payment"
                    onChange={(e) => {
                      setFilter({ ...filter, other_cast_type: e.target.value });
                    }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                  ></TextField>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    native
                    fullWidth
                    select
                    name=""
                    label="State"
                    onChange={(e) => {
                      setFilter({ ...filter, other_cast_type: e.target.value });
                    }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                  ></TextField>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    native
                    fullWidth
                    select
                    name=""
                    label="City"
                    onChange={(e) => {
                      setFilter({ ...filter, other_cast_type: e.target.value });
                    }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                  ></TextField>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    native
                    fullWidth
                    select
                    name=""
                    label="Business Time"
                    onChange={(e) => {
                      setFilter({ ...filter, other_cast_type: e.target.value });
                    }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                  ></TextField>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    native
                    fullWidth
                    select
                    name=""
                    label="Call Status"
                    onChange={(e) => {
                      setFilter({ ...filter, other_cast_type: e.target.value });
                    }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                  ></TextField>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    native
                    fullWidth
                    select
                    name=""
                    label="Age"
                    onChange={(e) => {
                      setFilter({ ...filter, other_cast_type: e.target.value });
                    }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                  ></TextField>
                </Grid>
              </Grid>
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleOpen}
                sx={{ typography: "body1", py: 2, mt: 1, mr: 1 }}
              >
                Schedule Meeting
              </Button>

              <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ backgroundColor: "red", color: "white" }}>
                  Schedule meeting
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: "white",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Heading"
                        placeholder="Eg- First Interview round"
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Candidate Name"
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth label="Date" margin="dense" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth label="Time" margin="dense" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Address"
                        multiline
                        rows={3}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Other Note"
                        multiline
                        rows={3}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: "red", color: "white", py: 1.5 }}
                    onClick={handleOptionsOpen}
                  >
                    Send
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openOptions}
                onClose={handleOptionsClose}
                maxWidth="sm"
                fullWidth
              >
                <DialogContent sx={{ textAlign: "center", pb: 4 }}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={whatsapp}
                            onChange={(e) => setWhatsapp(e.target.checked)}
                          />
                        }
                        label="Whatsapp"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={email}
                            onChange={(e) => setEmail(e.target.checked)}
                          />
                        }
                        label="Email"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      mt: 2,
                      py: 1.5,
                    }}
                    onClick={handleOptionsClose}
                  >
                    Send
                  </Button>
                </DialogContent>
              </Dialog>
              <Button
                variant="contained"
                size="small"
                onClick={openPaymentRequestPage}
                sx={{ typography: "body1", py: 2, mt: 1, mr: 1 }}
              >
                Payment Request
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={openTransactionHistoryPage}
                sx={{ typography: "body1", py: 2, mt: 1, mr: 1 }}
              >
                Transaction History
              </Button>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleFilterApply}
                sx={{ typography: "body1", py: 2, mt: 1 }}
              >
                Apply Filter
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
