import { DeleteOutline, SearchRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { useAuthContext } from "src/auth/useAuthContext";
import Label from "src/components/label";
import constant from "src/Constant";
import ScannerBioDataUserCard from "src/sections/@dashboard/user/cards/ScannerBiodataUserCard";
import {
  deleteBiodataHinduPy,
  deleteBiodataPy,
  finalBiodataHinduPy,
  finalBiodataPy,
  getCityListAPI,
  getCountryListAPI,
  getDetailList,
  getHinduSectsByStateIdApi,
  getHinduSubSectsBySectIdApi,
  getStateListAPI,
  gptBiodataHinduPy,
  gptBiodataPy,
  mobileCheckPy,
  scanBiodataHinduPy,
  scanBiodataPy,
  subSectApi,
  updateMobileBiodataPy,
  updateScannerForm,
  updateShadiDoneStatus,
  viewBiodataByIdPy,
  viewBiodataByIdPyHindu,
} from "src/service";

const disability = [
  { id: 1, name: "Normal" },
  { id: 2, name: "Some Medical Problem" },
  { id: 3, name: "Physically Challenged" },
];

const maritalStatus = [
  { id: 1, name: "Unmarried" },
  { id: 2, name: "Widow/widower" },
  { id: 3, name: "Divorce" },
  { id: 4, name: "Awiting divorce" },
];

const ViewScannerDetails = () => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({});
  const [filterDetail, setFilterDetail] = useState({});
  const [mobileNumbers, setMobileNumbers] = useState([""]);
  const [cityList, setCityList] = useState([]);
  const [prefoundData, setPrefoundData] = useState({
    jain_data: [],
    hindu_data: [],
  });
  const [prefoundDataCount, setPrefoundDataCount] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [subSectList, setSubSectList] = useState([]);
  const [isNumberChecking, setIsNumberChecking] = useState(false);
  const [permission] = useState(
    user?.sections?.[`${type === "jain" ? "ScannerData" : "HinduScannerData"}`]
  );

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    gender: "",
    profession: "",
    education: [],
    community: "",
    height: "",
    motherTongue: "",
    income: "",
    sub_community: "",
    manglik: "DONT KNOW",
    country: "India",
    state: "Madhya Pradesh",
    city: "",
    maritalStatus: "Unmarried",
    physicalStatus: "Normal",
    dob: "",
  });

  const getDetails = useCallback(async () => {
    try {
      if (type === "jain") {
        const res = await viewBiodataByIdPy(id);
        if (res?.data?.status) {
          setData(res?.data?.data);
        }
      } else {
        const res = await viewBiodataByIdPyHindu(id);
        if (res?.data?.status) {
          setData(res?.data?.data);
          setMobileNumbers(
            res?.data?.data?.mobiles ? res?.data?.data?.mobiles?.split(",") : []
          );
          setFormData({
            id: res?.data?.data?.id,
            name: res?.data?.data?.name,
            gender: res?.data?.data?.gender,
            profession: res?.data?.data?.employed_in,
            education: res?.data?.data?.education?.split(","),
            sect: res?.data?.data?.community,
            subSect: res?.data?.data?.sub_community,
            manglik: res?.data?.data?.manglik
              ? res?.data?.data?.manglik
              : "DONT KNOW",
            country: res?.data?.data?.country
              ? res?.data?.data?.country
              : "India",
            state: res?.data?.data?.state
              ? res?.data?.data?.state
              : "Madhya Pradesh",
            city: res?.data?.data?.city,
            maritalStatus: res?.data?.data?.marital_status
              ? res?.data?.data?.marital_status
              : "Unmarried",
            physicalStatus: res?.data?.data?.physical_status
              ? res?.data?.data?.physical_status
              : "Normal",
            dob: res?.data?.data?.dob === "-" ? 0 : res?.data?.data?.dob,
            height: res?.data?.data?.height,
            motherTongue: res?.data?.data?.mother_tongue,
            income: res?.data?.data?.income,
          });
        }
      }
    } catch (error) {
      console.error("error ::::", error);
    }
  }, [id, type]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const getCountryList = useCallback(async () => {
    let res = await getCountryListAPI();
    setCountryList(res?.data?.data);
  }, []);

  useEffect(() => {
    getCountryList();
  }, [getCountryList]);

  const getStateList2 = useCallback(async () => {
    var filterDetails = await getDetailList();
    let stateList = await getStateListAPI("India");
    setFilterDetail({
      ...filterDetails?.data?.data,
      stateList: stateList?.data.data,
      sects: type === "hindu" ? [] : filterDetails?.data?.data?.sects,
    });
  }, [type]);

  useEffect(() => {
    getStateList2();
  }, [getStateList2]);

  const getCityList = async (val) => {
    let res = await getCityListAPI(val);
    setCityList(res?.data?.data);
  };

  const getHinduCommunityList = async (val) => {
    const stateId =
      filterDetail?.stateList?.find((item) => item.english === val)?.id || null;
    if (!stateId) return;
    let res = await getHinduSectsByStateIdApi(stateId);
    let sect = [];
    res?.data?.data.forEach((item) => {
      sect.push({
        id: item.id,
        english: item.name_english,
        hindi: item.name_english,
        master_status: true,
      });
    });
    setFilterDetail({ ...filterDetail, sects: sect });
  };

  const getHinduSubCommunity = async (sectId) => {
    const sect =
      filterDetail?.sects?.find((item) => item.english === sectId)?.id || null;
    const res = await getHinduSubSectsBySectIdApi(sect);
    let subCommunity = [];
    res?.data?.data?.forEach((item) => {
      subCommunity.push({
        id: item.id,
        english: item.name_english,
        hindi: item.name_english,
      });
    });
    setSubSectList(subCommunity);
  };

  const getSubSect = async (sub) => {
    let res = await subSectApi(sub);
    if (res?.data?.status) {
      setSubSectList(res?.data?.data);
    }
  };

  useEffect(() => {
    if (formData?.community) {
      if (type === "hindu") getHinduSubCommunity(formData?.community);
      if (type === "jain") getSubSect(formData?.community);
    }
  }, [formData?.community]);

  useEffect(() => {
    if (formData.state) {
      getCityList(formData.state);
      if (type === "hindu") getHinduCommunityList(formData.state);
    }
  }, [formData.state, type]);

  const checkMobileNumber = async (number) => {
    setIsNumberChecking(true);
    try {
      const res = await mobileCheckPy(number);
      if (res?.data?.status) {
        setPrefoundData(res?.data?.data?.result);
        const count =
          res?.data?.data?.result?.jain_data?.length +
          res?.data?.data?.result?.hindu_data?.length;
        setPrefoundDataCount(count);
      }
    } catch (error) {
      console.error("error ::::", error);
    } finally {
      setIsNumberChecking(false);
    }
  };

  useEffect(() => {
    if (
      mobileNumbers.length > 0 &&
      mobileNumbers[0] &&
      mobileNumbers[0] !== "-"
    ) {
      const timer = setTimeout(() => {
        checkMobileNumber(mobileNumbers[0]);
      }, 1200);

      return () => clearTimeout(timer);
    }
  }, [mobileNumbers?.length, mobileNumbers]);

  const addMobileField = () => {
    setMobileNumbers([...mobileNumbers, ""]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEducation = (event, value) => {
    setFormData((prev) => ({
      ...prev,
      education: value,
    }));
  };

  const handleChange = (index, value) => {
    const updatedNumbers = [...mobileNumbers];
    updatedNumbers[index] = value;
    setMobileNumbers(updatedNumbers);
  };

  const removeMobileField = (index) => {
    const updatedNumbers = mobileNumbers.filter((_, i) => i !== index);
    setMobileNumbers(updatedNumbers);
  };

  const handleSubmit = async (submit_type) => {
    try {
      const formObj = {
        id: id,
        name: formData?.name,
        gender: formData?.gender,
        dob: formData?.dob,
        education: formData?.education?.join(","),
        marital_status: formData?.maritalStatus,
        physical_status: formData?.physicalStatus,
        community: formData?.community,
        sub_community: formData?.sub_community,
        manglik: formData?.manglik,
        country: formData?.country,
        state: formData?.state,
        city: formData?.city,
        height: formData?.height,
        mother_tongue: formData?.motherTongue,
        income: formData?.income,
        employed_in: formData?.profession,
        mobiles: mobileNumbers?.join(","),
        update_staff_id: user?.id,
        update_staff_name: user?.nameEnglish,
        update_staff_email: user?.email,
        submit_type: submit_type,
      };

      const res = await updateScannerForm(formObj, type);
      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.message);
        navigate(-1);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      console.error("error ::::", error);
    }
  };

  const handleScan = async () => {
    try {
      enqueueSnackbar("Scanning...");
      const res =
        type === "hindu"
          ? await scanBiodataHinduPy({ ids: [Number(id)] })
          : await scanBiodataPy({ ids: [Number(id)] });
      enqueueSnackbar(res?.data?.message);
      getDetails();
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleGpt = async () => {
    try {
      enqueueSnackbar("Gpt Scanning...");
      const res =
        type === "hindu"
          ? await gptBiodataHinduPy({ ids: [Number(id)] })
          : await gptBiodataPy({ ids: [Number(id)] });
      enqueueSnackbar(res?.data?.message);
      getDetails();
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleFinal = async () => {
    try {
      enqueueSnackbar("Final Scanning...");
      const res =
        type === "hindu"
          ? await finalBiodataHinduPy({ ids: [Number(id)] })
          : await finalBiodataPy({ ids: [Number(id)] });
      enqueueSnackbar(res?.data?.message);
      getDetails();
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleUpdateByDescription = async () => {
    try {
      enqueueSnackbar("Description Scanning...");
      const res = await updateMobileBiodataPy(type, {
        ids: [Number(id)],
      });
      getDetails();
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleUpdateShadiDoneStatus = async () => {
    try {
      const res = await updateShadiDoneStatus(type, {
        ids: [Number(id)],
      });

      getDetails();
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      const res =
        type === "hindu"
          ? await deleteBiodataHinduPy({ ids: [id] })
          : await deleteBiodataPy({ ids: [id] });
      enqueueSnackbar(res?.data?.message);
      navigate(-1);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  return (
    <>
      <Helmet>
        <title>{type === "jain" ? "JAIN" : "HINDU"} : Biodata</title>
      </Helmet>
      <Card sx={{ position: "relative", padding: 3 }}>
        <CardHeader
          style={{ padding: "10px 20px" }}
          title={<Typography variant="h4">Biodata Details Updating</Typography>}
          action={
            <Button
              variant="contained"
              onClick={() => {
                window.close();
              }}
            >
              Back
            </Button>
          }
        />
        <CardContent sx={{ border: "1px solid black", borderRadius: "10px" }}>
          <Grid container spacing={2}>
            {/* image */}
            <Grid item md={5} lg={5} xs={12} padding={2}>
              <Stack spacing={2} direction={"column"}>
                <img
                  src={
                    type === "jain"
                      ? `${constant.scannerUrl}/images/${data?.jain_image?.image}`
                      : `${constant.scannerUrl}/images/${data?.hindu_image?.image}`
                  }
                  alt=""
                />
                {!permission?.WorkFromHomeStaff && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{ my: 1 }}
                      size="small"
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ my: 1 }}
                      size="small"
                      color="info"
                      onClick={handleScan}
                    >
                      Scan
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ my: 1 }}
                      size="small"
                      color="inherit"
                      onClick={handleGpt}
                    >
                      Gpt
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ my: 1 }}
                      size="small"
                      color="success"
                      onClick={handleFinal}
                    >
                      Final Update
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ my: 1 }}
                      color="error"
                      onClick={handleUpdateByDescription}
                    >
                      Update Mobile By Description
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      style={{ margin: "5px" }}
                      onClick={handleUpdateShadiDoneStatus}
                    >
                      Move to shadi done
                    </Button>
                  </Box>
                )}
              </Stack>
            </Grid>

            {/* prefetchedData */}
            <Grid item md={4} lg={4} xs={12}>
              <Typography
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: 1,
                  fontWeight: "bold",
                  textAlign: "center",
                  padding: 1,
                  mb: 1,
                }}
                variant="body1"
              >
                Gpt/Final update form
              </Typography>
              <Stack spacing={2} direction={"column"}>
                <Typography variant="body1">
                  NAME:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.name ? data?.name : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  GENDER:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.gender ? data?.gender : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  COMMUNITY:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.community ? data?.community : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  SUB COMMUNITY:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.sub_community ? data?.sub_community : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  DATA TYPE:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.data_type ? data?.data_type : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  EDUCATION:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.education ? data?.education : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  EMPLOYEDIN:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.employed_in ? data?.employed_in : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  DOB:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.dob ? data?.dob : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  MOTHERTONGUE:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.mother_tongue ? data?.mother_tongue : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  INCOME:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.income ? data?.income : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  FAMILY STATUS:-{" "}
                  <span style={{ color: "#da0302" }}>{"N/A"}</span>
                </Typography>
                <Typography variant="body1">
                  HEIGHT:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.height ? data?.height : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  MANGLIK:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.manglik ? data?.manglik : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  MARITAL STATUS:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.marital_status ? data?.marital_status : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  MOBILES:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.mobiles ? data?.mobiles : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  PHYSICAL STATUS:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.physical_status ? data?.physical_status : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  SPECIAL CASE:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.special_case ? data?.special_case : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  STATE:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.state ? data?.state : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  CITY:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.city ? data?.city : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  COUNTRY:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.country ? data?.country : "N/A"}
                  </span>
                </Typography>
                <p>
                  DESCRIPTION:-{" "}
                  <span style={{ color: "#da0302" }}>
                    {data?.description ? data?.description : "N/A"}
                  </span>
                </p>
              </Stack>
            </Grid>

            {/* form */}
            <Grid item md={3} lg={3} xs={12}>
              <Typography
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: 1,
                  fontWeight: "bold",
                  textAlign: "center",
                  padding: 1,
                  mb: 1,
                }}
                variant="body1"
              >
                Manual update form
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {mobileNumbers.map((number, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      position: "relative",
                    }}
                  >
                    <TextField
                      label="Mobile Number"
                      variant="outlined"
                      value={number}
                      onChange={(e) => handleChange(index, e.target.value)}
                      fullWidth
                    />
                    {index === 0 && (
                      <span
                        style={{
                          position: "absolute",
                          right: 5,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Label sx={{ mx: 1 }}>
                          {isNumberChecking ? (
                            <CircularProgress size={20} />
                          ) : (
                            prefoundDataCount
                          )}
                        </Label>
                        <IconButton
                          color="error"
                          sx={{
                            border: "1px solid red",
                            borderRadius: "50%",
                            padding: "2px 5px",
                          }}
                          onClick={() =>
                            checkMobileNumber(mobileNumbers[index])
                          }
                        >
                          <SearchRounded />
                        </IconButton>
                      </span>
                    )}

                    {index > 0 && (
                      <IconButton
                        color="error"
                        onClick={() => removeMobileField(index)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    )}
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  onClick={addMobileField}
                >
                  Add Number
                </Button>

                <TextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={formData?.name}
                  onChange={handleInputChange}
                  fullWidth
                />

                <TextField
                  select
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  label="Gender"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </TextField>

                <Autocomplete
                  freeSolo
                  value={formData.profession}
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "profession", value: newValue },
                    })
                  }
                  options={
                    filterDetail?.employedIn?.english?.map(
                      (opt) => opt?.label
                    ) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Profession"
                      name="profession"
                    />
                  )}
                />

                <Autocomplete
                  freeSolo
                  multiple
                  options={
                    filterDetail?.education?.map((opt) => opt?.name_english) ||
                    []
                  }
                  value={
                    Array.isArray(formData?.education)
                      ? formData?.education
                      : []
                  }
                  onChange={(event, value) => handleEducation(event, value)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} style={{ marginRight: 8 }} />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Education"
                      placeholder="Select education"
                    />
                  )}
                />

                <TextField
                  select
                  name="manglik"
                  value={formData.manglik}
                  onChange={handleInputChange}
                  label="Manglik"
                >
                  {filterDetail?.doshList?.english?.map((opt) => (
                    <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  label="Country"
                >
                  {countryList?.map((opt) => (
                    <MenuItem value={opt?.name_english}>
                      {opt?.name_english}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  label="State"
                >
                  {filterDetail?.stateList?.map((opt) => (
                    <MenuItem value={opt?.english}>{opt?.english}</MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  freeSolo
                  value={formData.city}
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "city", value: newValue },
                    })
                  }
                  options={cityList?.map((opt) => opt?.name_english) || []}
                  renderInput={(params) => (
                    <TextField {...params} label="City" name="city" />
                  )}
                />

                <Autocomplete
                  freeSolo
                  value={formData.community}
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "community", value: newValue },
                    })
                  }
                  options={
                    filterDetail?.sects?.map((opt) => opt?.english) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Community"
                      name="community"
                    />
                  )}
                />

                <Autocomplete
                  freeSolo
                  value={formData.sub_community}
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "sub_community", value: newValue },
                    })
                  }
                  options={subSectList?.map((opt) => opt?.english) || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Sub Community"
                      name="sub_community"
                    />
                  )}
                />

                <TextField
                  select
                  name="maritalStatus"
                  value={formData.maritalStatus}
                  onChange={handleInputChange}
                  label="Marital Status"
                >
                  {maritalStatus?.map((opt) => (
                    <MenuItem value={opt?.name}>{opt?.name}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="physicalStatus"
                  value={formData.physicalStatus}
                  onChange={handleInputChange}
                  label="Physical Status"
                >
                  {disability?.map((opt) => (
                    <MenuItem value={opt?.name}>{opt?.name}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="motherTongue"
                  value={formData.motherTongue}
                  onChange={handleInputChange}
                  label="Mother Tongue"
                >
                  {filterDetail?.motherTongueList?.english?.map((opt) => (
                    <MenuItem value={opt?.label}>{opt?.label}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="height"
                  value={formData.height}
                  onChange={handleInputChange}
                  label="Height"
                >
                  {filterDetail?.height?.english?.map((opt) => (
                    <MenuItem value={opt?.label}>{opt?.label}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="income"
                  value={formData.income}
                  onChange={handleInputChange}
                  label="Income"
                >
                  {filterDetail?.incomeList?.english?.map((opt) => (
                    <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
                  ))}
                </TextField>

                {/* <TextField
                  select
                  name="familyStatus"
                  value={formData.familyStatus}
                  onChange={handleInputChange}
                  label="Family Status"
                >
                    {filterDetail?.familyStatusList?.english?.map((opt) => (
                    <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
                  ))}
                </TextField> */}

                {/* <TextField
                  label="dob"
                  name="dob"
                  variant="outlined"
                  value={formData?.dob}
                  onChange={handleInputChange}
                  fullWidth
                /> */}

                <TextField
                  label="dob"
                  type="date"
                  name="dob"
                  variant="outlined"
                  value={formData.dob}
                  onChange={handleInputChange}
                  fullWidth
                />

                <Button
                  onClick={() => handleSubmit("update")}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* cards */}
          <Grid container spacing={2}>
            {prefoundData?.jain_data?.length > 0 &&
              prefoundData?.jain_data?.map((bioData) => {
                return (
                  <Grid item lg={4} key={bioData?.id} md={6} sm={6} xs={12}>
                    <ScannerBioDataUserCard bioData={bioData} type="jain" />
                  </Grid>
                );
              })}
            {prefoundData?.hindu_data?.length > 0 &&
              prefoundData?.hindu_data?.map((bioData) => {
                return (
                  <Grid item lg={4} key={bioData?.id} md={6} sm={6} xs={12}>
                    <ScannerBioDataUserCard bioData={bioData} type="hindu" />
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ViewScannerDetails;
