import React from "react";
import {
  getAnalyticResponse1008,
  getAnalyticResponse1009,
  getAnalyticResponseBio,
  getAnalyticResponseReply,
} from "src/service";
import { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import Iconify from "src/components/iconify";
const WhatsAppAnalyticsHistory = ({ onClose }) => {
  const [analyticsBioType, setAnalyticsBioType] = useState([]);

  const [analyticsAssignData1009, setAnalyticsAssignData1009] = useState([]);
  const [analyticsAssignData1008, setAnalyticsAssignData1008] = useState([]);
  const [analyticsReplySection, setAnalyticsReplySection] = useState([]);
  console.log("analyticsAssignData1009 ::::", analyticsAssignData1009);

  useEffect(() => {
    const bioTypeData = async () => {
      try {
        // const startId = '860875';
        // const endId = '860885';
        const result = await getAnalyticResponseBio();

        if (result.status && Array.isArray(result.data)) {
          setAnalyticsBioType(result.data);
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };

    bioTypeData();
  }, []);

  useEffect(() => {
    const assignData1009 = async () => {
      try {
        // const startId = '860875';
        // const endId = '860885';
        const result = await getAnalyticResponse1009();
        console.log("result ::::", result);
        if (result.status && Array.isArray(result.data)) {
          setAnalyticsAssignData1009(result.data);
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };

    assignData1009();
  }, []);
  useEffect(() => {
    const assignData1008 = async () => {
      try {
        // const startId = '860875';
        // const endId = '860885';
        const result = await getAnalyticResponse1008();
        if (result.status && Array.isArray(result.data)) {
          setAnalyticsAssignData1008(result.data);
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };

    assignData1008();
  }, []);

  const apiData = {
    no_replay: 0,
    first_replay: 0,
    second_replay: 0,
    third_replay: 0,
  };
  useEffect(() => {
    const replySection = async () => {
      try {
        const result = await getAnalyticResponseReply();

        // Assuming the API returns an object like { no_replay: 0, first_replay: 0, ... }
        if (result.status && result.data) {
          // Step 1: Transform the object into an array of { name, value }
          const transformedData = Object.keys(result.data).map((key) => ({
            name: key.replace(/_/g, " "), // Optional: replace underscores with spaces for better display
            value: result.data[key],
          }));

          // Step 2: Set the transformed data in the state
          setAnalyticsReplySection(transformedData);
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };

    replySection();
  }, []);

  return (
    <div>
      <div>
        <Box
          sx={{
            color: "white",
            bgcolor: "red",
            mb: 0.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            py: 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              margin: "0 auto",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            Response Data Analytics History
          </Typography>

          <Tooltip title="Close">
            <Box
              component="span"
              sx={{
                cursor: "pointer",
                "&:hover": { opacity: 0.7 },
              }}
              onClick={onClose}
            >
              <Iconify icon="mdi:close-circle-outline" width={24} height={24} />
            </Box>
          </Tooltip>
        </Box>

        {/* Rest of your existing component code */}
      </div>

      <div style={{ width: "100%" }}>
        {/* user reply  */}
        <p
          style={{
            color: "white",
            backgroundColor: "grey",
            width: "100%",
            padding: "2px",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          User Reply Data
        </p>

        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
          {analyticsReplySection.map((item, index) => (
            <div
              key={index}
              style={{
                width: "calc(20% - 20px)",
                minWidth: "100px",
                height: "100px",
                backgroundColor: "#96DED1",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
                fontWeight: "bold",
                margin: "10px",
              }}
            >
              <div>{item.value}</div>
              <div>{item.name}</div>
            </div>
          ))}
        </div>

        {/* Data Type  */}

        <p
          style={{
            color: "white",
            backgroundColor: "grey",
            width: "100%",
            padding: "2px",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Data Type
        </p>

        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
          {analyticsBioType.map((item, index) => (
            <div
              key={item.key}
              style={{
                width: "calc(20% - 20px)",
                minWidth: "100px",
                height: "100px",
                backgroundColor: "#96DED1",
                border: "10px solid #96DED1",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
                fontWeight: "bold",
                margin: "10px",
              }}
            >
              <div>{item.value}</div>
              <div>{item.name}</div>
            </div>
          ))}
        </div>

        <p
          style={{
            color: "white",
            backgroundColor: "grey",
            width: "100%",
            padding: "2px",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Assign Data To Staff 1009
        </p>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
          {analyticsAssignData1009.map((item, index) => (
            <div
              key={item.key}
              style={{
                width: "calc(20% - 20px)",
                minWidth: "100px",
                height: "100px",
                backgroundColor: "#96DED1",
                border: "10px solid lightblue",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
                fontWeight: "bold",
                margin: "10px",
              }}
            >
              <div>{item?.count}</div>
              <div>{item?.WSTAFF?.name?.english}</div>
            </div>
          ))}
        </div>
        <p
          style={{
            color: "white",
            backgroundColor: "grey",
            width: "100%",
            padding: "2px",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Assign Data To Staff 1008
        </p>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
          {analyticsAssignData1008.map((item, index) => (
            <div
              key={item.key}
              style={{
                width: "calc(20% - 20px)",
                minWidth: "100px",
                height: "100px",
                backgroundColor: "#96DED1",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
                fontWeight: "bold",
                margin: "10px",
              }}
            >
              <div>{item?.count}</div>
              <div>{item?.WSTAFF?.name?.english}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppAnalyticsHistory;
