import { useState, useEffect } from "react";
import {
  Tooltip,
  Button,
  CardHeader,
  Typography,
  Stack,
  Badge,
  IconButton,
  DialogContent,
  Dialog,
  Switch,
  DialogActions,
  Grid,
  DialogTitle,
} from "@mui/material";

import blankUSer from "../../../../assets/images/blank-profile-picture-973460__340.webp";
import Image from "../../../../components/image";
import {
  updateBiodata,
  scanBiodataPy,
  gptBiodataPy,
  finalBiodataPy,
  deleteBiodataPy,
  scanBiodataHinduPy,
  gptBiodataHinduPy,
  finalBiodataHinduPy,
  deleteBiodataHinduPy,
  copyBioDataCountHistory,
  callBioData,
  callStatus,
  getDuplicateImages,
  updateGender,
  moveToData,
  approvedJainHinduBiodata,
} from "src/service";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Iconify from "../../../../components/iconify";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { useSnackbar } from "../../../../components/snackbar";
import constant from "src/Constant";
import { useAuthContext } from "src/auth/useAuthContext";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useCopyImageWithId from "src/hooks/useCopyImageWithId";
import moment from "moment";
// import Dialog from "src/theme/overrides/Dialog";
// ----------------------------------------------------------------------

export default function ScannerBioDataUserCard({
  selectedId,
  handleToggleAll,
  bioData,
  type,
  handleApproveDisapproveStatus,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDuplicateImage, setOpenDuplicateImage] = useState(false);
  const [duplicateImages, setDuplicateImages] = useState([]);
  const [openPopover, setOpenPopover] = useState(null);
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const [newRow, setNewRow] = useState(bioData);
  const [date, setDate] = useState([]);
  const contxt = useAuthContext();
  const [mobileValue, setMobileValue] = useState([]);
  const [viewImage, SetViewImage] = useState({ open: false, path: "" });
  const condition =
    type === "jain" ? newRow?.jain_image?.image : newRow?.hindu_image?.image;
  const { copyImageWithId, canvasRef } = useCopyImageWithId(
    `${type === "jain" ? "Jain" : "Hindu"}${bioData?.id}`
  );

  const handleCallStatus = async (id, callType) => {
    try {
      const obj = {
        id: id,
        call_type: callType,
        user_id: contxt?.user?.id,
        user_name: contxt?.user?.nameEnglish,
      };

      const response = await callBioData(type, obj);

      if (response?.status === 200) {
        console.log(response);
        setNewRow(bioData);
        enqueueSnackbar("Successfully Updated", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("An error occurred while updating call status", {
        variant: "error",
      });
    }
  };
  const onCallStatus = async (obj) => {
    try {
      const response = await callStatus(type, obj);
      if (response?.status === 200) {
        console.log(response);
        setNewRow(bioData);
        enqueueSnackbar("Successfully Updated", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("An error occurred while updating call status", {
        variant: "error",
      });
    }
  };

  // callStatus
  const onStatus = async (obj) => {
    setNewRow({ ...newRow, ...obj });
    obj.id = bioData?.id;
    let res = await updateBiodata(obj);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
    } else enqueueSnackbar(res?.data?.message, { variant: "error" });
  };



  const handleDuplicateImage = async (id) => {
    try {
      const response = await getDuplicateImages(type, { id: id });
      if (response?.status === 200) {
        setOpenDuplicateImage(true);
        setDuplicateImages(response?.data?.data);
      }
    } catch (error) {
      console.error("Error during handleCall:", error);
      enqueueSnackbar("An error occurred while updating call status", {
        variant: "error",
      });
    }
  };

  const copyImageById = async (url) => {
    const image_id =
      type === "jain" ? newRow?.jain_image?.id : newRow?.hindu_image?.id;
    try {
      let obj = {
        id: newRow?.id,
        image_id: image_id,
        user_id: contxt?.user?.id,
        user_name: contxt?.user?.nameEnglish,
      };
      const response = await copyBioDataCountHistory(type, obj);
      if (response?.status === 200) {
        copyImageWithId(url);
        //  navigator.clipboard.writeText();
        // enqueueSnackbar(response?.data?.data, {
        //   variant: "success",
        // });
      }
    } catch (error) {
      console.error("Error during self-assign scanner data:", error);
    }
  };

  const handleGender = async (gender) => {
    try {
      let obj = {
        id: newRow?.id,
        gender: gender,
      };
      const response = await updateGender(obj, type);
      if (response?.status === 200) {
        enqueueSnackbar("Gender Updated Successfully", {
          variant: "success",
        });
        setNewRow((prev) => ({ ...prev, gender }));
      }
    } catch (error) {
      console.error("Error during self-assign scanner data:", error);
    }
  };

  const handleMoveToData = async (submit_type) => {
    try {
      const formObj = {
        id: [newRow?.id],
        user_id: contxt?.user?.id,
        submit_type: submit_type,
      };

      const response = await moveToData(type, formObj);
      if (response?.status === 200) {
        enqueueSnackbar("Moved Data Successfully!");
        setNewRow((prev) => ({
          ...prev,
          is_common: 0,
        }));
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleApprovedata = async () => {
    try {
      const formObj = {
        id: newRow?.id,
        status: 1,
      };

      const response = await approvedJainHinduBiodata(formObj, type);
      if (response?.status === 200) {
        enqueueSnackbar("Approved Successfully!");
        setNewRow((prev) => ({
          ...prev,
          is_jain_hindu_approved: 1,
        }));
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleRightClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setNewRow(bioData);
  }, [bioData]);

  const maskNumber = (phoneNumber) => {
    const number = contxt?.maskPhoneNumber(phoneNumber);
    if (number) return number;
  };

  const handleViewImage = (path) => {
    SetViewImage({ ...viewImage, open: !viewImage?.open, path: path });
  };

  useEffect(() => {
    let mobile = newRow?.mobiles
      ? newRow.mobiles.split(",").map((mobile) => mobile.trim())
      : [];
    setMobileValue(mobile || []);
  }, [newRow]);

  useEffect(() => {
    currentDate();
  }, []);

  const currentDate = () => {
    let today = new Date();
    setDate(today);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const handleCopyMobile = (i) => {
    copy(i);
    enqueueSnackbar("Copied!");
  };
  const handleCopyName = () => {
    copy(newRow.name);
    enqueueSnackbar("Copied!");
  };
  // send whatsapp msg
  const whatsappNow = (mobile) => {
    window.location.href = "https://wa.me/91" + `${maskNumber(mobile)}`;
  };

  const handleScan = async () => {
    try {
      enqueueSnackbar("Scanning...");
      let obj = {
        ids: [bioData?.id],
      };
      const res =
        type === "hindu"
          ? await scanBiodataHinduPy(obj)
          : await scanBiodataPy(obj);
      const updatedData = res?.data?.data?.scanned_results?.find(
        (i) => i.id === bioData?.id
      );
      setNewRow({ ...newRow, ...updatedData, scan_status: 1 });
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleGpt = async () => {
    try {
      enqueueSnackbar("Gpt Scanning...");
      let obj = {
        ids: [bioData?.id],
      };
      const res =
        type === "hindu"
          ? await gptBiodataHinduPy(obj)
          : await gptBiodataPy(obj);
      const updatedData = res?.data?.data?.scanned_results?.find(
        (i) => i.id === bioData?.id
      );
      setNewRow({ ...newRow, ...updatedData, gpt_status: 1 });
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleFinal = async () => {
    try {
      enqueueSnackbar("Final Scanning...");
      let obj = {
        ids: [bioData?.id],
      };
      const res =
        type === "hindu"
          ? await finalBiodataHinduPy(obj)
          : await finalBiodataPy(obj);
      const updatedData = res?.data?.data?.scanned_results?.find(
        (i) => i.id === bioData?.id
      );
      setNewRow({ ...newRow, ...updatedData });
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      let obj = {
        ids: [bioData?.id],
      };
      const res =
        type === "hindu"
          ? await deleteBiodataHinduPy(obj)
          : await deleteBiodataPy(obj);
      setOpenConfirm(false);
      setNewRow({});
      enqueueSnackbar(res?.data?.message);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };



  return (
    <div className={`offlineCard m-2`}>
      <div
        className={`d-flex justify-content-between ${
          newRow?.[`${type}_image`]?.repeat_count !== 2
            ? "primaryBg"
            : "userAppBg"
        } light`}
        style={{
          padding: "8px",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <div
          className={`d-flex align-items-center my-2 ${
            newRow?.is_online_user ? "ms-4" : ""
          }`}
        >
          <div>
            {newRow?.id && (
              <Typography
                className={`${newRow?.is_online_user ? "ms-4" : ""}`}
                variant="subtitle1"
              >
                {" "}
                #
                <span
                  className={`fw-bold fs-18 ${
                    newRow?.[`${type}_image`]?.repeat_count !== 2
                      ? "text-danger"
                      : "text-primary"
                  }`}
                >
                  {type === "jain" ? "J" : "H"}
                </span>
                {newRow?.id} &nbsp;({newRow?.[`${type}_image`]?.repeat_count})
              </Typography>
            )}
            {newRow?.name && (
              <Typography
                variant="subtitle1"
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  handleCopyName(newRow?.name);
                }}
              >
                <AccountCircleIcon
                  className="fs-16"
                  style={{ marginRight: "5px" }}
                />
                {newRow?.name}{" "}
                <ContentCopyOutlinedIcon className="fs-16 ms-2" />
              </Typography>
            )}
            <div className="fs-12">
              {newRow?.confidence_score && (
                <span> Confidence Score = {newRow.confidence_score}</span>
              )}
              {newRow?.community && <span> | {newRow.community}</span>}
              {newRow?.data_type && <span> | {newRow.data_type}</span>}
              {newRow?.marital_status && (
                <span> | {newRow.marital_status}</span>
              )}
              {newRow?.special_case && <span> | {newRow.special_case}</span>}
              {newRow?.state && <span> | {newRow.state}</span>}
              {newRow?.notes && <span> | {newRow.notes}</span>}
            </div>
          </div>
        </div>
        {(contxt?.user?.sections?.[
          type === "jain" ? "ScannerData" : "HinduScannerData"
        ]?.WorkFromHomeStaff ||
          contxt?.user?.sections?.[
            type === "jain" ? "ScannerData" : "HinduScannerData"
          ]?.isAdmin) && (
          <div className="mx-2">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                const url = `/viewscannerdata/${type}/${newRow?.id}`;
                window.open(url, "_blank");
              }}
              mx={1}
              my={1}
            >
              View
            </Button>
          </div>
        )}
        {contxt?.user?.sections?.[
          type === "jain" ? "ScannerData" : "HinduScannerData"
        ]?.delete && (
          <div className="mx-2">
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => setOpenConfirm(true)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              mx={1}
              my={1}
            >
              Delete
            </Button>
          </div>
        )}
        {newRow?.[`${type}_image`]?.repeat_count > 1 && (
          <div className="mx-2">
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => handleDuplicateImage(newRow?.id)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              mx={1}
              my={1}
            >
              Duplicate Images
            </Button>
          </div>
        )}
        <div>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleToggleAll(newRow?.id)}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              background: selectedId?.find((i) => i === newRow?.id)
                ? "#fff"
                : "#32be8f",
              border: "1px solid #fff",
              borderRadius: "5px",
              color: selectedId?.find((i) => i === newRow?.id)
                ? "#32be8f"
                : "#fff",
            }}
          >
            {selectedId?.find((i) => i === newRow?.id) ? "Unselect" : "Select"}{" "}
          </Button>
        </div>
      </div>
      {newRow?.gender === "both" && (
        <div className="p-1 primaryBg text-white">
          <button
            className="btn btn-sm btn-outline-light me-2"
            onClick={() => handleGender("male")}
          >
            Male
          </button>
          <button
            className="btn btn-sm btn-outline-light"
            onClick={() => handleGender("female")}
          >
            Female
          </button>
        </div>
      )}
      {newRow?.id &&
        newRow?.is_common === 1 && newRow?.is_jain_hindu_approved === 0 &&
        (type === "jain" ? (
          <>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => handleMoveToData("move_to_hindu")}
            >
              Move To Hindu
            </button>

            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleApprovedata}
            >
              Approve Jain Biodata
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => handleMoveToData("move_to_jain")}
            >
              Move To Jain
            </button>

            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleApprovedata}
            >
              Approve Hindu Biodata
            </button>
          </>
        ))}
      {newRow?.upload_staff_name && (
        <div className="p-1 primaryBg d-flex justify-content-end text-white">
          Uploaded By: {newRow?.upload_staff_name ?? "N/A"} (
          {moment(newRow?.upload_time, "YYYY-MM-DD").format("DD/MM/YYYY")}{" "}
          {newRow?.upload_real_time
            ? moment(newRow?.upload_real_time, "HH:mm:ss").format("hh:mm A")
            : "N/A"}
          )
        </div>
      )}
      {newRow?.update_staff_name && (
        <div className="p-1 primaryBg d-flex justify-content-end text-white">
          Updated By: {newRow?.update_staff_name ?? "N/A"} (
          {moment(newRow?.update_time, "YYYY-MM-DD").format("DD/MM/YYYY")}{" "}
          {newRow?.update_real_time
            ? moment(newRow?.update_real_time, "HH:mm:ss").format("hh:mm A")
            : "N/A"}
          )
        </div>
      )}{" "}
      {newRow?.jain_data_notes && (
        <div className="p-1 primaryBg d-flex justify-content-end text-white">
          Removed By: {newRow?.jain_data_notes?.remove_by ?? "N/A"} (
          {moment(newRow?.jain_data_notes?.remove_date, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )}{" "}
          {newRow?.jain_data_notes?.remove_time
            ? moment(newRow?.jain_data_notes?.remove_time, "HH:mm:ss").format(
                "hh:mm A"
              )
            : "N/A"}
          )
        </div>
      )}
      <div className="py-2 px-3">
        {/* image section start */}
        <div className="d-flex justify-content-between">
          <div>
            <div style={{ display: "flex" }}>
              <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
              <Image
                onContextMenu={handleRightClick}
                alt={newRow.value}
                src={
                  type === "jain"
                    ? `${constant.scannerUrl}/images/${newRow?.jain_image?.image}`
                    : `${constant.scannerUrl}/images/${newRow?.hindu_image?.image}`
                }
                sx={{ borderRadius: 1 }}
                style={{
                  width: "350px",
                  height: "400px",
                  borderRadius: 8,
                  objectFit: "contain",
                }}
                onClick={() => {
                  handleViewImage(
                    type === "jain"
                      ? `${constant.scannerUrl}/images/${newRow?.jain_image?.image}`
                      : `${constant.scannerUrl}/images/${newRow?.hindu_image?.image}`
                  );
                }}
              />
              <ContentCopyOutlinedIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  copyImageById(
                    type === "jain"
                      ? `${constant.scannerUrl}/images/${newRow?.jain_image?.image}`
                      : `${constant.scannerUrl}/images/${newRow?.hindu_image?.image}`
                  )
                }
                className="fs-16 ms-2"
              />
            </div>
            {/* {condition ? (
              <div style={{ display: "flex" }}>
                <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
                <Image
                  onContextMenu={handleRightClick}
                  alt={newRow.value}
                  src={
                    type === "jain"
                      ? `${constant.scannerUrl}/images/${newRow?.jain_image?.image}`
                      : `${constant.scannerUrl}/images/${newRow?.hindu_image?.image}`
                  }
                  sx={{ borderRadius: 1 }}
                  style={{
                    width: "350px",
                    height: "400px",
                    borderRadius: 8,
                    objectFit: "contain",
                  }}
                  onClick={() => {
                    handleViewImage(
                      type === "jain"
                        ? `${constant.scannerUrl}/images/${newRow?.jain_image?.image}`
                        : `${constant.scannerUrl}/images/${newRow?.hindu_image?.image}`
                    );
                  }}
                />
                <ContentCopyOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    copyImageById(
                      type === "jain"
                        ? `${constant.scannerUrl}/images/${newRow?.jain_image?.image}`
                        : `${constant.scannerUrl}/images/${newRow?.hindu_image?.image}`
                    )
                  }
                  className="fs-16 ms-2"
                />
              </div>
            ) : (
              <Image
                onContextMenu={handleRightClick}
                src={blankUSer}
                alt="about-vision"
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: 8,
                  objectFit: "contain",
                }}
              />
            )} */}
          </div>
        </div>
        {/* image section end */}
        <div>
          {mobileValue && mobileValue?.length > 0
            ? mobileValue?.map((i) => {
                return (
                  <div className="displayBtwPro" style={{ height: "50px" }}>
                    <div>
                      <Button
                        className="roundBtn"
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          handleCopyMobile(
                            maskNumber(typeof i === "object" ? i?.mobile : i)
                          )
                        }
                        // onClick={() => handleCopyMobile(maskNumber(i?.mobile))}
                      >
                        {maskNumber(typeof i === "object" ? i?.mobile : i)}
                        {/* {maskNumber(i?.mobile)} */}
                        <ContentCopyOutlinedIcon className="fs-16 ms-2" />
                      </Button>
                    </div>
                    <div className="my-1 align-items-center d-flex">
                      <button
                        className="WtsBtn"
                        style={{
                          borderColor: "blue",
                          color: newRow?.whatsapp_status ? "#fff" : "#525FFE",
                          background: newRow?.whatsapp_status
                            ? "#525FFE"
                            : "transparent",
                        }}
                        onClick={() => {
                          onStatus({
                            whatsapp_status: !newRow?.whatsapp_status,
                            whatsapp_date_time: date,
                            whatsapp_action_id: contxt?.user?.id,
                          });
                        }}
                      >
                        WA
                      </button>
                      <button
                        className="WtsBtn"
                        style={{ borderColor: "#32BE8F", color: "#32BE8F" }}
                        onClick={() => {
                          whatsappNow(newRow?.mobile);
                        }}
                      >
                        <WhatsAppIcon />
                      </button>
                    </div>
                  </div>
                );
              })
            : newRow?.mobile && (
                <div className="displayBtwPro" style={{ height: "50px" }}>
                  <div>
                    <Button
                      className="roundBtn"
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        handleCopyMobile(maskNumber(newRow?.mobile))
                      }
                    >
                      {maskNumber(newRow?.mobile)}
                      <ContentCopyOutlinedIcon className="fs-16 ms-2" />
                    </Button>
                  </div>
                  <div className="my-1 align-items-center d-flex">
                    <button
                      className="WtsBtn"
                      style={{
                        borderColor: "blue",
                        color: newRow?.whatsapp_status ? "#fff" : "#525FFE",
                        background: newRow?.whatsapp_status
                          ? "#525FFE"
                          : "transparent",
                      }}
                      onClick={() => {
                        onStatus({
                          whatsapp_status: !newRow?.whatsapp_status,
                          whatsapp_date_time: date,
                          whatsapp_action_id: contxt?.user?.id,
                        });
                      }}
                    >
                      WA
                    </button>
                    <button
                      className="WtsBtn"
                      style={{ borderColor: "#32BE8F", color: "#32BE8F" }}
                      onClick={() => {
                        whatsappNow(newRow?.mobile);
                      }}
                    >
                      <WhatsAppIcon />
                    </button>
                  </div>
                </div>
              )}
        </div>

        {/* Fourth Section end */}
        <box open={openPopover} onClose={handleClosePopover} arrow="right-top">
          {(contxt?.user?.sections?.[
            type === "jain" ? "ScannerData" : "HinduScannerData"
          ]?.isAdmin ||
            contxt?.user?.sections?.[
              type === "jain" ? "ScannerData" : "HinduScannerData"
            ]?.update) && (
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              sx={{ my: 2 }}
            >
              {(contxt?.user?.sections?.ScannerData?.isAdmin ||
                contxt?.user?.sections?.[
                  type === "jain" ? "ScannerData" : "HinduScannerData"
                ]?.approval) &&
                newRow?.update_staff_id && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      gap: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <b>Approval</b>
                      <Switch
                        color="success"
                        checked={newRow?.approve_status}
                        onChange={handleApproveDisapproveStatus}
                      />
                    </div>
                    {newRow?.approve_status === 1 && (
                      <small>
                        Approved By - {newRow?.approve_by} (
                        {moment(newRow?.approve_date, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        )}{" "}
                        {newRow?.approve_time
                          ? moment(newRow?.approve_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )
                          : "N/A"}
                        )
                      </small>
                    )}
                  </div>
                )}

              {newRow?.mobiles != null && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {/* Call Section */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Badge
                      badgeContent="0"
                      color="secondary"
                      style={{
                        fontSize: "0.8rem",
                      }}
                      componentsProps={{
                        badge: {
                          style: {
                            borderRadius: "2px",
                          },
                        },
                      }}
                    />
                    <Switch
                      checked={
                        type === "jain"
                          ? newRow?.jain_data_call?.call_connected_status
                          : newRow?.hindu_data_call?.call_connected_status
                      }
                      onChange={() =>
                        handleCallStatus(newRow?.id, "call_connected_status")
                      }
                    />
                    <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "-15px" }}
                        className="primaryColor fw-500 primaryColor"
                      >
                        Call
                      </Typography>
                  </div>

                  {/* NP Section */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Badge
                      badgeContent={0}
                      color="primary"
                      style={{ fontSize: "0.8rem" }}
                    />
                    <Switch
                      color="success"
                      checked={
                        newRow?.type === "jain"
                          ? newRow?.jain_data_call?.call_not_picked_status
                          : newRow?.hindu_data_call?.call_not_picked_status
                      }
                      onChange={() =>
                        handleCallStatus(newRow?.id, "not_picked")
                      }
                    />
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "-15px" }}
                        className="primaryColor fw-500 primaryColor"
                      >
                        NP
                      </Typography>
                  </div>

                  {/* NC Section */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Badge
                      badgeContent={0}
                      color="primary"
                      style={{ fontSize: "0.8rem" }}
                    />
                    <Switch
                      color="success"
                      checked={
                        newRow?.type === "jain"
                          ? newRow?.jain_data_call?.not_connected
                          : newRow?.hindu_data_call?.not_connected
                      }
                      onChange={() =>
                        handleCallStatus(newRow?.id, "not_connected")
                      }
                    />
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "-15px" }}
                        className="primaryColor fw-500 primaryColor"
                      >
                        NC
                      </Typography>
                  </div>
                </div>
              )}








              {!newRow?.scan_status ? (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleScan();
                  }}
                >
                  Scan
                </Button>
              ) : null}
              {Number(newRow?.for_gpt) &&
              !Number(newRow?.gpt_status) &&
              Number(newRow?.scan_status) &&
              !isNaN(Number(newRow?.confidence_score)) &&
              Number(newRow?.confidence_score) > 75 ? (
                <Button
                  variant="outlined"
                  color="warning"
                  size="small"
                  onClick={() => {
                    handleGpt();
                  }}
                >
                  Gpt
                </Button>
              ) : null}
              {newRow?.gpt_status === 1 && newRow?.final_status !== 1 ? (
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleFinal();
                  }}
                >
                  Final Update
                </Button>
              ) : null}
            </Stack>
          )}
          {newRow?.jain_data_notes?.notes && (
            <p>Removed Reason:- {newRow?.jain_data_notes?.notes}</p>
          )}
        </box>
      </div>



      { (type === "jain" && newRow?.jain_data_call?.call_connected_status === 1) || (type === "hindu" && newRow?.hindu_data_call?.call_connected_status === 1) ? (
            <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
              <div
                className="d-flex align-items-center flex-wrap"
                style={{ gap: "10px" }}
              >
                <Button
                  size="small"
                  variant={
                    type === "jain" ? 
                    newRow?.jain_data_call?.call_status === 'samband' ? "contained" : "outlined" :
                    newRow?.hindu_data_call?.call_status === 'samband' ? "contained" : "outlined"
                  }
                  className="btnPadding"
                  onClick={() => {
                    onCallStatus({
                      call_status: 'samband',
                      id: type === "jain" ? newRow?.jain_data_call?.id : newRow?.hindu_data_call?.id
                    });
                  }}
                >
                  Sambandh
                </Button>
                <Button
                  size="small"
                  variant={
                    type === "jain" ? 
                    newRow?.jain_data_call?.call_status === 'share' ? "contained" : "outlined" :
                    newRow?.hindu_data_call?.call_status === 'share' ? "contained" : "outlined"
                  }
                  className="btnPadding"
                  onClick={() => {
                    onCallStatus({
                      call_status: 'share',
                      id: type === "jain" ? newRow?.jain_data_call?.id : newRow?.hindu_data_call?.id
                    });
                  }}
                >
                  Share
                </Button>
                <Button
                  size="small"
                  variant={
                    type === "jain" ? 
                    newRow?.jain_data_call?.call_status === 'later' ? "contained" : "outlined" :
                    newRow?.hindu_data_call?.call_status === 'later' ? "contained" : "outlined"
                  }
                  className="btnPadding"
                  onClick={() => {
                    onCallStatus({
                      call_status: 'later',
                      id: type === "jain" ? newRow?.jain_data_call?.id : newRow?.hindu_data_call?.id
                    });
                  }}
                >
                  Later
                </Button>
                <Button
                  size="small"
                  variant={
                    type === "jain" ? 
                    newRow?.jain_data_call?.call_status === 'special' ? "contained" : "outlined" :
                    newRow?.hindu_data_call?.call_status === 'special' ? "contained" : "outlined"
                  }
                  className="btnPadding"
                  onClick={() => {
                    onCallStatus({
                      call_status: 'special',
                      id: type === "jain" ? newRow?.jain_data_call?.id : newRow?.hindu_data_call?.id
                    });
                  }}
                >
                  Special
                </Button>
                <Button
                  size="small"
                  variant={
                    type === "jain" ? 
                    newRow?.jain_data_call?.call_status === 'not_interested' ? "contained" : "outlined" :
                    newRow?.hindu_data_call?.call_status === 'not_interested' ? "contained" : "outlined"
                  }
                  className="btnPadding"
                  onClick={() => {
                    onCallStatus({
                      call_status: 'not_interested',
                      id: type === "jain" ? newRow?.jain_data_call?.id : newRow?.hindu_data_call?.id
                    });
                  }}
                >
                  Not Interested
                </Button>
                <Button
                  size="small"
                  variant={
                    type === "jain" ? 
                    newRow?.jain_data_call?.call_status === 'hindu' ? "contained" : "outlined" :
                    newRow?.hindu_data_call?.call_status === 'hindu' ? "contained" : "outlined"
                  }
                  className="btnPadding"
                  onClick={() => {
                    onCallStatus({
                      call_status: 'hindu',
                      id: type === "jain" ? newRow?.jain_data_call?.id : newRow?.hindu_data_call?.id
                    });
                  }}
                >
                  Hindu
                </Button>
                <Button
                  size="small"
                  variant={
                    type === "jain" ? 
                    newRow?.jain_data_call?.call_status === 'other_cast' ? "contained" : "outlined" :
                    newRow?.hindu_data_call?.call_status === 'other_cast' ? "contained" : "outlined"
                  }
                  className="btnPadding"
                  onClick={() => {
                    onCallStatus({
                      call_status: 'other_cast',
                      id: type === "jain" ? newRow?.jain_data_call?.id : newRow?.hindu_data_call?.id
                    });
                  }}
                >
                  Other Cast
                </Button>
                {/* <Button
                  size="small"
                  variant={newRow?.shadi_done_status ? "contained" : "outlined"}
                  className="btnPadding"
                  onClick={async () => {
                    onStatus({
                      shadi_done_status: !newRow?.shadi_done_status,
                      shadi_done_date_time: new Date(),
                    });
  
                    try {
                      const response = await bioDataShadiDoneMove(bioData?.id);
                      if (response?.status === 200) {
                        console.log("API call successful:", response.data);
                      } else {
                        console.error(
                          "API call failed with status:",
                          response?.status
                        );
                      }
                    } catch (error) {
                      console.error("Error in API call:", error);
                    }
                  }}
                >
                  Shadi Done
                </Button> */}
  
                <Button
                  size="small"
                  variant={
                    type === "jain" ? 
                    newRow?.jain_data_call?.call_status === 'on_app' ? "contained" : "outlined" :
                    newRow?.hindu_data_call?.call_status === 'on_app' ? "contained" : "outlined"
                  }
                  className="btnPadding"
                  onClick={() => {
                    onCallStatus({
                      call_status: 'on_app',
                      id: type === "jain" ? newRow?.jain_data_call?.id : newRow?.hindu_data_call?.id
                    });
                  }}
                >
                  On App
                </Button>
                <Button
                  size="small"
                  variant={
                    type === "jain" ? 
                    newRow?.jain_data_call?.call_status === 'different_language' ? "contained" : "outlined" :
                    newRow?.hindu_data_call?.call_status === 'different_language' ? "contained" : "outlined"
                  }
                  className="btnPadding"
                  onClick={() => {
                    onCallStatus({
                      call_status: 'different_language',
                      id: type === "jain" ? newRow?.jain_data_call?.id : newRow?.hindu_data_call?.id
                    });
                  }}
                >
                  Diff Language
                </Button>
              </div>
              {/* rating section start */}
              {/* <div className="my-2">
                {ratingArray?.map((i) => (
                  <Button
                    className="ratingSec"
                    key={i?.key}
                    variant={newRow?.rating === i?.key ? "contained" : "outlined"}
                    size="small"
                  >
                    {i?.title}
                  </Button>
                ))}
              </div> */}
              {/* rating section end */}
  
              {/* Sambandh section preview */}
              {newRow?.BioSambandh?.length > 0 && (
                <>
                  {" "}
                  {newRow?.BioSambandh?.map((val, ind) => {
                    return (
                      <div>
                        {val?.type == "0" && (
                          <div className="row align-items-center">
                            <div className="col-10 pe-0">
                              <p className="fs-12 darkText border-bottom px-1 ">
                                <span style={{ color: "#eb445a" }}>
                                  <b>SF </b>{" "}
                                </span>
                                {val?.created_for ? ": " + val?.created_for : ""}
                                {val?.name ? "- " + val?.name : ""}
                                {val?.mobile
                                  ? "- " + maskNumber(val?.mobile)
                                  : ""}
                                {val?.relative_name.trim()
                                  ? "- " + val?.relative_name
                                  : ""}
                                {val?.age ? "- " + val?.age : ""}
                                {val?.employed_in ? "- " + val?.employed_in : ""}
                                {val?.marital_status
                                  ? "- " + val?.marital_status
                                  : ""}
                                {val?.sect ? "- " + val?.sect : ""}
                                {val?.state ? "- " + val?.state : ""}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {newRow?.BioSambandh &&
                    newRow?.BioSambandh?.map((val, ind) => {
                      return (
                        <div>
                          {val?.type == "1" && (
                            <div className="row align-items-center">
                              <div className="col-10">
                                <p className="fs-12 darkText border-bottom px-1 ">
                                  <span style={{ color: "#eb445a" }}>
                                    <b>OF </b>{" "}
                                  </span>
                                  {val?.created_for
                                    ? ": " + val?.created_for
                                    : ""}
                                  {val?.name ? ": " + val?.name : ""}
                                  {val?.mobile
                                    ? ": " + maskNumber(val?.mobile)
                                    : ""}
                                  {val?.relative_name
                                    ? ": " + val?.relative_name
                                    : ""}
                                  {val?.employed_in
                                    ? "- " + val?.employed_in
                                    : ""}
                                  {val?.marital_status
                                    ? "- " + val?.marital_status
                                    : ""}
                                  {val?.sect ? "- " + val?.sect : ""}
                                  {val?.state ? "- " + val?.state : ""}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </>
              )}
              <div className="row ">
                <div className="col-12">
                  {newRow?.partner_preference ? (
                    <span className="fs-12 ">
                      <b style={{ color: "#eb445a" }}>Partner Preference : </b>
                      {newRow?.partner_preference}{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col-12">
                  {newRow?.different_language ? (
                    <span className="fs-12 ">
                      <b style={{ color: "#eb445a" }}>Different Language : </b>
                      {newRow?.different_language} |{" "}
                      {newRow?.different_language_notes}{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col-12">
                  {newRow?.not_interested_notes ? (
                    <span className="fs-12 ">
                      <b style={{ color: "#eb445a" }}>Not Interested Reason : </b>
                      {newRow?.not_interested_notes}{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* notes show */}
              <div className="row">
                {newRow?.other ? (
                  <div className="col-12 fs-14" style={{ color: "#525ffe" }}>
                    {newRow?.other
                      ? "Notes : " + newRow?.other + newRow?.notes
                      : "" + <br />}
                    <span className="fs-12 fw-500" style={{ color: "red" }}>
                      {" "}
                      [
                      {newRow?.notes_date_time
                        ? moment(newRow?.notes_date_time)?.format("DD-MM-YY") +
                          " , " +
                          moment(newRow?.notes_date_time)?.format("HH:mm")
                        : ""}
                      ]
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
  
              {/* notes show */}
              <div className="row ">
                {(newRow?.special || newRow?.special_notes) && (
                  <div className="col-12 fs-14">
                    <span style={{ color: "#525ffe" }}>
                      {" "}
                      {newRow?.special
                        ? "Special Share : " + newRow?.special
                        : ""}{" "}
                      |{" "}
                    </span>
                    <span style={{ color: "#EB445A" }}>
                      {newRow?.special_notes ? newRow?.special_notes : ""}
                    </span>
                  </div>
                )}
              </div>
              {/* notes show */}
              <div className="row">
                {newRow?.normal_share && (
                  <div className="col-12">
                    {newRow?.normal_share
                      ? "Share : " + newRow?.normal_share
                      : "" + <br />}
                  </div>
                )}
              </div>
  
              {/* followup section */}
              {newRow?.later_status && (
                <div className="d-flex" style={{ gap: "20px" }}>
                  <div className="fs-14">
                    <span style={{ color: "red" }}>Follow up :</span>{" "}
                    {newRow?.later_date_time ? (
                      <span style={{ fontSize: "13px" }}>
                        Date :{" "}
                        {moment(newRow?.later_date_time).format("YYYY-MM-DD")}
                      </span>
                    ) : (
                      <span>Date : --- </span>
                    )}
                  </div>
                  <div className="fs-14">
                    {newRow?.later_date_time ? (
                      <span style={{ fontSize: "13px" }}>
                        Time : {moment(newRow?.later_date_time).format("HH:mm a")}
                      </span>
                    ) : (
                      <span>Time : ---</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : ''}






      {/* view Image */}
      <Dialog
        Dialog
        open={viewImage?.open}
        onClose={() => {
          handleViewImage("");
        }}
        fullWidth
        maxWidth={"md"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleViewImage("");
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
        />
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <p
              style={{
                position: "absolute",
                top: "5px",
                left: "5px",
                color: "#ed1c24",
                zIndex: "9",
                backgroundColor: "White",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              #{type === "jain" ? "Jain" : "Hindu"}
              {newRow?.id}
            </p>
            <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
            <Image
              onContextMenu={handleRightClick}
              alt={newRow.value}
              src={viewImage.path}
              sx={{ borderRadius: 1 }}
              style={{
                width: 500,
                height: 600,
                borderRadius: 8,
                objectFit: "fill",
              }}
            />
          </div>
          {/* <Button variant="contained" onClick={copyImageWithId}>
            Copy Image with ID
          </Button>*/}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDuplicateImage}
        onClose={() => setOpenDuplicateImage(false)}
      >
        <DialogTitle>View All Duplicate Images</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "16px",
            }}
          >
            {duplicateImages.length > 0 ? (
              duplicateImages.map((image, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  <img
                    src={`${constant.scannerUrl}/images/${image.image}`}
                    alt={`Duplicate ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
              ))
            ) : (
              <p style={{ gridColumn: "1 / -1", textAlign: "center" }}>
                No duplicate images found.
              </p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDuplicateImage(false)}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        className="deleteModal"
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete Biodata"
        content="Are you sure to delete this biodata ?"
        action={
          <>
            <Button
              variant="contained"
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDelete();
              }}
            >
              Yes
            </Button>
          </>
        }
      />
    </div>
  );
}
