import PropTypes from "prop-types";
import { useState, useRef } from "react";
import {
  Box,
  Card,
  Divider,
  Grid,
  Tooltip,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  MenuItem,
  CardContent,
  DialogActions,
} from "@mui/material";
import { _socials } from "../../../../_mock/arrays";
import Image from "../../../../components/image";
import Switch from "@mui/material/Switch";
import ConfirmDialog from "../../../../components/confirm-dialog";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { useForm } from "react-hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField } from "src/components/hook-form";
import { updateBannerApi, updateBannerStatusApi } from "src/service";

BannerCard.propTypes = {
  img: PropTypes.object,
  selected: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
};

export default function BannerCard({ img, onDelete, getBanner }) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [filePreview, setFilePreview] = useState(null);
  const [status, setStatus] = useState(Number(img?.status) || 0);
  const fileRef = useRef(null);

  const LoginSchema = Yup.object().shape({});

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      name: img?.name || "",
      url: img?.url || "",
      status: img?.status || "",
      clickable: img?.clickable ? "Yes" : "No",
    },
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      if (
        fileRef.current &&
        fileRef.current.files &&
        fileRef.current.files.length > 0
      ) {
        formData.append("images", fileRef.current.files[0]);
        formData.append("name", data?.name ?? img?.name);
        formData.append("url", data?.url ?? img?.url);
        formData.append("status", 1);
        formData.append("clickable", data?.clickable ?? img?.clickable);
        const res = await updateBannerApi(img?.id, formData);
        enqueueSnackbar(res?.data?.message);
        reset();
        handleClose();
        getBanner();
      } else {
        enqueueSnackbar("Image Cannot be Empty It is Compolsory", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error(error);
      reset();
      setError("afterSubmit", {
        ...error,
        message: error.message,
      });
    }
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setFilePreview(e.target.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setFilePreview(null);
    }
  };

  const handleStatusChange = async () => {
    setStatus(status === 0 ? 1 : 0);
    let obj = {
      status: String(status === 0 ? 1 : 0),
    };
    const res = await updateBannerStatusApi(img?.id, obj);
    enqueueSnackbar(res?.data?.message);
  };

  return (
    <Grid sx={{ mb: 15 }}>
      <Card key={img.value} sx={{ textAlign: "center" }}>
        <Box
          variant="subtitle1"
          display="flex"
          justifyContent="center"
          sx={{ flexDirection: "row" }}
        >
          <Typography
            variant="overline"
            sx={{ mb: 3, mt: 3, display: "block", color: "text.secondary" }}
          >
            Name :&nbsp;&nbsp;
          </Typography>

          <Typography
            variant="overline"
            sx={{ mb: 3, mt: 3, display: "block", color: "text.disabled" }}
          >
            {img?.name}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: "solid" }} />

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="overline"
            sx={{ mt: 0.5, ml: 2, color: "text.secondary" }}
          >
            Id :
            <Typography
              variant="overline"
              sx={{ mt: 0.5, color: "text.disabled" }}
            >
              &nbsp;{img?.id}
            </Typography>
          </Typography>

          <Typography
            variant="overline"
            sx={{ mt: 0.5, display: "block", color: "text.secondary" }}
          >
            Status
            <Switch
              size="small"
              checked={Number(status) === 1}
              onChange={handleStatusChange}
            />
          </Typography>
        </Box>
        <Image
          disabledEffect
          alt={img.value}
          src={img?.path}
          ratio={img.value}
          sx={{ m: 1.5, borderRadius: 1 }}
        />

        <box open={openPopover} onClose={handleClosePopover} arrow="right-top">
          <Tooltip
            title="Delete"
            onClick={() => {
              handleOpenConfirm();
              handleClosePopover();
            }}
            sx={{
              position: "relative",
              right: "10px",
              mb: 1.5,
              typography: "body1",
            }}
          >
            <Button variant="contained" size="small">
              Delete
            </Button>
          </Tooltip>

          <Tooltip
            title="Update"
            onClick={() => {
              handleClose();
            }}
            sx={{
              position: "relative",
              left: "10px",
              mb: 1.5,
              typography: "body1",
            }}
          >
            <Button variant="contained" color="inherit" size="small">
              Edit
            </Button>
          </Tooltip>
        </box>
        <ConfirmDialog
          className="deleteModal"
          open={openConfirm}
          onClose={handleCloseConfirm}
          title="Delete"
          content="Are you sure want to delete?"
          action={
            <div>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseConfirm}
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  onDelete(img?.id);
                  handleCloseConfirm();
                }}
              >
                Delete
              </Button>
            </div>
          }
        />
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        sx={{ textAlign: "center" }}
      >
        <DialogTitle>Edit Banner</DialogTitle>

        <DialogContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Box
                  rowGap={3}
                  columnGap={2}
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <Card sx={{ p: 4, mt: 3, mb: 2 }}>
                    <Stack spacing={3}>
                      <RHFTextField name="name" label="Name" />
                      <RHFTextField name="url" label="URL" />
                      <RHFTextField
                        native
                        fullWidth
                        select
                        name="clickable"
                        label="Clickable"
                        SelectProps={{
                          MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                        }}
                        sx={{
                          maxWidth: { sm: "auto" },
                          textTransform: "capitalize",
                          my: 1,
                        }}
                      >
                        {[
                          { label: "Yes", value: "1" },
                          { label: "No", value: "0" },
                        ]?.map((i) => (
                          <MenuItem
                            key={i?.id}
                            value={i.value || img?.clickable}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {i.label}
                          </MenuItem>
                        ))}
                      </RHFTextField>
                      <Grid item xs={6}>
                        <label
                          htmlFor="file"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            backgroundColor: "#f7fbfe",
                            padding: "0.5em",
                            width: "100%",
                            borderRadius: 4,
                          }}
                        >
                          <PermMediaIcon
                            color="success"
                            sx={{ marginRight: 1 }}
                          />
                          <Typography variant="body1" color="textSecondary">
                            Choose File
                          </Typography>
                          <input
                            type="file"
                            id="file"
                            ref={fileRef}
                            accept=".png, .jpg, .jpeg"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                        </label>
                      </Grid>
                      {filePreview && (
                        <Card sx={{ maxWidth: "300px", m: "1em" }}>
                          <CardContent>
                            <div>
                              <img
                                src={filePreview}
                                alt="Selected"
                                style={{ maxWidth: "200px", height: "auto" }}
                              />
                            </div>
                          </CardContent>
                        </Card>
                      )}
                    </Stack>
                  </Card>
                </Box>
              </Grid>
            </Grid>
            <Button
              color="inherit"
              size="large"
              type="submit"
              variant="contained"
              // loading={isSubmitSuccessful || isSubmitting}
              sx={{
                my: 2,
                bgcolor: "text.primary",
                width: 220,
                justifyContent: "center",
                typography: "body1",
                color: (theme) =>
                  theme.palette.mode === "light" ? "common.white" : "grey.800",
                "&:hover": {
                  bgcolor: "text.primary",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                },
              }}
            >
              Update
            </Button>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
