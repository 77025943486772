import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  Checkbox,
  Tooltip,
  Badge,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  Switch,
  Grid,
  TextField,
  CardHeader,
  FormControl,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogContent,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import ConfirmDialog from "../../../../components/confirm-dialog";
import { restoreApi, updateApprovalPopup, updateUser } from "src/service";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../../../components/snackbar";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import Image from "../../../../components/image";
import blankUSer from "../../../../assets/images/blank-profile-picture-973460__340.webp";
import constant, { currentAppVersion } from "src/Constant";
import { useAuthContext } from "src/auth/useAuthContext";
import { Link as RouterLink } from "react-router-dom";

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

const styles = {
  marginZero: {
    marginTop: "0px",
    fontWeight: "normal",
    color: "rgb(105, 105, 105)",
    textTransform: "capitalize",
    whiteSpace: "unset",
  },
  smallNormalText: {
    marginTop: "0px",
    fontWeight: "normal",
    color: "rgb(105, 105, 105)",
    fontSize: 12.5,
    whiteSpace: "unset",
  },
  smallText: {
    fontSize: 12.5,
    marginTop: "0px",
    fontWeight: "normal",
    whiteSpace: "unset",
  },
  smallDangerText: {
    marginTop: "0px",
    fontWeight: "normal",
    color: "red",
    fontSize: 12.5,
    whiteSpace: "unset",
  },
};
// const ratingArray = [
//   { key: 0, title: "0" },
//   { key: 1, title: "1" },
//   { key: 2, title: "2" },
//   { key: 3, title: "3" },
//   { key: 4, title: "4" },
//   { key: 5, title: "5" },
//   { key: 7, title: "S" },
//   { key: 8, title: "L" },

// ]

export default function UserTableRow({
  openPopup,
  showPushNotif,
  handleToggleOne,
  check,
  row,
  selected,
  staffNumber,
  onSelectRow,
  onDeleteRow,
  payPackge,
  type,
  notes,
  setFilter,
  getOnlineUserAnalyticsApi,
  globalshow,
}) {
  const {
    nameEnglish,
    id,
    mobile,
    first_formGenderEnglish,
    profiles,
    first_formProfileEnglish,
  } = row;
  const [newRow, setNewRow] = useState(row);
  const [chnageRating, setChangeRating] = useState(false);
  const [ratingID, setRatingID] = useState(null);
  const [noteModal, setNoteModal] = useState(false);
  const [shadiDone, setShadiDoneModel] = useState(false);
  const [noteModalValue, setNoteModalValue] = useState(newRow?.note || "");
  const { enqueueSnackbar } = useSnackbar();
  const { copy } = useCopyToClipboard();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [otherNumber, setSetOtherNumber] = useState(true);
  const [note, setNote] = useState([]);
  const contxt = useAuthContext();
  const [openRConfirm, setROpenConfirm] = useState(false);
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [historyPopupOpen, setHistoryPopupOpen] = useState(false);
  const [nphistoryPopupOpen, setNpHistoryPopupOpen] = useState(false);
  const [nchistoryPopupOpen, setNcHistoryPopupOpen] = useState(false);

  const [viewHour, setViewHour] = useState("");
  const handleSwitchChange = async () => {
    // Check if payment is not verified
    if (contxt?.user?.payment_verified_at === 0) {
      setOpenViewPopup(true);
    } else {
      // Proceed with updating the live status and viewHour
      await updateuser({
        live: newRow.live ? 0 : 1,
        live_date: new Date(),
        live_by_staff_id: contxt?.user?.id,
        viewHour: viewHour, // Update viewHour
      });

      if (!newRow.live) await updateApprovalPopup(newRow);
      setNewRow({
        ...newRow,
        live: newRow.live ? 0 : 1,
        live_date: new Date(),
        live_by_staff_id: contxt?.user?.id,
        liveByStaff: {
          name: {
            english: contxt?.user?.nameEnglish,
          },
        },
      });
    }
  };
  const handleUpdateViewHour = async () => {
    // Update viewHour
    await updateuser({
      viewHour: viewHour,
      live: newRow.live ? 0 : 1,
      live_date: new Date(),
      live_by_staff_id: contxt?.user?.id,
    });
    setNewRow({
      ...newRow,
      live: newRow.live ? 0 : 1,
      live_date: new Date(),
      live_by_staff_id: contxt?.user?.id,
      liveByStaff: {
        name: {
          english: contxt?.user?.nameEnglish,
        },
      },
    });

    // Close the popup
    setOpenViewPopup(false);
  };

  useEffect(() => {
    if (type?.type === "deletedUsers") setShow(false);
    else setShow(true);
  }, [type]);

  useEffect(() => {
    if (type?.type === "onlineUsers") setShow1(false);
    else setShow1(true);
  }, [type]);

  useEffect(() => {
    setNote(
      notes?.map((val) => {
        return {
          status: newRow?.note?.includes(val.title) ? true : false,
          name: val.title,
        };
      })
    );
  }, [notes]);

  const updateuser = async (data) => {
    var res = await updateUser(id, data);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
    } else enqueueSnackbar(res?.data?.errors);
  };

  // call history
  const handleOpenHistory = () => {
    setHistoryPopupOpen(true);
  };

  const maskNumber = (phoneNumber) => {
    const number = contxt?.maskPhoneNumber(phoneNumber);
    if (number) return number;
  };

  const handleCloseHistory = () => {
    setHistoryPopupOpen(false);
  };

  //np history pop
  const handleNpOpenHistory = () => {
    setNpHistoryPopupOpen(true);
  };

  const handleNpCloseHistory = () => {
    setNpHistoryPopupOpen(false);
  };

  //nc history pop
  const handleNcOpenHistory = () => {
    setNcHistoryPopupOpen(true);
  };

  const handleNcCloseHistory = () => {
    setNcHistoryPopupOpen(false);
  };

  const handleCopyId = () => {
    enqueueSnackbar("Copied!");
    copy(id);
  };

  const handleOpenConfirmR = () => {
    setROpenConfirm(true);
  };
  const restoreuser = async () => {
    var res = await restoreApi(id);
  };
  const handleCopymobile = () => {
    enqueueSnackbar("Copied!");
    copy(maskNumber(mobile));
  };

  const handleCopyShadiDoneUserId = () => {
    enqueueSnackbar("Copied!");
    copy(newRow?.ShadiDoneData?.User?.id);
  };

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const handleCheckboxChange = () => {
    onSelectRow(id);
  };

  let p = profiles?.find((i) => {
    return i?.type === "profile" && i?.order_number === 10;
  });
  if (!p)
    p = profiles?.find((i) => {
      return i?.type === "profile";
    });

  return (
    <>
      <TableRow hover type={type}>
        <TableCell>
          <div style={{ display: "flex", alignItems: "center" }}>
            {(check || showPushNotif || openPopup) && (
              <Checkbox
                size="small"
                checked={selected}
                onChange={() => handleToggleOne(newRow.id)}
              />
            )}
            <Stack style={{ width: "100%" }}>
              <div>
                {globalshow && (
                  <div>
                    {newRow?.deleted_at ? (
                      <span
                        style={{
                          color: "#fff",
                          background: "red",
                          padding: "3px 10px",
                        }}
                      >
                        Deleted User
                      </span>
                    ) : newRow?.shadi_done ? (
                      <span
                        style={{
                          color: "#fff",
                          background: "red",
                          padding: "3px 10px",
                        }}
                      >
                        Shadi Done User
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#fff",
                          background: "red",
                          padding: "3px 10px",
                        }}
                      >
                        Online Section
                      </span>
                    )}
                  </div>
                )}

                {p ? (
                  <RouterLink
                    to={`/onlineuser/preview?url=${p?.folder}/${p?.name}`}
                    target={"_blank"}
                  >
                    <Image
                      src={`${constant.appBaseUrl}/${p?.folder}/${p?.name}`}
                      alt="about-vision"
                      style={{
                        width: 70,
                        height: 70,
                        borderRadius: 8,
                        boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }}
                    />
                  </RouterLink>
                ) : (
                  <Image
                    src={blankUSer}
                    alt="about-vision"
                    style={{
                      width: 70,
                      height: 70,
                      borderRadius: 8,
                      boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  />
                )}
              </div>
              <Typography variant="subtitle2" noWrap>
                <Iconify
                  icon="material-symbols:content-copy-outline"
                  width="24"
                  height="18"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyId();
                  }}
                />
                {/* {id} {newRow?.new_user ? <Iconify className="star" icon="eva:star-fill" icon="iconamoon:notification-off-duotone" /> : ""} */}
                {id}{" "}
                {newRow?.new_user ? (
                  <>
                    <Iconify className="star" icon="eva:star-fill" />
                    {newRow?.app_id && (
                      <Iconify
                        className="star"
                        icon="basil:notification-on-solid"
                      />
                    )}
                    {!newRow?.app_id && (
                      <Iconify
                        className="star text-danger"
                        icon="ri:notification-off-fill"
                      />
                    )}
                  </>
                ) : (
                  ""
                )}
                {newRow?.web_app === "website" ? (
                  <Badge badgeContent={"W"} color="info" sx={{ ml: 2 }}></Badge>
                ) : (
                  ""
                )}
                {newRow?.web_app === "app" ? (
                  <Badge badgeContent={"A"} color="info" sx={{ ml: 2 }}></Badge>
                ) : (
                  ""
                )}
                {!newRow?.web_app ? (
                  <Badge badgeContent={"A"} color="info" sx={{ ml: 2 }}></Badge>
                ) : (
                  ""
                )}
                {newRow?.web_app === "master" ? (
                  <Badge badgeContent={"M"} color="info" sx={{ ml: 2 }}></Badge>
                ) : (
                  ""
                )}
              </Typography>
              <Typography
                variant="subtitle2"
                color={first_formGenderEnglish === "Male" ? "#000" : "#000"}
              >
                <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                  {" "}
                  {nameEnglish}{" "}
                </span>
                {newRow?.otp_submitted_button ? (
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "green",
                    }}
                  >
                    OTP
                  </span>
                ) : (
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "red",
                    }}
                  >
                    OTP
                  </span>
                )}
                {`(${newRow?.totalAccount})`} &nbsp;
                {row?.app_version === currentAppVersion ? (
                  <>
                    <span className="text-danger">Latest</span>
                    <Iconify
                      className="star text-danger"
                      icon="eva:star-fill"
                    />
                    -
                  </>
                ) : (
                  ""
                )}
                (
                <span
                  className={`${
                    row?.app_version ? "text-primary" : "text-danger"
                  }`}
                >
                  {row?.app_version ? row?.app_version : "App Inactive"}
                </span>
                )
              </Typography>
              <Typography variant="subtitle2" color={"#000"}>
                <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                  {" "}
                  {first_formProfileEnglish}{" "}
                </span>
              </Typography>
              {newRow?.NotesGiveBySatff && (
                <Typography variant="subtitle2" color={"#000"}>
                  <span style={{ color: "#3366FF", fontWeight: 600 }}>
                    {newRow?.NotesGiveBySatff?.map((i) =>
                      i?.notes === null ? null : (
                        <div>
                          <b style={{ color: "#FF3030" }}>Online : </b>
                          <span style={{ color: "#3366FF", fontWeight: 600 }}>
                            {i?.StaffName?.name?.english}{" "}
                            {i?.StaffName?.public_view === 0 ? (
                              <span style={{ color: "Red" }}>(Old Staff)</span>
                            ) : null}{" "}
                            (
                            {`${moment(i?.created_at).format(
                              "DD-MM-YY, h:mm a"
                            )}`}
                            )
                          </span>
                          <span style={{ color: "#000", fontWeight: 400 }}>
                            {i?.created_for} {i?.notes} {i?.custom_notes}
                          </span>
                        </div>
                      )
                    )}
                  </span>
                </Typography>
              )}
              {/* {newRow?.biodataUserNotes?.notes &&
                <Typography variant="subtitle2" color={"#000"}>
                  <span style={{ textTransform: "capitalize", fontWeight: 600, color: "#FF3030" }}>Biodata :
                    <span style={{ color: "#3366FF", fontWeight: 600 }}>{newRow?.biodataUserNotes?.notesGiveStaffName?.name?.english} {contxt?.user?.public_view === 0 ? <span style={{ color: "Red" }}>(Old)</span> : null} [{newRow?.biodataUserNotes?.call_date_time ? `${moment(newRow?.biodataUserNotes?.call_date_time).format('DD-MM-YY, h:mm a')}` : null}] &nbsp;</span>
                    <span style={{ color: "#000", fontWeight: 400 }}>{newRow?.biodataUserNotes?.notes}{newRow?.biodataUserNotes?.other}
                      [{newRow?.biodataUserNotes?.notes_date_time ? `${moment(newRow?.biodataUserNotes?.notes_date_time).format('DD-MM-YY, h:mm a')}` : null}]
                    </span>
                  </span>
                </Typography>} */}
              {/* Biodata note history Start */}
              {newRow?.biodataResponses ? (
                <Typography variant="subtitle2" color="#000">
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "#FF3030",
                    }}
                  >
                    Biodata :{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#3366FF",
                      }}
                    >
                      {
                        newRow?.biodataResponses?.biodataNotHistory[
                          newRow?.biodataResponses?.biodataNotHistory?.length -
                            1
                        ]?.biodataStaff?.name?.english
                      }
                      {newRow?.biodataResponses?.biodataNotHistory[
                        newRow?.biodataResponses?.biodataNotHistory?.length - 1
                      ]?.biodataStaff?.public_view === 0 ? (
                        <span style={{ color: "red" }}>(Old Staff)</span>
                      ) : null}
                      :
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.biodataResponses?.biodataNotHistory[
                          newRow?.biodataResponses?.biodataNotHistory?.length -
                            1
                        ]?.other
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.biodataResponses?.biodataNotHistory[
                          newRow?.biodataResponses?.biodataNotHistory?.length -
                            1
                        ]?.notes
                      }
                    </span>
                    {newRow?.biodataResponses?.biodataNotHistory[
                      newRow?.biodataResponses?.biodataNotHistory?.length - 1
                    ]?.notes_date_time && (
                      <span style={{ color: "blue", fontWeight: 500 }}>
                        [
                        {moment(
                          newRow?.biodataResponses?.biodataNotHistory[
                            newRow?.biodataResponses?.biodataNotHistory
                              ?.length - 1
                          ]?.notes_date_time
                        ).format("DD-MM-YY, h:mm a")}
                        ]
                      </span>
                    )}
                  </span>
                </Typography>
              ) : null}
              {newRow?.biodataResponses ? (
                <div style={{ fontWeight: "600", fontSize: "12px" }}>
                  {newRow?.biodataResponses?.call_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Call :-
                      <span style={{ color: "Blue" }}>
                        {moment(
                          newRow?.biodataResponses?.call_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.biodataResponses?.call_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "Blue" }}>
                        Name :-{" "}
                        {
                          newRow?.biodataResponses?.ICallBioStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.biodataResponses?.pickup_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio NP :-
                      <span style={{ color: "Blue" }}>
                        {moment(
                          newRow?.biodataResponses?.pickup_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.biodataResponses?.pickup_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "Blue" }}>
                        Name :-{" "}
                        {
                          newRow?.biodataResponses?.ICallBioStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.biodataResponses?.not_connected_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio NC :-
                      <span style={{ color: "Blue" }}>
                        {moment(
                          newRow?.biodataResponses?.not_connected_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.biodataResponses?.not_connected_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "Blue" }}>
                        Name :-{" "}
                        {
                          newRow?.biodataResponses?.ICallBioStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.biodataResponses?.normal_share && (
                    <span style={{ color: "red" }}>
                      Bio Share Note : -{" "}
                      <span className="textDark">
                        {newRow?.biodataResponses?.normal_share}
                        {newRow?.biodataResponses?.share_notes}
                      </span>{" "}
                    </span>
                  )}
                  {newRow?.biodataResponses?.special_notes && (
                    <span style={{ color: "red" }}>
                      Bio Special Share : -{" "}
                      <span className="textDark">
                        {newRow?.biodataResponses?.special_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.biodataResponses?.different_language_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Different Language : -{" "}
                      <span className="textDark">
                        {newRow?.biodataResponses?.different_language_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.biodataResponses?.not_interested_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Not Interested :-{" "}
                      <span className="textDark">
                        {newRow?.biodataResponses?.not_interested_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.biodataResponses?.sambandh_custom_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Sambandh Custom Note :-{" "}
                      <span className="textDark">
                        {newRow?.biodataResponses?.sambandh_custom_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.biodataResponses?.sambandh_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Sambandh :-{" "}
                      <span className="textDark">
                        {newRow?.biodataResponses?.sambandh_notes}
                      </span>
                    </p>
                  )}
                </div>
              ) : null}{" "}
              {/* Biodata note history end */}
              {newRow?.biodataResponses && (
                <div style={{ fontWeight: "600", fontSize: "12px" }}>
                  {/* Show Call Status */}
                  {newRow?.biodataResponses?.call_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Call :-
                      <span style={{ color: "Blue" }}>
                        {moment(
                          newRow?.biodataResponses?.call_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.biodataResponses?.call_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "Blue" }}>
                        Name :-{" "}
                        {
                          newRow?.biodataResponses?.ICallBioStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}

                  {/* Show Share Notes */}
                  {newRow?.biodataResponses?.normal_share && (
                    <span style={{ color: "red" }}>
                      Bio Share Note : -
                      <span className="textDark">
                        {newRow?.biodataResponses?.normal_share}
                        {newRow?.biodataResponses?.share_notes}
                      </span>
                    </span>
                  )}

                  {/* Show Special Notes */}
                  {newRow?.biodataResponses?.special_notes && (
                    <span style={{ color: "red" }}>
                      Bio Special Share : -
                      <span className="textDark">
                        {newRow?.biodataResponses?.special_notes}
                      </span>
                    </span>
                  )}

                  {/* Show Different Language Notes */}
                  {newRow?.biodataResponses?.different_language_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Different Language : -
                      <span className="textDark">
                        {newRow?.biodataResponses?.different_language_notes}
                      </span>
                    </p>
                  )}

                  {/* Show Not Interested Notes */}
                  {newRow?.biodataResponses?.not_interested_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Not Interested :-
                      <span className="textDark">
                        {newRow?.biodataResponses?.not_interested_notes}
                      </span>
                    </p>
                  )}

                  {/* Show Sambandh Custom Notes */}
                  {newRow?.biodataResponses?.sambandh_custom_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Sambandh Custom Note :-
                      <span className="textDark">
                        {newRow?.biodataResponses?.sambandh_custom_notes}
                      </span>
                    </p>
                  )}

                  {/* Show Sambandh Notes */}
                  {newRow?.biodataResponses?.sambandh_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Bio Sambandh :-
                      <span className="textDark">
                        {newRow?.biodataResponses?.sambandh_notes}
                      </span>
                    </p>
                  )}
                </div>
              )}
              {/* offline notes history Start*/}
              {newRow?.offlineResponses?.offNoteHistory?.length ? (
                <Typography variant="subtitle2" color="#000">
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "#FF3030",
                    }}
                  >
                    Offline :{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#3366FF",
                      }}
                    >
                      {
                        newRow?.offlineResponses?.offNoteHistory[
                          newRow?.offlineResponses?.offNoteHistory?.length - 1
                        ]?.OffStaffName?.name?.english
                      }{" "}
                      {newRow?.offlineResponses?.offNoteHistory[
                        newRow?.offlineResponses?.offNoteHistory?.length - 1
                      ]?.OffStaffName?.public_view === 0 ? (
                        <span style={{ color: "red" }}>(Old Staff)</span>
                      ) : null}
                      :
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.offlineResponses?.offNoteHistory[
                          newRow?.offlineResponses?.offNoteHistory?.length - 1
                        ]?.partner_preference
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.offlineResponses?.offNoteHistory[
                          newRow?.offlineResponses?.offNoteHistory?.length - 1
                        ]?.other
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.offlineResponses?.offNoteHistory[
                          newRow?.offlineResponses?.offNoteHistory?.length - 1
                        ]?.notes
                      }
                    </span>
                    {newRow?.offlineResponses?.offNoteHistory[
                      newRow?.offlineResponses?.offNoteHistory?.length - 1
                    ]?.notes_date_time && (
                      <span style={{ color: "blue", fontWeight: 500 }}>
                        [
                        {moment(
                          newRow?.offlineResponses?.offNoteHistory[
                            newRow?.offlineResponses?.offNoteHistory?.length - 1
                          ]?.notes_date_time
                        ).format("DD-MM-YY, h:mm a")}
                        ]
                      </span>
                    )}
                  </span>
                </Typography>
              ) : null}
              {newRow?.offlineResponses ? (
                <div style={{ fontWeight: "600", fontSize: "12px" }}>
                  {newRow?.offlineResponses?.call_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Offline Call :- &nbsp;
                      <span style={{ color: "Blue" }}>
                        {moment(
                          newRow?.offlineResponses?.call_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.offlineResponses?.call_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "Blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.offlineResponses?.ICallOffStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.offlineResponses?.pickup_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Offline NP :- &nbsp;
                      <span style={{ color: "Blue" }}>
                        {moment(
                          newRow?.offlineResponses?.pickup_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.offlineResponses?.pickup_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "Blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.offlineResponses?.ICallOffStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.offlineResponses?.not_connected_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Offline NC :- &nbsp;
                      <span style={{ color: "Blue" }}>
                        {moment(
                          newRow?.offlineResponses?.not_connected_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.offlineResponses?.not_connected_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "Blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.offlineResponses?.ICallOffStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.offlineResponses?.normal_share && (
                    <span style={{ color: "red" }}>
                      Offline Share Note : -{" "}
                      <span className="textDark">
                        {" "}
                        {newRow?.offlineResponses?.normal_share}{" "}
                        {newRow?.offlineResponses?.share_notes} &nbsp;
                      </span>
                    </span>
                  )}
                  {newRow?.offlineResponses?.special_notes && (
                    <span style={{ color: "red" }}>
                      Offline Special Share : -{" "}
                      <span className="textDark">
                        {newRow?.offlineResponses?.special_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.offlineResponses?.different_language_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Offline Different Language : -{" "}
                      <span className="textDark">
                        {newRow?.offlineResponses?.different_language_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.offlineResponses?.not_interested_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Offline Not Interested :-{" "}
                      <span className="textDark">
                        {newRow?.offlineResponses?.not_interested_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.offlineResponses?.sambandh_custom_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Offline Sambandh Custom Note :-{" "}
                      <span className="textDark">
                        {newRow?.offlineResponses?.sambandh_custom_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.offlineResponses?.sambandh_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Offline Sambandh :-{" "}
                      <span className="textDark">
                        {newRow?.offlineResponses?.sambandh_notes}
                      </span>
                    </p>
                  )}
                </div>
              ) : null}
              {/* offline notes history end*/}
              {/* whatsapp response  start*/}
              {newRow?.whatsappResponses?.whatsappResponseshistory?.length ? (
                <Typography variant="subtitle2" color="#000">
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "#FF3030",
                    }}
                  >
                    Whatsapp Response :{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#3366FF",
                      }}
                    >
                      {
                        newRow?.whatsappResponses?.whatsappResponseshistory[
                          newRow?.whatsappResponses?.whatsappResponseshistory
                            ?.length - 1
                        ]?.staffName?.name?.english
                      }{" "}
                      {newRow?.whatsappResponses?.whatsappResponseshistory[
                        newRow?.whatsappResponses?.whatsappResponseshistory
                          ?.length - 1
                      ]?.staffName?.public_view === 0 ? (
                        <span style={{ color: "red" }}>(Old Staff)</span>
                      ) : null}
                      :
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.whatsappResponses?.whatsappResponseshistory[
                          newRow?.whatsappResponses?.whatsappResponseshistory
                            ?.length - 1
                        ]?.partner_preference
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.whatsappResponses?.whatsappResponseshistory[
                          newRow?.whatsappResponses?.whatsappResponseshistory
                            ?.length - 1
                        ]?.other
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.whatsappResponses?.whatsappResponseshistory[
                          newRow?.whatsappResponses?.whatsappResponseshistory
                            ?.length - 1
                        ]?.notes
                      }
                    </span>
                    {newRow?.whatsappResponses?.whatsappResponseshistory[
                      newRow?.whatsappResponses?.whatsappResponseshistory
                        ?.length - 1
                    ]?.notes_date_time && (
                      <span style={{ color: "blue", fontWeight: 500 }}>
                        [
                        {moment(
                          newRow?.whatsappResponses?.whatsappResponseshistory[
                            newRow?.whatsappResponses?.whatsappResponseshistory
                              .length - 1
                          ]?.notes_date_time
                        ).format("DD-MM-YY, h:mm a")}
                        ]
                      </span>
                    )}
                  </span>
                </Typography>
              ) : null}
              {newRow?.whatsappResponses ? (
                <div style={{ fontWeight: "600", fontSize: "12px" }}>
                  {newRow?.whatsappResponses?.call_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Whatsapp Call :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.whatsappResponses?.call_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.whatsappResponses?.call_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.whatsappResponses?.ICallWhatsResStaffsName
                            ?.name?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.whatsappResponses?.pickup_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Whatsapp NP :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.whatsappResponses?.pickup_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.whatsappResponses?.pickup_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.whatsappResponses?.ICallWhatsResStaffsName
                            ?.name?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.whatsappResponses?.not_connected_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Whatsapp NC :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.whatsappResponses?.not_connected_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.whatsappResponses?.not_connected_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.whatsappResponses?.ICallWhatsResStaffsName
                            ?.name?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.whatsappResponses?.normal_share && (
                    <span style={{ color: "red" }}>
                      Whatsapp Share Note : -{" "}
                      <span className="textDark">
                        {newRow?.whatsappResponses?.normal_share}
                        {newRow?.whatsappResponses?.share_notes}
                      </span>{" "}
                      &nbsp;
                    </span>
                  )}
                  {newRow?.whatsappResponses?.special_notes && (
                    <span style={{ color: "red" }}>
                      Whatsapp Special Share : -{" "}
                      <span className="textDark">
                        {newRow?.whatsappResponses?.special_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.whatsappResponses?.different_language_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Whatsapp Different Language : -{" "}
                      <span className="textDark">
                        {newRow?.whatsappResponses?.different_language_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.whatsappResponses?.not_interested_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Whatsapp Not Interested :-{" "}
                      <span className="textDark">
                        {newRow?.whatsappResponses?.not_interested_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.whatsappResponses?.sambandh_custom_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Whatsapp Sambandh Custom Note :-{" "}
                      <span className="textDark">
                        {newRow?.whatsappResponses?.sambandh_custom_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.whatsappResponses?.sambandh_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Whatsapp Sambandh :-{" "}
                      <span className="textDark">
                        {newRow?.whatsappResponses?.sambandh_notes}
                      </span>
                    </p>
                  )}
                </div>
              ) : null}
              {/*  whatsapp response end */}
              {/* social note start*/}
              {newRow?.SocailResponses?.SocialNotHistory?.length ? (
                <Typography variant="subtitle2" color="#000">
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "#FF3030",
                    }}
                  >
                    Social Media :{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#3366FF",
                      }}
                    >
                      {
                        newRow?.SocailResponses?.SocialNotHistory[
                          newRow?.SocailResponses?.SocialNotHistory?.length - 1
                        ]?.SocialStaff?.name?.english
                      }{" "}
                      {newRow?.SocailResponses?.SocialNotHistory[
                        newRow?.SocailResponses?.SocialNotHistory?.length - 1
                      ]?.SocialStaff?.public_view === 0 ? (
                        <span style={{ color: "red" }}>(Old Staff)</span>
                      ) : null}
                      :
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.SocailResponses?.SocialNotHistory[
                          newRow?.SocailResponses?.SocialNotHistory?.length - 1
                        ]?.partner_preference
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.SocailResponses?.SocialNotHistory[
                          newRow?.SocailResponses?.SocialNotHistory?.length - 1
                        ]?.other
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.SocailResponses?.SocialNotHistory[
                          newRow?.SocailResponses?.SocialNotHistory?.length - 1
                        ]?.notes
                      }
                    </span>
                    {newRow?.SocailResponses?.SocialNotHistory[
                      newRow?.SocailResponses?.SocialNotHistory?.length - 1
                    ]?.notes_date_time && (
                      <span style={{ color: "blue", fontWeight: 500 }}>
                        [
                        {moment(
                          newRow?.SocailResponses?.SocialNotHistory[
                            newRow?.SocailResponses?.SocialNotHistory?.length -
                              1
                          ]?.notes_date_time
                        ).format("DD-MM-YY, h:mm a")}
                        ]
                      </span>
                    )}
                  </span>
                </Typography>
              ) : null}
              {newRow?.SocailResponses ? (
                <div style={{ fontWeight: "600", fontSize: "12px" }}>
                  {newRow?.SocailResponses?.call_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Social Call :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(newRow?.SocailResponses?.call_date_time).format(
                          "hh:mm a"
                        )}{" "}
                        ,{" "}
                        {moment(newRow?.SocailResponses?.call_date_time).format(
                          "DD/MM/YY"
                        )}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.SocailResponses?.ICallSocialStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.SocailResponses?.pickup_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Social NP :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.SocailResponses?.pickup_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.SocailResponses?.pickup_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.SocailResponses?.ICallSocialStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.SocailResponses?.not_connected_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Social NC :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.SocailResponses?.not_connected_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.SocailResponses?.not_connected_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.SocailResponses?.ICallSocialStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.SocailResponses?.normal_share && (
                    <span style={{ color: "red" }}>
                      Social Share Note : -{" "}
                      <span className="textDark">
                        {newRow?.SocailResponses?.normal_share}
                        {newRow?.SocailResponses?.share_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.SocailResponses?.special_notes && (
                    <span style={{ color: "red" }}>
                      Social Special Share : -{" "}
                      <span className="textDark">
                        {newRow?.SocailResponses?.special_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.SocailResponses?.different_language_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Social Different Language : -{" "}
                      <span className="textDark">
                        {newRow?.SocailResponses?.different_language_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.SocailResponses?.not_interested_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Social Not Interested :-{" "}
                      <span className="textDark">
                        {newRow?.SocailResponses?.not_interested_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.SocailResponses?.sambandh_custom_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Social Sambandh Custom Note :-{" "}
                      <span className="textDark">
                        {newRow?.SocailResponses?.sambandh_custom_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.SocailResponses?.sambandh_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Social Sambandh :-{" "}
                      <span className="textDark">
                        {newRow?.SocailResponses?.sambandh_notes}
                      </span>
                    </p>
                  )}
                </div>
              ) : null}
              {/* social note end */}
              {/* group note start */}
              {newRow?.GroupResponses?.GroupNotHistory?.length ? (
                <Typography variant="subtitle2" color="#000">
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "#FF3030",
                    }}
                  >
                    Whatsapp Group Services :{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#3366FF",
                      }}
                    >
                      {
                        newRow?.GroupResponses?.GroupNotHistory[
                          newRow?.GroupResponses?.GroupNotHistory?.length - 1
                        ]?.GroupStaff?.name?.english
                      }{" "}
                      {newRow?.GroupResponses?.GroupNotHistory[
                        newRow?.GroupResponses?.GroupNotHistory?.length - 1
                      ]?.GroupStaff?.public_view === 0 ? (
                        <span style={{ color: "red" }}>(Old Staff)</span>
                      ) : null}
                      :
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.GroupResponses?.GroupNotHistory[
                          newRow?.GroupResponses?.GroupNotHistory?.length - 1
                        ]?.partner_preference
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.GroupResponses?.GroupNotHistory[
                          newRow?.GroupResponses?.GroupNotHistory?.length - 1
                        ]?.other
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.GroupResponses?.GroupNotHistory[
                          newRow?.GroupResponses?.GroupNotHistory?.length - 1
                        ]?.notes
                      }
                    </span>
                    {newRow?.GroupResponses?.GroupNotHistory[
                      newRow?.GroupResponses?.GroupNotHistory?.length - 1
                    ]?.notes_date_time && (
                      <span style={{ color: "blue", fontWeight: 500 }}>
                        [
                        {moment(
                          newRow?.GroupResponses?.GroupNotHistory[
                            newRow?.GroupResponses?.GroupNotHistory?.length - 1
                          ]?.notes_date_time
                        ).format("DD-MM-YY, h:mm a")}
                        ]
                      </span>
                    )}
                  </span>
                </Typography>
              ) : null}
              {newRow?.GroupResponses ? (
                <div style={{ fontWeight: "600", fontSize: "12px" }}>
                  {newRow?.GroupResponses?.call_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Group Call :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(newRow?.GroupResponses?.call_date_time).format(
                          "hh:mm a"
                        )}{" "}
                        ,{" "}
                        {moment(newRow?.GroupResponses?.call_date_time).format(
                          "DD/MM/YY"
                        )}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.GroupResponses?.ICallGroupStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.GroupResponses?.pickup_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Group NP :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.GroupResponses?.pickup_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.GroupResponses?.pickup_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.GroupResponses?.ICallGroupStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.GroupResponses?.not_connected_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Group NC :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.GroupResponses?.not_connected_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.GroupResponses?.not_connected_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.GroupResponses?.ICallGroupStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.GroupResponses?.normal_share && (
                    <span style={{ color: "red" }}>
                      Group Share Note : -{" "}
                      <span className="textDark">
                        {newRow?.GroupResponses?.normal_share}
                        {newRow?.GroupResponses?.share_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.GroupResponses?.special_notes && (
                    <span style={{ color: "red" }}>
                      Group Special Share : -{" "}
                      <span className="textDark">
                        {newRow?.GroupResponses?.special_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.GroupResponses?.different_language_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Group Different Language : -
                      <span className="textDark">
                        {newRow?.GroupResponses?.different_language_notes}
                      </span>{" "}
                    </p>
                  )}
                  {newRow?.GroupResponses?.not_interested_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Group Not Interested :-{" "}
                      <span className="textDark">
                        {newRow?.GroupResponses?.not_interested_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.GroupResponses?.sambandh_custom_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Group Sambandh Custom Note :-{" "}
                      <span className="textDark">
                        {newRow?.GroupResponses?.sambandh_custom_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.GroupResponses?.sambandh_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Group Sambandh :-{" "}
                      <span className="textDark">
                        {newRow?.GroupResponses?.sambandh_notes}
                      </span>
                    </p>
                  )}
                </div>
              ) : null}
              {/* group note end */}
              {/* paid note end*/}
              {newRow?.PaidResponses?.PaidNotHistory?.length ? (
                <Typography variant="subtitle2" color="#000">
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "#FF3030",
                    }}
                  >
                    Paid Promotion :{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#3366FF",
                      }}
                    >
                      {
                        newRow?.PaidResponses?.PaidNotHistory[
                          newRow?.PaidResponses?.PaidNotHistory?.length - 1
                        ]?.PaidStaff?.name?.english
                      }{" "}
                      {newRow?.PaidResponses?.PaidNotHistory[
                        newRow?.PaidResponses?.PaidNotHistory?.length - 1
                      ]?.PaidStaff?.public_view === 0 ? (
                        <span style={{ color: "red" }}>(Old Staff)</span>
                      ) : null}
                      :
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.PaidResponses?.PaidNotHistory[
                          newRow?.PaidResponses?.PaidNotHistory?.length - 1
                        ]?.partner_preference
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.PaidResponses?.PaidNotHistory[
                          newRow?.PaidResponses?.PaidNotHistory?.length - 1
                        ]?.other
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.PaidResponses?.PaidNotHistory[
                          newRow?.PaidResponses?.PaidNotHistory?.length - 1
                        ]?.notes
                      }
                    </span>
                    {newRow?.PaidResponses?.PaidNotHistory[
                      newRow?.PaidResponses?.PaidNotHistory?.length - 1
                    ]?.notes_date_time && (
                      <span style={{ color: "blue", fontWeight: 500 }}>
                        [
                        {moment(
                          newRow?.PaidResponses?.PaidNotHistory[
                            newRow?.PaidResponses?.PaidNotHistory?.length - 1
                          ]?.notes_date_time
                        ).format("DD-MM-YY, h:mm a")}
                        ]
                      </span>
                    )}
                  </span>
                </Typography>
              ) : null}
              {newRow?.PaidResponses ? (
                <div style={{ fontWeight: "600", fontSize: "12px" }}>
                  {newRow?.PaidResponses?.call_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Paid Call :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(newRow?.PaidResponses?.call_date_time).format(
                          "hh:mm a"
                        )}{" "}
                        ,{" "}
                        {moment(newRow?.PaidResponses?.call_date_time).format(
                          "DD/MM/YY"
                        )}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.PaidResponses?.ICallPaidStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.PaidResponses?.pickup_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Paid NP :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(newRow?.PaidResponses?.pickup_date_time).format(
                          "hh:mm a"
                        )}{" "}
                        ,{" "}
                        {moment(newRow?.PaidResponses?.pickup_date_time).format(
                          "DD/MM/YY"
                        )}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.PaidResponses?.ICallPaidStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.PaidResponses?.not_connected_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Paid NC :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.PaidResponses?.not_connected_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.PaidResponses?.not_connected_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.PaidResponses?.ICallPaidStaffsName?.name
                            ?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.PaidResponses?.normal_share && (
                    <span style={{ color: "red" }}>
                      Paid Share Note : -{" "}
                      <span className="textDark">
                        {newRow?.PaidResponses?.normal_share}
                        {newRow?.PaidResponses?.share_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.PaidResponses?.special_notes && (
                    <span style={{ color: "red" }}>
                      Paid Special Share : -{" "}
                      <span className="textDark">
                        {newRow?.PaidResponses?.special_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.PaidResponses?.different_language_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Paid Different Language : -{" "}
                      <span className="textDark">
                        {newRow?.PaidResponses?.different_language_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.PaidResponses?.not_interested_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Paid Not Interested :-{" "}
                      <span className="textDark">
                        {newRow?.PaidResponses?.not_interested_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.PaidResponses?.sambandh_custom_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Paid Sambandh Custom Note :-{" "}
                      <span className="textDark">
                        {newRow?.PaidResponses?.sambandh_custom_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.PaidResponses?.sambandh_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Paid Sambandh :-{" "}
                      <span className="textDark">
                        {newRow?.PaidResponses?.sambandh_notes}
                      </span>
                    </p>
                  )}
                </div>
              ) : null}
              {/* paid note end */}
              {/* incoming notes start*/}
              {newRow?.IncomingCallEntries?.IncomingCallEntriesHistory
                ?.length ? (
                <Typography variant="subtitle2" color="#000">
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      color: "#FF3030",
                    }}
                  >
                    Incoming :{" "}
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#FF3030",
                      }}
                    >
                      {
                        newRow?.IncomingCallEntries?.IncomingCallEntriesHistory[
                          newRow?.IncomingCallEntries
                            ?.IncomingCallEntriesHistory?.length - 1
                        ]?.IncomingStaff?.name?.english
                      }{" "}
                      {newRow?.IncomingCallEntries?.IncomingCallEntriesHistory[
                        newRow?.IncomingCallEntries?.IncomingCallEntriesHistory
                          ?.length - 1
                      ]?.IncomingStaff?.public_view === 0 ? (
                        <span style={{ color: "red" }}>(Old Staff)</span>
                      ) : null}
                      -
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.IncomingCallEntries?.IncomingCallEntriesHistory[
                          newRow?.IncomingCallEntries
                            ?.IncomingCallEntriesHistory?.length - 1
                        ]?.partner_preference
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.IncomingCallEntries?.IncomingCallEntriesHistory[
                          newRow?.IncomingCallEntries
                            ?.IncomingCallEntriesHistory?.length - 1
                        ]?.other
                      }
                    </span>
                    <span style={{ color: "#000", fontWeight: 400 }}>
                      {
                        newRow?.IncomingCallEntries?.IncomingCallEntriesHistory[
                          newRow?.IncomingCallEntries
                            ?.IncomingCallEntriesHistory?.length - 1
                        ]?.notes
                      }
                    </span>
                    {newRow?.IncomingCallEntries?.IncomingCallEntriesHistory[
                      newRow?.IncomingCallEntries?.IncomingCallEntriesHistory
                        ?.length - 1
                    ]?.notes_date_time && (
                      <span style={{ color: "blue", fontWeight: 500 }}>
                        [
                        {moment(
                          newRow?.IncomingCallEntries
                            ?.IncomingCallEntriesHistory[
                            newRow?.IncomingCallEntries
                              ?.IncomingCallEntriesHistory?.length - 1
                          ]?.notes_date_time
                        ).format("DD-MM-YY, h:mm a")}
                        ]
                      </span>
                    )}
                  </span>
                </Typography>
              ) : null}
              {newRow?.IncomingCallEntries ? (
                <div style={{ fontWeight: "600", fontSize: "12px" }}>
                  {newRow?.IncomingCallEntries?.call_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Incoming Call :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.IncomingCallEntries?.call_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.IncomingCallEntries?.call_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.IncomingCallEntries?.ICallIncomingStaffsName
                            ?.name?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.IncomingCallEntries?.pickup_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Incoming NP :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.IncomingCallEntries?.pickup_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.IncomingCallEntries?.pickup_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.IncomingCallEntries?.ICallIncomingStaffsName
                            ?.name?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.IncomingCallEntries?.not_connected_status && (
                    <p className="m-0" style={{ color: "red" }}>
                      Incoming NC :- &nbsp;
                      <span style={{ color: "blue" }}>
                        {moment(
                          newRow?.IncomingCallEntries?.not_connected_date_time
                        ).format("hh:mm a")}{" "}
                        ,{" "}
                        {moment(
                          newRow?.IncomingCallEntries?.not_connected_date_time
                        ).format("DD/MM/YY")}
                      </span>
                      <span style={{ color: "blue" }}>
                        &nbsp; Name :-{" "}
                        {
                          newRow?.IncomingCallEntries?.ICallIncomingStaffsName
                            ?.name?.english
                        }
                      </span>
                    </p>
                  )}
                  {newRow?.IncomingCallEntries?.normal_share && (
                    <span style={{ color: "red" }}>
                      Incoming Share Note : -{" "}
                      <span className="textDark">
                        {newRow?.IncomingCallEntries?.normal_share}
                        {newRow?.IncomingCallEntries?.share_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.IncomingCallEntries?.special_notes && (
                    <span style={{ color: "red" }}>
                      Incoming Special Share : -{" "}
                      <span className="textDark">
                        {newRow?.IncomingCallEntries?.special_notes}
                      </span>
                    </span>
                  )}
                  {newRow?.IncomingCallEntries?.different_language_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Incoming Different Language : -{" "}
                      <span className="textDark">
                        {newRow?.IncomingCallEntries?.different_language_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.IncomingCallEntries?.not_interested_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Incoming Not Interested :-{" "}
                      <span className="textDark">
                        {newRow?.IncomingCallEntries?.not_interested_notes}
                      </span>{" "}
                    </p>
                  )}
                  {newRow?.IncomingCallEntries?.sambandh_custom_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Incoming Sambandh Custom Note :-{" "}
                      <span className="textDark">
                        {newRow?.IncomingCallEntries?.sambandh_custom_notes}
                      </span>
                    </p>
                  )}
                  {newRow?.IncomingCallEntries?.sambandh_notes && (
                    <p className="m-0" style={{ color: "red" }}>
                      Incoming Sambandh :-{" "}
                      <span className="textDark">
                        {newRow?.IncomingCallEntries?.sambandh_notes}
                      </span>
                    </p>
                  )}
                </div>
              ) : null}
              {/* incoming notes end */}
              {/* share notes show  */}
              <p className="m-0">
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "red",
                  }}
                >
                  Share notes :{" "}
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                  }}
                >
                  {newRow?.STPNOTEINUSER?.share_staff_comment}

                  <div>
                    <span>
                      [Time:{" "}
                      {newRow?.STPNOTEINUSER?.share_staff_comment_date_time
                        ? moment(
                            newRow?.STPNOTEINUSER?.share_staff_comment_date_time
                          ).format("DD-MM-YY, h:mm a")
                        : "No Date and Time"}
                      ] [Name:{" "}
                      {newRow?.STPNOTEINUSER?.ShareCardNoteByStaff?.name
                        ?.english || "No Name"}
                      ]
                    </span>
                  </div>
                </span>
              </p>
              {/* {newRow?.MTaskAssignByStaff?.name?.english &&
                <Typography variant="subtitle2" color={"#000"}>
                  <span style={{ textTransform: "capitalize", fontWeight: 600, color: "#FF3030" }}>Assigned By :-&nbsp;
                    <span style={{ color: "#3366FF", fontWeight: 600 }}>{newRow?.MTaskAssignByStaff?.name?.english} &nbsp;</span>
                    <span style={{ textTransform: "capitalize", fontWeight: 600, color: "#FF3030" }}>To :-&nbsp;
                      <span style={{ color: "#3366FF", fontWeight: 600 }}>{newRow?.MTaskAssignOfStaff?.name?.english} &nbsp;</span>
                      [{`${moment(newRow?.master_task_assign_of_date).format('DD-MM-YY, h:mm a')}`}]
                    </span>
                  </span>
                </Typography>
              } */}
              {/* self assign */}
              {/* {Array.isArray(newRow?.self_task_detail) ?
                newRow?.self_task_detail?.map((el) => {
                  return (
                    <Typography variant="subtitle2" color={"red"}>Self Assign : <span style={{ color: "#000" }}>{el?.task_create_by_name || ""}</span>[ {`${moment(el?.task_created_at).format('Do MMM YY, h:mm a')}`}]</Typography>
                  )
                })
                :
                []
              } */}
              {/*  task assign start*/}
              {Array.isArray(newRow?.self_task_detail)
                ? newRow?.self_task_detail?.map((el) => {
                    let assigned = el?.task_type?.split("_")[0];
                    return (
                      <Typography variant="subtitle2" color={"red"}>
                        <span>
                          {" "}
                          {assigned &&
                            (assigned === "self"
                              ? "Self Assign"
                              : assigned === "master"
                              ? "Assigned by :"
                              : "Assigned To :")}{" "}
                        </span>
                        <span style={{ color: "blue" }}>
                          {assigned && el?.task_create_by_name}{" "}
                        </span>
                        <span>
                          {assigned &&
                            (assigned === "self"
                              ? ""
                              : assigned === "master"
                              ? " To :-"
                              : " by :-")}{" "}
                        </span>
                        <span style={{ color: "blue" }}>
                          {" "}
                          {assigned && assigned === "self"
                            ? ""
                            : el?.task_create_of_name === null ||
                              el?.task_create_of_name === undefined ||
                              Array.isArray(el?.task_create_of_name)
                            ? (el?.task_create_of_name === null ||
                                el?.task_create_of_name === undefined) &&
                              el?.task_create_by_name
                            : el?.task_create_of_name}{" "}
                        </span>
                        <span>
                          {assigned &&
                            moment(el?.task_created_at).format(
                              "Do MMM YY, h:mm a"
                            )}
                        </span>
                      </Typography>
                    );
                  })
                : []}
              {/*  task assign end*/}
              {newRow?.last_active ? (
                <Stack direction="row">
                  {" "}
                  <Typography
                    variant="caption"
                    noWrap
                    style={styles.marginZero}
                  >
                    <b> Last Active : </b>&nbsp;
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={styles.smallNormalText}
                  >
                    {`${moment(newRow?.last_active).format(
                      "Do MMM YY, h:mm a"
                    )}`}
                  </Typography>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </div>
        </TableCell>
        <TableCell align="left">
          <Stack>
            <Typography variant="subtitle2" noWrap>
              {mobile && (
                <Iconify
                  style={{ verticalAlign: "sub" }}
                  icon="material-symbols:content-copy-outline"
                  width="18"
                  height="18"
                  onClick={() => {
                    handleCopymobile();
                  }}
                />
              )}
              {maskNumber(mobile)}&nbsp;
              {newRow?.registration_form_status ? (
                <Iconify
                  style={{ verticalAlign: "sub" }}
                  icon="fluent-emoji-flat:green-circle"
                  width="13"
                  height="13"
                />
              ) : (
                <Iconify
                  style={{ verticalAlign: "sub" }}
                  icon="fluent-emoji-flat:red-circle"
                  width="13"
                  height="13"
                />
              )}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {newRow?.second_formMobile && (
                <Iconify
                  style={{ verticalAlign: "sub" }}
                  icon="material-symbols:content-copy-outline"
                  width="18"
                  height="18"
                  onClick={() => {
                    enqueueSnackbar("Copied!");
                    copy(maskNumber(newRow?.second_formMobile));
                  }}
                />
              )}
              {maskNumber(newRow?.second_formMobile)}
            </Typography>
            {otherNumber &&
            (newRow?.fifth_formFatherContactNumberMobile ||
              newRow?.fifth_formMotherContactNumberMobile) ? (
              <Typography
                variant="subtitle2"
                onClick={() => {
                  setSetOtherNumber(false);
                }}
                noWrap
                style={styles.smallDangerText}
              >
                Other Numbers.
              </Typography>
            ) : (
              <>
                <Typography variant="subtitle2" noWrap>
                  {newRow?.fifth_formFatherContactNumberMobile && (
                    <Iconify
                      style={{ verticalAlign: "sub" }}
                      icon="material-symbols:content-copy-outline"
                      width="18"
                      height="18"
                      onClick={() => {
                        enqueueSnackbar("Copied!");
                        copy(
                          maskNumber(
                            newRow?.fifth_formFatherContactNumberMobile
                          )
                        );
                      }}
                    />
                  )}
                  {maskNumber(newRow?.fifth_formFatherContactNumberMobile)}
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  {newRow?.fifth_formMotherContactNumberMobile && (
                    <Iconify
                      style={{ verticalAlign: "sub" }}
                      icon="material-symbols:content-copy-outline"
                      width="18"
                      height="18"
                      onClick={() => {
                        enqueueSnackbar("Copied!");
                        copy(
                          maskNumber(
                            newRow?.fifth_formMotherContactNumberMobile
                          )
                        );
                      }}
                    />
                  )}
                  {maskNumber(newRow?.fifth_formMotherContactNumberMobile)}
                </Typography>
              </>
            )}
            <Typography
              variant="subtitle2"
              color={first_formGenderEnglish === "Male" ? "#0096FF" : "#EE4B2B"}
            >
              {`${first_formGenderEnglish ? first_formGenderEnglish : ""}   ${
                parseInt(newRow?._age)
                  ? " (" + parseInt(newRow?._age) + " Yrs)"
                  : ""
              }`}
            </Typography>
            {newRow.fourth_formEmployedEnglish && (
              <Typography
                variant="subtitle2"
                noWrap
                style={styles.smallDangerText}
              >
                {`${newRow.fourth_formEmployedEnglish}`}
              </Typography>
            )}
            {newRow.second_formCityEnglish && (
              <Typography
                variant="subtitle2"
                noWrap
                style={styles.smallNormalText}
              >
                {`${newRow.second_formCityEnglish} / ${newRow.second_formStateEnglish}`}
              </Typography>
            )}
            <Typography variant="subtitle2" noWrap style={styles.marginZero}>
              {type.type === "deletedUsers" ? (
                <div>
                  {" "}
                  <b> Suspended By :</b>
                  <p className="m-0">
                    {" "}
                    {newRow?.userDeletedByStaff?.name?.english}
                  </p>{" "}
                  <p
                    className="m-0 fs-12"
                    style={{ color: "red", fontWeight: "500" }}
                  >{`${moment(newRow?.deleted_at).format(
                    "Do MMM YY, h:mm:ss a"
                  )}`}</p>
                </div>
              ) : (
                ""
              )}
            </Typography>
            <Typography
              variant="subtitle2"
              color={"#3366FF"}
              style={{ textDecoration: "underline" }}
              onClick={() => {
                enqueueSnackbar("Copied!");
                // copy(`${constant.appBaseUrl}/app/users/${id}`);
                const formattedMessage = `
*${nameEnglish} (${
                  parseInt(newRow?._age)
                    ? " (" + parseInt(newRow?._age) + " Yrs)"
                    : ""
                })*
(Profile ID : ${id}) is Registered on Our Jain shadi milan App

*Click here For View Our Full Profile*👇
${constant.DemoImageURl}${id}

*Click here to download/Update Jain Shadi Milan App* 👇
https://play.google.com/store/apps/details?id=com.jsm.digital

- *जैन शादी मिलन*
(जैनों का सबसे विश्वसनीय एप्प)
👉 7610447511 📢
✨✨✨✨✨✨✨✨

🔊 Link 🔗 पर क्लिक नहीं होता है तो Reply में "jaijinendra" लिखकर भेजें.
`;

                copy(formattedMessage);

                //     copy(`${nameEnglish}  (${
                //       parseInt(newRow?._age)
                //         ? " (" + parseInt(newRow?._age) + " Yrs"
                //         : ""
                //     })\n(Profile ID : ${id}) is Registered on Our Jain shadi milan App
                //   \nClick here For View Our Full Profile 👇\n${
                //     constant.appBaseUrl
                //   }/app/users/${id}
                //   \nClick here to download/Update Jain Shadi Milan App 👇\nhttps://play.google.com/store/apps/details?id=com.jsm.digital
                //  \nजैन शादी मिलन \n(जैनों का सबसे विश्वसनीय एप्प)
                //  \n 👉 7610447511 📢
                //  \n 👉 7610447512 📢
                //   \n✨✨✨✨✨✨✨✨
                //  \n 🔊 Link 🔗 पर क्लिक नही होता है तो Reply में "jaijinendra" लिखकर भेजे।`);

                //   copy(`${p?.name ? constant.appBaseUrl + "/" + p?.folder + "/" + p?.name : ""}\n${nameEnglish}  (${parseInt(newRow?._age) ? " (" + parseInt(newRow?._age) + " Yrs" : ""})\n(Profile ID : ${id})
                //   \nis Registered on Our Jain shadi milan App
                //   \nClick here For View Our Full Profile 👇\n${constant.appBaseUrl}/app/users/${id}
                //   \nClick here to download/Update Jain Shadi Milan App 👇\nhttps://play.google.com/store/apps/details?id=com.jsm.digital
                //  \nजैन शादी मिलन \n(जैनों का सबसे विश्वसनीय एप्प)
                //  \n 👉 7610447511 📢
                //  \n 👉 7610447512 📢
                //   \n✨✨✨✨✨✨✨✨
                //  \n 🔊 Link 🔗 पर क्लिक नही होता है तो Reply में "jaijinendra" लिखकर भेजे।`)
              }}
            >
              {" "}
              <Iconify
                icon="material-symbols:content-copy-outline"
                style={{ verticalAlign: "sub" }}
                width="18"
                height="18"
              />
              <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                {" "}
                Copy User Link{" "}
              </span>
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Grid style={{ margin: "10px 9px" }}>
            <Stack spacing={2} direction="row" className="badgeSpan">
              {newRow?.first_formGenderEnglish &&
              newRow?.second_formStateEnglish &&
              newRow?.third_formSectEnglish &&
              newRow?.fourth_formEmployedEnglish ? (
                <Badge className="rightCheck">
                  <Iconify icon="eva:checkmark-outline" />
                </Badge>
              ) : (
                <Badge badgeContent={"R"} color="primary"></Badge>
              )}
              {newRow?.profiles?.find((val) => val?.type === "id") ? (
                <Badge className="rightCheck">
                  <Iconify icon="eva:checkmark-outline" />
                </Badge>
              ) : (
                <Badge badgeContent={"ID"} color="secondary"></Badge>
              )}
              {newRow?.mandir_detail_verified_at === 1 ? (
                <Badge className="rightCheck">
                  <Iconify icon="eva:checkmark-outline" />
                </Badge>
              ) : (
                <Badge badgeContent={"M"} color="info"></Badge>
              )}
              {newRow?.profiles?.find((val) => val?.type === "birth") ? (
                <Badge className="rightCheck">
                  <Iconify icon="eva:checkmark-outline" />
                </Badge>
              ) : (
                <Badge badgeContent={"B"} color="error"></Badge>
              )}
              {newRow?.profiles?.find((val) => val?.type === "profile") ? (
                <Badge className="rightCheck">
                  <Iconify icon="eva:checkmark-outline" />
                </Badge>
              ) : (
                <Badge badgeContent={"P"} color="warning"></Badge>
              )}
            </Stack>
          </Grid>
          <Grid style={{ margin: "0px 0px" }}>
            {newRow?.created_at && (
              <Stack spacing={1} direction="row" style={{ marginTop: 15 }}>
                <Typography variant="subtitle2" noWrap style={styles.smallText}>
                  Cre.
                </Typography>
                <Typography
                  variant="subtitle2"
                  noWrap
                  color={"red"}
                  style={styles.smallText}
                >
                  {`${moment(newRow?.created_at).format(
                    "Do MMM YY, h:mm:ss a"
                  )}`}
                </Typography>
              </Stack>
            )}
            {newRow?.payment_verified_date && (
              <Stack spacing={1} direction="row">
                <Typography variant="subtitle2" noWrap style={styles.smallText}>
                  P. Ver.
                </Typography>
                <Typography
                  variant="subtitle2"
                  noWrap
                  color={"red"}
                  style={styles.smallText}
                >
                  {`${moment(newRow?.payment_verified_date).format(
                    "Do MMM YY, h:mm:ss a"
                  )}`}
                </Typography>
              </Stack>
            )}
            {newRow?.payment_expired_date && (
              <Stack spacing={1} direction="row">
                <Typography
                  variant="subtitle2"
                  noWrap
                  style={styles.smallText}
                  color={
                    new Date(newRow?.payment_expired_date) <= new Date()
                      ? "black"
                      : "#50C878"
                  }
                >
                  P. Exp.
                </Typography>
                <Typography
                  variant="subtitle2"
                  noWrap
                  style={styles.smallText}
                  color={
                    new Date(newRow?.payment_expired_date) <= new Date()
                      ? "black"
                      : "#50C878"
                  }
                >
                  {`${moment(newRow?.payment_expired_date).format(
                    "Do MMM YY, h:mm:ss a"
                  )}`}
                </Typography>
              </Stack>
            )}
            {newRow?.packageId && newRow.payment_verified_at && (
              <>
                <Stack spacing={1} direction="row" style={{ marginTop: 1 }}>
                  <Typography
                    variant="subtitle2"
                    noWrap
                    color="#FFCC00"
                    style={styles.smallText}
                  >
                    Plan:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    noWrap
                    color="#FFCC00"
                    style={styles.smallText}
                  >
                    {/* {(newRow?.packageId.package_id)} */}
                    {
                      payPackge.find(
                        (val) => val.id === newRow?.packageId.package_id
                      )?.name?.english
                    }
                  </Typography>
                </Stack>
              </>
            )}
          </Grid>
        </TableCell>
        <TableCell align="left" className="checkBox">
          <Typography variant="subtitle2" noWrap style={styles.smallNormalText}>
            <Switch
              disabled
              checked={newRow.second_formMobile ? true : false}
            />
            Live
          </Typography>

          <>
            <Typography
              variant="subtitle2"
              noWrap
              style={styles.smallNormalText}
            >
              {contxt?.user?.sections?.onlineUsers?.view ? (
                <Switch
                  checked={newRow.live ? true : false}
                  onChange={handleSwitchChange}
                />
              ) : (
                <Switch disabled={true} checked={newRow.live ? true : false} />
              )}
              View
            </Typography>

            {/* Popup Model */}
            <Dialog
              open={openViewPopup}
              onClose={() => setOpenViewPopup(false)}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogContent sx={{ padding: "20px" }} className="displayBtwPro">
                <TextField
                  label="Enter the value"
                  variant="outlined"
                  placeholder="Enter the value"
                  type="number"
                  value={viewHour}
                  onChange={(e) => setViewHour(e.target.value)}
                />
                <Button variant="contained" onClick={handleUpdateViewHour}>
                  Update
                </Button>
              </DialogContent>
            </Dialog>
          </>

          {/* call history  */}
          <Dialog
            open={historyPopupOpen}
            onClose={handleCloseHistory}
            fullWidth
            maxWidth={"sm"}
          >
            <DialogContent sx={{ padding: "20px" }} className="displayBtwPro">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ color: "#5858d9" }}>#</th>
                    <th style={{ color: "#5858d9" }}>Staff Name</th>
                    <th style={{ color: "#5858d9" }}>Call Time</th>
                    <th style={{ color: "#5858d9" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {newRow?.call_historys &&
                    newRow?.call_historys.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.staff_name}</td>

                        <td>
                          {" "}
                          {`${moment(item?.call_date_time).format(
                            "DD-MM-YY, hh:mm"
                          )}`}
                        </td>
                        <td>{item?.call_status ? "On" : "Off"}</td>
                      </tr>
                    ))}
                  {/* Add more rows if needed */}
                </tbody>
              </table>
            </DialogContent>
          </Dialog>

          {/* np call history*/}
          <Dialog
            open={nphistoryPopupOpen}
            onClose={handleNpCloseHistory}
            fullWidth
            maxWidth={"sm"}
          >
            <DialogContent sx={{ padding: "20px" }} className="displayBtwPro">
              <table className="table">
                <thead>
                  <tr style={{ color: "#5858d9" }}>
                    <th style={{ color: "#5858d9" }}>#</th>
                    <th style={{ color: "#5858d9" }}>Staff Name</th>
                    <th style={{ color: "#5858d9" }}>Call Time</th>
                    <th style={{ color: "#5858d9" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {newRow?.not_pick_call_history &&
                    newRow?.not_pick_call_history.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.staff_name}</td>

                        <td>
                          {" "}
                          {`${moment(item.call_date_time).format(
                            "DD-MM-YY, hh:mm"
                          )}`}
                        </td>
                        <td>{item?.call_status ? "On" : "Off"}</td>
                      </tr>
                    ))}
                  {/* Add more rows if needed */}
                </tbody>
              </table>
            </DialogContent>
          </Dialog>

          {/* nc call history  */}
          <Dialog
            open={nchistoryPopupOpen}
            onClose={handleNcCloseHistory}
            fullWidth
            maxWidth={"sm"}
          >
            <DialogContent sx={{ padding: "20px" }} className="displayBtwPro">
              <table className="table">
                <thead>
                  <tr style={{ color: "#5858d9" }}>
                    <th style={{ color: "#5858d9" }}>#</th>
                    <th style={{ color: "#5858d9" }}>Staff Name</th>
                    <th style={{ color: "#5858d9" }}>Call Time</th>
                    <th style={{ color: "#5858d9" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {newRow?.not_received_call_history &&
                    newRow?.not_received_call_history.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.staff_name}</td>

                        <td>
                          {" "}
                          {`${moment(item?.call_date_time).format(
                            "DD-MM-YY, hh:mm"
                          )}`}
                        </td>
                        <td>{item?.call_status ? "On" : "Off"}</td>
                      </tr>
                    ))}
                  {/* Add more rows if needed */}
                </tbody>
              </table>
            </DialogContent>
          </Dialog>

          {newRow?.live_date && newRow.live === 1 && (
            <Stack spacing={1} direction="row" style={{}}>
              <Typography
                variant="subtitle2"
                noWrap
                color={"red"}
                style={styles.smallNormalText}
              >
                {moment(newRow?.live_date).format("DD/MM/YY , hh:mm")}(
                {newRow?.liveByStaff?.name?.english
                  ? newRow?.liveByStaff?.name?.english
                  : ""}
                )
              </Typography>
            </Stack>
          )}

          <Typography variant="subtitle2" noWrap style={styles.smallNormalText}>
            <Switch
              disabled
              checked={newRow.payment_verified_at ? true : false}
            />
            Payment
          </Typography>
          <Typography variant="subtitle2" noWrap style={styles.smallNormalText}>
            <Switch
              disabled
              checked={newRow.special_expiry_status ? true : false}
            />
            Special Payment
          </Typography>
          <Typography variant="subtitle2" noWrap style={styles.smallNormalText}>
            {contxt?.user?.sections?.onlineUsers?.approval ? (
              <Switch
                checked={newRow.verified_at ? true : false}
                onChange={async () => {
                  updateuser({
                    verified_at_date: new Date(),
                    verified_at: newRow.verified_at ? 0 : 1,
                    verified_by_staff_id: contxt?.user?.id,
                  });
                  if (!newRow.verified_at) await updateApprovalPopup(newRow);
                  setNewRow({
                    ...newRow,
                    verified_at: newRow.verified_at ? 0 : 1,
                    verified_at_date: new Date(),
                    verified_by_staff_id: contxt?.user?.id,
                    verifiedByStaff: {
                      name: {
                        english: contxt?.user?.nameEnglish,
                      },
                    },
                  });
                }}
              />
            ) : (
              <Switch checked={newRow.verified_at ? true : false} disabled />
            )}
            Approval
          </Typography>
          {newRow?.verified_at_date && newRow.verified_at === 1 && (
            <Stack spacing={1} direction="row" style={{}}>
              <Typography
                variant="subtitle2"
                noWrap
                color={"red"}
                style={styles.smallNormalText}
              >
                {moment(newRow?.verified_at_date).format("MMM D, YY hh:mm:A")}(
                {newRow?.verifiedByStaff?.name?.english
                  ? newRow?.verifiedByStaff?.name?.english
                  : ""}
                )
              </Typography>
            </Stack>
          )}
          <Typography variant="subtitle2" noWrap style={styles.smallNormalText}>
            {contxt?.user?.sections?.onlineUsers?.testUser ? (
              <Switch
                checked={newRow.test_user ? true : false}
                onChange={() => {
                  updateuser({
                    test_user: newRow.test_user ? 0 : 1,
                    test_user_date_time: new Date(),
                    test_user_staff_id: contxt?.user?.id,
                    hide: newRow.test_user ? 0 : 1,
                  });
                  setNewRow({
                    ...newRow,
                    test_user: newRow.test_user ? 0 : 1,
                    test_user_date_time: new Date(),
                    test_user_staff_id: contxt?.user?.id,
                    testUserByStaff: {
                      name: {
                        english: contxt?.user?.nameEnglish,
                      },
                    },
                  });
                }}
              />
            ) : (
              <Switch
                disabled={true}
                checked={newRow.test_user ? true : false}
              />
            )}
            Test User
          </Typography>
          {newRow?.test_user_date_time || newRow.test_user_date_time === 1 ? (
            <Stack Stack spacing={1} direction="row" style={{}}>
              <Typography
                variant="subtitle2"
                noWrap
                color={"red"}
                style={styles.smallNormalText}
              >
                {newRow?.test_user_date_time
                  ? moment(newRow?.test_user_date_time).format(
                      "DD/MM/YY , hh:mm"
                    )
                  : null}
                (
                {newRow?.testUserByStaff?.name?.english
                  ? newRow?.testUserByStaff?.name?.english
                  : ""}
                )
              </Typography>
            </Stack>
          ) : (
            ""
          )}
          <Typography variant="subtitle2" noWrap style={styles.smallNormalText}>
            {contxt?.user?.sections?.onlineUsers?.hide ? (
              <Switch
                checked={newRow.hide === 1 ? true : false}
                onChange={() => {
                  updateuser({
                    hide: newRow.hide === 1 ? 0 : 1,
                    hide_date_time: new Date(),
                    hide_staff_id: contxt?.user?.id,
                  });
                  setNewRow({
                    ...newRow,
                    hide: newRow.hide === 1 ? 0 : 1,
                    hide_date_time: new Date(),
                    hideByStaff: {
                      name: {
                        english: contxt?.user?.nameEnglish,
                      },
                    },
                  });
                }}
              />
            ) : (
              <Switch disabled={true} checked={newRow.hide ? true : false} />
            )}
            Hide
          </Typography>

          {newRow?.hide_date_time || newRow.hide_date_time === 1 ? (
            <Stack Stack spacing={1} direction="row" style={{}}>
              <Typography
                variant="subtitle2"
                noWrap
                color={"red"}
                style={styles.smallNormalText}
              >
                {newRow?.hide_date_time
                  ? moment(newRow?.hide_date_time).format("DD/MM/YY , hh:mm")
                  : null}
                (
                {newRow?.hideByStaff?.name?.english
                  ? newRow?.hideByStaff?.name?.english
                  : ""}
                )
              </Typography>
            </Stack>
          ) : null}
        </TableCell>
        <TableCell align-item="left" className="checkBox">
          <Typography variant="subtitle2" noWrap style={styles.smallNormalText}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ minWidth: "37px" }}
              onClick={handleOpenHistory}
            >
              {newRow?.total_count_calls}
            </Button>
            <Switch
              checked={newRow.for_call ? true : false}
              disabled={
                newRow.payment_verified_at
                  ? newRow.payment_verified_at
                  : contxt?.user?.roles === "admin"
                  ? false
                  : newRow.for_call
                  ? true
                  : false
              }
              onChange={() => {
                const val = true;

                const call_historys = [
                  {
                    call_date_time: new Date(),
                    call_status: val ? 0 : 1,
                    staff_id: contxt?.user?.id,
                    staff_name: contxt?.user?.nameEnglish,
                  },
                ];

                // Update user with call_historys
                updateuser({
                  for_call: newRow.for_call ? 0 : 1,
                  call_time: new Date(),
                  call_id: contxt?.user?.id,
                  call_historys: call_historys,
                });

                // Update newRow state
                setNewRow({
                  ...newRow,
                  for_call: newRow.for_call ? 0 : 1,
                  call_time: new Date(),
                  call_id: contxt?.user?.id,
                  caller: {
                    name: {
                      english: contxt?.user?.nameEnglish,
                    },
                  },
                  total_count_calls: !newRow.for_call
                    ? newRow?.total_count_calls + 1
                    : newRow?.total_count_calls,
                });
              }}
            />{" "}
            Call
          </Typography>
          {newRow?.call_time && newRow?.for_call === 1 && (
            <Stack spacing={1} direction="row" style={{}}>
              <Typography
                variant="subtitle2"
                noWrap
                color={"red"}
                style={styles.smallNormalText}
              >
                {moment(newRow?.call_time).format("MMM D, YY hh:mm:A")}(
                {newRow?.caller?.name?.english})
              </Typography>
            </Stack>
          )}
          {/* {newRow?.caller?.name?.english &&
            <Typography variant="subtitle2" noWrap color={"red"} style={styles.smallText}>

            </Typography>} */}
          <Typography
            className="mt-2"
            variant="subtitle2"
            noWrap
            style={styles.smallNormalText}
          >
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ minWidth: "37px" }}
              onClick={handleNpOpenHistory} // Corrected inline style
            >
              {newRow?.total_count_not_pick}
            </Button>
            <Switch
              checked={newRow.not_connected ? true : false}
              disabled={
                newRow.payment_verified_at
                  ? newRow.payment_verified_at
                  : contxt?.user?.roles === "admin"
                  ? false
                  : newRow.for_call
                  ? true
                  : false
              }
              onChange={() => {
                const val = true;

                const not_pick_call_history = [
                  {
                    call_date_time: new Date(),
                    call_status: val ? 0 : 1,
                    staff_id: contxt?.user?.id,
                    staff_name: contxt?.user?.nameEnglish,
                  },
                ];

                updateuser({
                  not_connected: newRow.not_connected ? 0 : 1,
                  not_connect_time: new Date(),
                  call_id: contxt?.user?.id,
                  not_pick_call_history: not_pick_call_history,
                });
                setNewRow({
                  ...newRow,
                  not_connected: newRow.not_connected ? 0 : 1,
                  not_connect_time: new Date(),
                  call_id: contxt?.user?.id,
                  caller: {
                    name: {
                      english: contxt?.user?.nameEnglish,
                    },
                  },
                  total_count_not_pick: !newRow.not_connected
                    ? newRow?.total_count_not_pick + 1
                    : newRow?.total_count_not_pick,
                });
              }}
            />
            {newRow?.not_connected === 1 ? "Connected" : "Not-Connect"}
          </Typography>
          {newRow?.not_connect_time && newRow?.not_connected === 1 && (
            <Stack spacing={1} direction="row">
              <Typography
                variant="subtitle2"
                noWrap
                color={"red"}
                style={styles.smallNormalText}
              >
                {moment(newRow?.not_connect_time).format("MMM D, YY hh:mm:A")}(
                {newRow?.caller?.name?.english})
              </Typography>
            </Stack>
          )}
          <Typography
            className="mt-2"
            variant="subtitle2"
            noWrap
            style={styles.smallNormalText}
          >
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ minWidth: "37px" }}
              onClick={handleNcOpenHistory}
            >
              {newRow?.total_count_not_received}
            </Button>
            <Switch
              checked={newRow.not_recieved ? true : false}
              disabled={
                newRow.payment_verified_at
                  ? newRow.payment_verified_at
                  : contxt?.user?.roles === "admin"
                  ? false
                  : newRow.for_call
                  ? true
                  : false
              }
              onChange={() => {
                const val = true;

                const not_received_call_history = [
                  {
                    call_date_time: new Date(),
                    call_status: val ? 0 : 1,
                    staff_id: contxt?.user?.id,
                    staff_name: contxt?.user?.nameEnglish,
                  },
                ];
                updateuser({
                  not_recieved: newRow.not_recieved ? 0 : 1,
                  not_received_time: new Date(),
                  call_id: contxt?.user?.id,
                  not_received_call_history: not_received_call_history,
                });
                setNewRow({
                  ...newRow,
                  not_recieved: newRow.not_recieved ? 0 : 1,
                  not_received_time: new Date(),
                  call_id: contxt?.user?.id,
                  caller: {
                    name: {
                      english: contxt?.user?.nameEnglish,
                    },
                  },
                  total_count_not_received: !newRow.not_recieved
                    ? newRow?.total_count_not_received + 1
                    : newRow?.total_count_not_received,
                });
              }}
            />
            {newRow?.not_recieved === 1 ? "Received" : "Not-Received"}{" "}
          </Typography>
          {newRow?.not_received_time && newRow?.not_recieved === 1 && (
            <Stack spacing={1} direction="row">
              <Typography
                variant="subtitle2"
                noWrap
                color={"red"}
                style={styles.smallNormalText}
              >
                {moment(newRow?.not_received_time).format("MMM D, YY hh:mm:A")}{" "}
                ({newRow?.caller?.name?.english})
              </Typography>
            </Stack>
          )}
          <Typography variant="subtitle2" noWrap style={styles.smallNormalText}>
            <Switch
              checked={newRow.public_view_for_web ? true : false}
              disabled={
                newRow.payment_verified_at
                  ? newRow.payment_verified_at
                  : contxt?.user?.roles === "admin"
                  ? false
                  : newRow.for_call
                  ? true
                  : false
              }
              onChange={() => {
                updateuser({
                  public_view_for_web: newRow.public_view_for_web ? 0 : 1,
                  public_view_for_web_date_time: new Date(),
                });
                setNewRow({
                  ...newRow,
                  public_view_for_web: newRow.public_view_for_web ? 0 : 1,
                  not_received_time: new Date(),
                });
              }}
            />
            {newRow?.public_view_for_web === 1 ? "Public" : "Private"}{" "}
          </Typography>
          {newRow?.public_view_for_web_date_time &&
            newRow?.public_view_for_web === 1 && (
              <Stack spacing={1} direction="row">
                <Typography
                  variant="subtitle2"
                  noWrap
                  color={"red"}
                  style={styles.smallNormalText}
                >
                  {moment(newRow?.public_view_for_web_date_time).format(
                    "MMM D, YY hh:mm:A"
                  )}
                </Typography>
              </Stack>
            )}
        </TableCell>
        {/* <TableCell align="left">
          <div className='ratingCell'>
            {ratingArray?.map((i) =>
              <Button
                disabled={newRow.payment_verified_at}
                key={i?.key}
                color='error'
                variant={newRow.rating === i?.key ? "contained" : "outlined"}
                size='small'
                onClick={() => { setChangeRating(true); setRatingID(i?.key) }}>
                {i?.title}
              </Button>
            )}
          </div>
        </TableCell> */}
        <TableCell align="left">
          <div className="ratingCell">
            <Button
              disabled={newRow.payment_verified_at}
              color="error"
              variant={newRow.rating === 5 ? "contained" : "outlined"}
              size="small"
              onClick={() => {
                setChangeRating(true);
                setRatingID(5);
              }}
            >
              A
            </Button>

            <Button
              disabled={newRow.payment_verified_at}
              color="error"
              variant={newRow.rating === 4 ? "contained" : "outlined"}
              size="small"
              onClick={() => {
                setChangeRating(true);
                setRatingID(4);
              }}
            >
              B
            </Button>

            <Button
              disabled={newRow.payment_verified_at}
              color="error"
              variant={
                newRow.rating === 2 || newRow.rating === 3
                  ? "contained"
                  : "outlined"
              }
              size="small"
              onClick={() => {
                setChangeRating(true);
                setRatingID(3 || 2);
              }}
            >
              C
            </Button>

            <Button
              disabled={newRow.payment_verified_at}
              color="error"
              variant={newRow.rating === 1 ? "contained" : "outlined"}
              size="small"
              onClick={() => {
                setChangeRating(true);
                setRatingID(1);
              }}
            >
              D
            </Button>
            <Button
              disabled={newRow.payment_verified_at}
              color="error"
              variant={newRow.rating === 7 ? "contained" : "outlined"}
              size="small"
              onClick={() => {
                setChangeRating(true);
                setRatingID(7);
              }}
            >
              S
            </Button>
            <Button
              disabled={newRow.payment_verified_at}
              color="error"
              variant={newRow.rating === 8 ? "contained" : "outlined"}
              size="small"
              onClick={() => {
                setChangeRating(true);
                setRatingID(8);
              }}
            >
              L
            </Button>
          </div>
        </TableCell>
        <TableCell align="left">
          <Grid item lg={12} xs={6} md={4} sm={6}>
            <TextField
              fullWidth
              select
              label="Mobile no."
              value={newRow?.calling_number?.trim()}
              disabled={newRow.payment_verified_at}
              onChange={(event) => {
                setNewRow({ ...newRow, calling_number: event.target.value });
                if ("Remove Number" === event.target.value)
                  updateuser({ calling_number: " " });
                else
                  updateuser({
                    calling_number: event.target.value,
                    call_id: contxt?.user?.id,
                  });
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {},
                },
              }}
              sx={{
                textTransform: "capitalize",
                maxWidth: { sm: 160 },
              }}
            >
              {staffNumber
                .concat({ label: "Remove Number", value: "Remove Number" })
                .map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </TableCell>

        <TableCell align="left">
          <Stack spacing={2}>
            <div style={{ paddingRight: 10 }}>
              <div
                className="noteBtn"
                onClick={(event) => {
                  event.stopPropagation();
                  setNoteModal(true);
                }}
              >
                <Badge badgeContent={"Note"} color="secondary"></Badge>
              </div>
              <div
                className="noteBtn "
                style={{ marginTop: 5 }}
                onClick={(event) => {
                  event.stopPropagation();
                  localStorage.setItem("show", show);
                  localStorage.setItem("show1", show1);
                }}
              >
                <RouterLink
                  to={`/${
                    type.type === "deletedUsers" ? "deleteduser" : "onlineuser"
                  }/edit/${id}`}
                  target="_blank"
                >
                  <Badge badgeContent={"Open"} color="primary"></Badge>
                </RouterLink>
              </div>
              {newRow.shadi_done ? (
                <div
                  className="noteBtn"
                  onClick={(event) => {
                    event.stopPropagation();
                    setShadiDoneModel(true);
                  }}
                >
                  <Badge
                    sx={{ mt: 1, whiteSpace: "noWrap" }}
                    badgeContent={"Remove"}
                    color="primary"
                  ></Badge>
                </div>
              ) : (
                <></>
              )}

              {Array.isArray(contxt?.user?.assignRole?.section) &&
              contxt?.user?.assignRole?.section.some(
                (section) => section.restore
              ) ? (
                // type.type === "deletedUsers" || contxt?.user?.sections?.deletedUsers?.restore
                <div
                  className="noteBtn"
                  onClick={() => {
                    handleOpenConfirmR();
                  }}
                >
                  <Badge
                    sx={{ mt: 1, whiteSpace: "noWrap" }}
                    badgeContent={"Restore"}
                    color="success"
                  ></Badge>
                </div>
              ) : null}
            </div>
          </Stack>
        </TableCell>
      </TableRow>
      {newRow?.ShadiDoneData && (
        <TableRow>
          <TableCell colSpan="12">
            <div>
              <Typography variant="caption">
                <span style={{ color: "#ff3030" }}>
                  <b>Shadi Done requested by : </b>
                </span>
                &nbsp;{" "}
                <span style={{ color: "#386afe" }}>
                  {newRow?.ShadiDoneData?.User?.nameEnglish}
                </span>
                ,&nbsp;&nbsp;
                <Iconify
                  style={{ verticalAlign: "sub" }}
                  icon="material-symbols:content-copy-outline"
                  width="18"
                  height="18"
                  onClick={() => {
                    handleCopyShadiDoneUserId();
                  }}
                />
                <span style={{ color: "#386afe" }}>
                  {newRow?.ShadiDoneData?.User?.id}
                </span>
                , &nbsp;&nbsp;
                <span style={{ color: "#386afe" }}>
                  {newRow?.ShadiDoneData?.User?.nameEnglish}
                </span>
              </Typography>
            </div>
          </TableCell>
        </TableRow>
      )}
      {noteModalValue && (
        <TableRow>
          <TableCell colSpan="12">
            <Typography variant="caption">
              <b>Note : </b>
              {noteModalValue}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {newRow?.staff_id && (
        <TableRow>
          <TableCell>
            <Typography variant="caption" noWrap style={styles.marginZero}>
              <b>Staff Name : </b>
              {newRow?.staff?.name?.english}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption" noWrap style={styles.marginZero}>
              <b>Offline Rating : </b>
              {newRow?.offline_rating}
            </Typography>
          </TableCell>
          <TableCell colSpan="8">
            <Typography variant="caption" noWrap style={styles.marginZero}>
              <b>Offline Note : </b>
              {newRow?.offline_note}{" "}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {newRow?.whatsappResponses?.id && (
        <TableRow>
          <TableCell>
            <Typography variant="caption" noWrap style={styles.marginZero}>
              <b>(W) Staff Name : </b>
              {newRow?.whatsappResponses?.whatsappStaff?.name?.english}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption" noWrap style={styles.marginZero}>
              <b>Offline Rating : </b>
              {newRow?.whatsappResponses?.rating}
            </Typography>
          </TableCell>
          <TableCell colSpan="8">
            <Typography variant="caption" noWrap style={styles.marginZero}>
              <b>Offline Note : </b>
              {newRow?.whatsappResponses?.notes}{" "}
            </Typography>
          </TableCell>
        </TableRow>
      )}

      <ConfirmDialog
        className="deleteModal"
        open={chnageRating}
        onClose={() => {
          setChangeRating(false);
        }}
        title="Change Rating"
        content="Are you sure want to change rating?"
        action={
          <div>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                setChangeRating(false);
              }}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                updateuser({ rating: ratingID });
                setNewRow({ ...newRow, rating: ratingID });
                setChangeRating(false);
              }}
            >
              Change
            </Button>
          </div>
        }
      />

      <ConfirmDialog
        className="deleteModal"
        open={shadiDone}
        onClose={() => {
          setShadiDoneModel(false);
        }}
        title="Restore Shadi Done"
        content="Are you sure want to restore?"
        action={
          <div>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                setShadiDoneModel(false);
              }}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                updateuser({ shadi_done: 0 });
                setNewRow({ ...newRow, shadi_done: 0 });
                setFilter({ shadi_done: true });
                getOnlineUserAnalyticsApi();
                setShadiDoneModel(false);
              }}
            >
              Done
            </Button>
          </div>
        }
      />
      <ConfirmDialog
        className="deleteModal"
        open={openRConfirm}
        onClose={() => setROpenConfirm(false)}
        title="Restore"
        content="Are You Sure To Restore this user?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              restoreuser();
              onDeleteRow(row);
              setROpenConfirm(false);
            }}
          >
            Yes
          </Button>
        }
      />

      {/* add notes */}
      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={noteModal}
        onClose={() => {
          setNoteModal(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setNoteModal(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Add Notes"}
            />

            <div>
              <FormControl
                sx={{ mb: 3 }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Grid container spacing={1}>
                    {note?.map((val, i) => {
                      return (
                        <Grid key={i} item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={val?.status}
                                onChange={(event) => {
                                  var tempNote = [...note];
                                  tempNote[i].status = event.target.checked;
                                  setNote(tempNote);
                                  if (event.target.checked) {
                                    setNoteModalValue(
                                      noteModalValue + " | " + event.target.name
                                    );
                                  } else {
                                    setNoteModalValue(
                                      noteModalValue.replaceAll(
                                        " | " + event.target.name,
                                        " "
                                      )
                                    );
                                  }
                                }}
                                name={val?.name}
                              />
                            }
                            label={val?.name}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormGroup>
              </FormControl>
              <TextField
                multiline
                fullWidth
                label="Note"
                placeholder="Type note here.."
                value={noteModalValue}
                onChange={(e) => {
                  setNoteModalValue(e.target.value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  // loading={isSubmitSuccessful || isSubmitting}
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    updateuser({ note: noteModalValue });
                    setNewRow({ ...newRow, note: noteModalValue });
                    setNoteModal(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />
    </>
  );
}
