import PropTypes from "prop-types";
// @mui
import { alpha, useTheme } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { bgGradient } from "../../../../utils/cssStyles";
import { fShortenNumber } from "../../../../utils/formatNumber";
// components
import Iconify from "../../../../components/iconify";

// ----------------------------------------------------------------------

AnalyticsWidgetSummary.propTypes = {
  sx: PropTypes.object,
  icon: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
};

export default function AnalyticsWidgetSummary({
  title,
  total,
  icon,
  color = "primary",
  py,
  sx,
  ...other
}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        py: py ? py : 5,
        boxShadow: 0,
        textAlign: "center",
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <Iconify
        icon={icon}
        sx={{
          mb: 3,
          p: 2.5,
          width: 64,
          height: 64,
          borderRadius: "50%",
          color: (theme) => theme.palette[color].dark,
          ...bgGradient({
            direction: "135deg",
            startColor: `${alpha(theme.palette[color].dark, 0)} 0%`,
            endColor: `${alpha(theme.palette[color].dark, 0.24)} 100%`,
          }),
        }}
      />

      <Typography variant="h4">{total}</Typography>
      {/* <Typography variant="h4">{fShortenNumber(total)}</Typography> */}

      <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
        {title}
      </Typography>
    </Card>
  );
}
