import { useState, useRef } from "react";

// @mui
import {
  Box,
  Card,
  Dialog,
  CardHeader,
  DialogContent,
  TextField,
  MenuItem,
  Divider,
  Grid,
  Tooltip,
  Button,
  Typography,
  Stack,
  IconButton,
  CardContent,
  CardMedia,
  Autocomplete,
  Checkbox,
  ListItemText,
} from "@mui/material";
// utils
// _mock
import { _socials } from "../../../../_mock/arrays";
// components
import FormProvider, {
  RHFTextField,
  RHFEditor,
  RHFTextArea,
} from "../../../../components/hook-form";
import { DateTimePicker } from "@mui/x-date-pickers";
import Switch from "@mui/material/Switch";
import ConfirmDialog from "../../../../components/confirm-dialog";
import {
  updatePacakge,
  deletePack,
  updatePacakgeStatus,
  updateSliderView,
  updateSliderOrder,
  updateHinduPacakge,
  deleteHinduPack,
  updateSlider_OrderHindu,
  updateOnlyForMasterAdminApi,
} from "src/service";
import constant from "src/Constant";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import Iconify from "../../../../components/iconify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../../../components/snackbar";
import { SketchPicker } from "react-color";
import { useAuthContext } from "src/auth/useAuthContext";
import moment from "moment";
import { TimeOffsetPicker } from "src/components/date-range-picker/DateDropdownPicker";

const gender = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
];

const SliderOrderOption = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
];

const packagesType = [
  { id: 1, name: "Basic" },
  { id: 2, name: "Special" },
  { id: 3, name: "VIP" },
];

const discount = [
  { id: 1, name: "Percent" },
  { id: 2, name: "Flat" },
];

const discountOne = [
  { id: 1, name: "Percent" },
  { id: 2, name: "Flat" },
];
const community = [
  { id: 0, name: "All" },
  { id: 1, name: "Maheswari Jain" },
  { id: 2, name: "Digamber Jain" },
  { id: 3, name: "Shwetamber Jain" },
  { id: 4, name: "Taran tarn" },
  { id: 5, name: "Khandelwal Jain" },
  { id: 6, name: "Agrawal Jain" },
  { id: 7, name: "Gujrati Jain" },
  { id: 8, name: "Marwadi Jain" },
  { id: 9, name: "Baniya Jain" },
  { id: 10, name: "Saraf Jain" },
  { id: 11, name: "Gupta Jain" },
  { id: 12, name: "Other Cast" },
];

const discountTwo = [
  { id: 1, name: "Percent" },
  { id: 2, name: "Flat" },
];

const payValue = [
  { id: 1, name: 1, label: "Yes" },
  { id: 2, name: 0, label: "No" },
];
const value = [
  { id: 1, name: "0" },
  { id: 2, name: "1" },
];
const valueOne = [
  { id: 1, name: "0" },
  { id: 2, name: "1" },
];

const valueTwo = [
  { id: 1, name: "0" },
  { id: 2, name: "1" },
];

const label = { inputProps: { "aria-label": "Switch demo" } };

// PackageCard.propTypes = {
//   name: PropTypes.object,
//   selected: PropTypes.bool,
//   onEdit: PropTypes.func,
//   onSelect: PropTypes.func,
//   filter: PropTypes.object,
// };

export default function PackageCard({ item, name, pageLoad, filter }) {
  const { status } = name;
  const contxt = useAuthContext();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);
  const [open, setOpen] = useState(false);
  const [showTimer1, setShowTimer1] = useState(
    name?.offer_end_date !== null ? true : false
  );
  const [showTimer2, setShowTimer2] = useState(
    name?.offer_end_date !== null ? true : false
  );
  const [showTimer3, setShowTimer3] = useState(
    name?.offer_end_date !== null ? true : false
  );
  const [showTimer4, setShowTimer4] = useState(
    name?.offer_end_date !== null ? true : false
  );
  const { enqueueSnackbar } = useSnackbar();
  const [datePickerValue, setValue] = useState(
    name?.offer_end_date ? new Date(name?.offer_end_date) : new Date()
  );
  const [datePickerValueOne, setValueOne] = useState(
    item?.item?.dob ? new Date(item?.item?.dob) : new Date()
  );
  const [datePickerValueTwo, setValueTwo] = useState(
    item?.item?.dob ? new Date(item?.item?.dob) : new Date()
  );
  const [color, setColor] = useState(name?.color ? name?.color : "#000000");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const fileRef = useRef(null);

  const LoginSchema = Yup.object().shape({
    nameEnglish: Yup.string().required("Name is required"),
    // nameHindi: Yup.string().required('Name is required'),
    descriptionEnglish: Yup.string().required("Description is required"),
    // descriptionHindi: Yup.string().required('Description is required'),
    mrp: Yup.string().required("MRP is required"),
    user_referral_amount: Yup.string().required(
      "User Referral Amount is required"
    ),
    filter_view_limit: Yup.string().required("Filter View Limit is required"),
    //   partner_referral_amount: Yup.string().required('Partner Referral Amount is required'),
    //   partner_user_referral_amount: Yup.string().required('Partner User Referral Amount is required'),
    month: Yup.string().required("Month is required"),
    // view_limit: Yup.string().required('View Limit is required'),
    discount_value: Yup.string().required("Discount is required"),
    // per_month_amount: Yup.string(),
    is_top_value: Yup.string().required("Top Value is required"),
    is_top_seller: Yup.string().required("Top Seller is required"),
    is_vip: Yup.string().required("VIP is required"),
  });

  const defaultValues = {
    types: name?.types,
    gender: name?.gender,
    nameEnglish: name?.name?.english,
    nameHindi: name?.name?.hindi,
    header: name?.header,
    filter_view_limit: name?.filter_view_limit,
    status: name?.name?.status,
    descriptionEnglish: name?.description?.english,
    descriptionHindi: name?.description?.hindi,
    mrp: name?.mrp,
    mrpone: name?.mrpone,
    mrptwo: name?.mrptwo,
    mrpthree: name?.mrpthree,
    amount: name?.amount,
    discount_type: name?.discount_type,
    discount_type_one: name?.discount_type_one,
    discount_type_two: name?.discount_type_two,
    discount_type_three: name?.discount_type_three,
    color: name?.color,
    price: name?.price,
    priceone: name?.priceone,
    pricetwo: name?.pricetwo,
    pricethree: name?.pricethree,
    user_referral_amount: name?.user_referral_amount,
    user_referral_amount_one: name?.user_referral_amount_one,
    user_referral_amount_two: name?.user_referral_amount_two,
    user_referral_amount_three: name?.user_referral_amount_three,
    partner_referral_amount_one: name?.partner_referral_amount_one,
    partner_referral_amount: name?.partner_referral_amount,
    partner_referral_amount_two: name?.partner_referral_amount_two,
    partner_referral_amount_three: name?.partner_referral_amount_three,
    partner_user_referral_amount: name?.partner_user_referral_amount,
    month: name?.month,
    month_one: name?.month_one,
    month_two: name?.month_two,
    month_three: name?.month_three,
    view_limit: name?.view_limit,
    view_limit_one: name?.view_limit_one || 0,
    view_limit_two: name?.view_limit_two || 0,
    view_limit_three: name?.view_limit_three || 0,
    discount_value: name?.discount_value,
    discount_value_one: name?.discount_value_one,
    discount_value_two: name?.discount_value_two,
    discount_value_three: name?.discount_value_three,
    offer_end_date: name?.offer_end_date,
    per_month_amount: name?.per_month_amount,
    per_month_amount_one: name?.per_month_amount_one,
    per_month_amount_two: name?.per_month_amount_two,
    per_month_amount_three: name?.per_month_amount_three,
    is_top_value: name?.is_top_value ? "1" : "0",
    is_top_value_one: name?.is_top_value_one ? "1" : "0",
    is_top_value_two: name?.is_top_value_two ? "1" : "0",
    is_top_value_three: name?.is_top_value_three ? "1" : "0",
    is_top_seller: name?.is_top_seller ? "1" : "0",
    is_top_seller_one: name?.is_top_seller_one ? "1" : "0",
    is_top_seller_two: name?.is_top_seller_two ? "1" : "0",
    is_top_seller_three: name?.is_top_seller_three ? "1" : "0",
    is_vip: name?.is_vip ? "1" : "0",
    package_id: name?.id,
    amountone: name?.mrp,
    amounttwo: name?.mrp,
    amountthree: name?.mrp,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: {},
  } = methods;

  const handleClose = () => {
    setOpen(!open);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setFilePreview(e.target.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setFilePreview(null);
    }
  };

  const onStatus = async () => {
    if (name.religion === "Jain") {
      let data = { package_id: name?.id, status: !name?.status };
      let res = await updatePacakgeStatus(data);
      pageLoad();
    } else {
      let data = { package_id: name?.id, status_order: !name?.status };
      let res = await updatePacakgeStatus(data);
      pageLoad();
    }
  };
  const onOnlyForMasterAdminChange = async () => {
    if (name.religion === "Jain") {
      let data = { id: name?.id };
      let res = await updateOnlyForMasterAdminApi(data);
      pageLoad();
    }
  };
  const handleSelectChange = (event) => {
    const { value } = event.target;

    if (value.includes("All")) {
      setSelectedCommunities(
        selectedCommunities.length === community.length
          ? []
          : community.map((c) => c.name)
      );
    } else {
      setSelectedCommunities(value);
    }
  };

  const handleSliderOrderChange = (event, value) => {
    const selectedValue = parseFloat(value) || null;
    onSliderOrderChange(selectedValue);
  };
  const onSliderOrderChange = async (val) => {
    if (name.religion === "Jain") {
      if (val !== null) {
        let data = {
          id: name?.id,
          slider_order: val,
        };
        let res = await updateSliderOrder(data);
        if (res?.data?.status) {
          enqueueSnackbar(res?.data?.data);
          pageLoad();
        } else {
          enqueueSnackbar(res?.data?.data, { variant: "error" });
        }
      } else {
        enqueueSnackbar("Invalid Order", { variant: "error" });
      }
    } else {
      if (val !== null) {
        let data = {
          package_id: name?.id,
          slider_order: val,
        };
        let res = await updateSlider_OrderHindu(data);
        if (res?.data?.status) {
          enqueueSnackbar(res?.data?.data);
          pageLoad();
        } else {
          enqueueSnackbar(res?.data?.data, { variant: "error" });
        }
      } else {
        enqueueSnackbar("Invalid Order", { variant: "error" });
      }
    }
  };
  const onSliderViewChange = async () => {
    if (name.religion === "Jain") {
      let data = { id: name?.id };
      let res = await updateSliderView(data);
      console.log(res?.data?.data);
      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.data);
        pageLoad();
      } else {
        enqueueSnackbar(res?.data?.data, { variant: "error" });
      }
    } else {
      let data = {
        package_id: name?.id,
        slider_view: name?.slider_view === 1 ? 0 : 1,
      };
      let res = await updateSlider_OrderHindu(data);
      console.log(res?.data?.data);
      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.data);
        pageLoad();
      } else {
        enqueueSnackbar(res?.data?.data, { variant: "error" });
      }
    }
  };

  const onDelete = async () => {
    let data = { package_id: name?.id };
    if (name.religion === "Jain") {
      var response = await deletePack(data);
    } else {
      var response = await deleteHinduPack(data);
    }
    if (status) enqueueSnackbar("Delete Successfully!");
    pageLoad();
  };
  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("types", data?.types);
    formData.append("gender", data?.gender);
    formData.append("community", selectedCommunities);
    formData.append("nameEnglish", data?.nameEnglish);
    formData.append("header", data?.header);
    formData.append("filter_view_limit", data?.filter_view_limit);
    formData.append("mrp", data?.mrp !== null ? data?.mrp : 0);
    formData.append("mrpone", data?.mrpone !== null ? data?.mrpone : 0);
    formData.append("mrptwo", data?.mrptwo !== null ? data?.mrptwo : 0);
    formData.append("mrpthree", data?.mrpthree !== null ? data?.mrpthree : 0);
    formData.append("price", data?.price !== null ? data?.price : 0);
    formData.append("priceone", data?.priceone !== null ? data?.priceone : 0);
    formData.append("pricetwo", data?.pricetwo !== null ? data?.pricetwo : 0);
    formData.append(
      "pricethree",
      data?.pricethree !== null ? data?.pricethree : 0
    );
    formData.append(
      "user_referral_amount",
      data?.user_referral_amount !== null ? data?.user_referral_amount : 0
    );
    formData.append(
      "user_referral_amount_one",
      data?.user_referral_amount_one !== null
        ? data?.user_referral_amount_one
        : 0
    );
    formData.append(
      "user_referral_amount_two",
      data?.user_referral_amount_two !== null
        ? data?.user_referral_amount_two
        : 0
    );
    formData.append(
      "user_referral_amount_three",
      data?.user_referral_amount_three !== null
        ? data?.user_referral_amount_three
        : 0
    );
    formData.append(
      "partner_referral_amount",
      data?.partner_referral_amount !== null ? data?.partner_referral_amount : 0
    );
    formData.append(
      "partner_referral_amount_one",
      data?.partner_referral_amount_one !== null
        ? data?.partner_referral_amount_one
        : 0
    );
    formData.append(
      "partner_referral_amount_two",
      data?.partner_referral_amount_two !== null
        ? data?.partner_referral_amount_two
        : 0
    );
    // formData.append("partner_referral_amount_three", data?.partner_referral_amount_three  !== null ? data?.partner_referral_amount_three : 0);
    formData.append(
      "partner_user_referral_amount",
      data?.partner_user_referral_amount !== null
        ? data?.partner_user_referral_amount
        : 0
    );
    formData.append("month", data?.month !== null ? data?.month : 0);
    formData.append(
      "month_one",
      data?.month_one !== null ? data?.month_one : 0
    );
    formData.append(
      "month_two",
      data?.month_two !== null ? data?.month_two : 0
    );
    formData.append(
      "month_three",
      data?.month_three !== null ? data?.month_three : 0
    );
    formData.append(
      "view_limit",
      data?.view_limit !== null ? data?.view_limit : 0
    );
    formData.append("view_limit_one", data?.view_limit_one || 0);
    formData.append("view_limit_two", data?.view_limit_two || 0);
    formData.append("view_limit_three", data?.view_limit_three || 0);
    formData.append("descriptionEnglish", data?.descriptionEnglish);
    formData.append("description_hindi", data?.description_hindi);
    formData.append(
      "discount_value",
      data?.discount_value !== null ? data?.discount_value : 0
    );
    formData.append(
      "discount_value_one",
      data?.discount_value_one !== null ? data?.discount_value_one : 0
    );
    formData.append(
      "discount_value_two",
      data?.discount_value_two !== null ? data?.discount_value_two : 0
    );
    formData.append(
      "discount_value_three",
      data?.discount_value_three !== null ? data?.discount_value_three : 0
    );
    formData.append("discount_type", data?.discount_type);
    formData.append("discount_type_one", data?.discount_type_one);
    formData.append("discount_type_two", data?.discount_type_two);
    formData.append("discount_type_three", data?.discount_type_three);
    formData.append(
      "offer_end_date",
      showTimer1 ? datePickerValue || moment().format("DD-MM-YY") : new Date()
    );
    formData.append(
      "offer_end_date_one",
      showTimer2 ? datePickerValue || moment().format("DD-MM-YY") : new Date()
    );
    formData.append(
      "offer_end_date_two",
      showTimer3 ? datePickerValue || moment().format("DD-MM-YY") : new Date()
    );
    formData.append(
      "offer_end_date_three",
      showTimer4 ? datePickerValue || moment().format("DD-MM-YY") : new Date()
    );
    formData.append(
      "per_month_amount",
      data?.per_month_amount !== null ? data?.per_month_amount : 0
    );
    formData.append(
      "per_month_amount_one",
      data?.per_month_amount_one !== null ? data?.per_month_amount_one : 0
    );
    formData.append(
      "per_month_amount_two",
      data?.per_month_amount_two !== null ? data?.per_month_amount_two : 0
    );
    // formData.append("per_month_amount_three", data?.per_month_amount_three !==null ? data?.per_month_amount_three : 0);
    formData.append("is_top_value", data?.is_top_value);
    formData.append("is_top_value_one", data?.is_top_value_one);
    formData.append("is_top_value_two", data?.is_top_value_two);
    formData.append("is_top_value_three", data?.is_top_value_three);
    formData.append("is_top_seller", data?.is_top_seller);
    formData.append("is_top_seller_one", data?.is_top_seller_one);
    formData.append("is_top_seller_two", data?.is_top_seller_two);
    formData.append("is_top_seller_three", data?.is_top_seller_three);
    formData.append("is_vip", data?.is_vip);
    formData.append("position", 1);
    formData.append("amount", data?.amount !== null ? data?.amount : 0);
    formData.append(
      "amountone",
      data?.amountone !== null ? data?.amountone : 0
    );
    formData.append(
      "amounttwo",
      data?.amounttwo !== null ? data?.amounttwo : 0
    );
    formData.append(
      "amountthree",
      data?.amountthree !== null ? data?.amountthree : 0
    );
    formData.append("status", true);
    formData.append("color", data?.color);
    formData.append("images", fileRef.current.files[0] ?? null);
    try {
      // data.package_id = name?.id;
      // data.offer_end_date = datePickerValue;
      // data.color = color;
      if (name.religion === "Jain") {
        let res = await updatePacakge(name?.id, formData);
      } else {
        let res = await updateHinduPacakge(name?.id, formData);
      }
      enqueueSnackbar("Updated Successfully!");
      pageLoad();
      setOpen(!open);
    } catch (error) {
      console.error(error);
    } finally {
      reset();
    }
  };

  // const handleDateChange = (value) => {
  //   const date = new Date(value);
  //   const isoString = date.toISOString();
  //   setValue(isoString);
  // };

  const handleDateChange = (value) => {
    if (value && !isNaN(new Date(value).getTime())) {
      const date = new Date(value);
      const isoString = date.toISOString();
      setValue(isoString);
    } else {
      // Handle invalid date, perhaps set to null or a default date
      setValue(null);
    }
  };

  const handleDateChangeOne = (value) => {
    const date = new Date(value);
    const isoString = date.toISOString();
    setValueOne(isoString);
  };
  const handleDateChangeTwo = (value) => {
    const date = new Date(value);
    const isoString = date.toISOString();
    setValueTwo(isoString);
  };

  return (
    <Grid sx={{ mb: 3 }}>
      <Card key={name.value} sx={{ textAlign: "left" }}>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="h6"
            sx={{ mt: 3, ml: 3, display: "block", fontWeight: "bold" }}
          >
            {" "}
            Religion{" "}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mt: 3,
              ml: 2,
              display: "block",
              color: "text.secondary",
            }}
          >
            {" "}
            : {name.religion}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="h6"
            sx={{ mb: 1, mt: 3, ml: 3, display: "block" }}
          >
            {" "}
            Name{" "}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              mt: 3,
              ml: 2,
              display: "block",
              color: "text.secondary",
            }}
          >
            {" "}
            : {name.name.english}{" "}
          </Typography>
        </Box>
        {contxt?.user?.sections?.packages?.update && (
          <Stack
            justifyContent="space-between"
            direction="row"
            spacing={3}
            sx={{ ml: 3 }}
          >
            <Typography
              variant="overline"
              sx={{ display: "block", ml: 3 }}
              className="checkBox"
            >
              Status
              <Switch {...label} checked={name?.status} onChange={onStatus} />
            </Typography>
            <Box open={openPopover} onClose={handleClosePopover}>
              <Tooltip
                title="Update"
                onClick={() => {
                  handleClose();
                }}
                sx={{ typography: "body1", mr: 1, mb: 1 }}
              >
                <Button variant="contained" size="small">
                  {" "}
                  Update
                </Button>
              </Tooltip>
            </Box>
          </Stack>
        )}
        <Stack
          justifyContent="space-between"
          direction="row"
          spacing={3}
          sx={{ ml: 4, my: 1 }}
        >
          <Typography variant="h6">
            Slider View
            <Switch
              {...label}
              checked={name?.slider_view === 1 ? true : false}
              onChange={onSliderViewChange}
            />
          </Typography>
          <Box className="me-3">
            <Autocomplete
              freeSolo
              id="sliderOrder"
              size="small"
              value={name?.slider_order || ""}
              onChange={handleSliderOrderChange}
              options={SliderOrderOption.map((opt) => opt?.value)}
              sx={{ width: 150, padding: 0 }}
              renderInput={(params) => (
                <TextField {...params} label="Slider Order" />
              )}
            />
          </Box>
        </Stack>
        <Stack
          justifyContent="space-between"
          direction="row"
          spacing={3}
          sx={{ ml: 4, my: 1 }}
        >
          <Typography
            variant="overline"
            sx={{ display: "block", ml: 3 }}
            className="checkBox"
          >
            Only for Master admin
            <Switch
              {...label}
              checked={name?.view_key}
              onChange={onOnlyForMasterAdminChange}
            />
          </Typography>
        </Stack>
        <Divider sx={{ borderStyle: "solid" }} />
        {name?.image && (
          <Box
            variant="subtitle1"
            display="flex"
            sx={{ flexDirection: "row", margin: "auto" }}
          >
            <CardMedia
              component="img"
              sx={{
                mx: "auto",
                borderRadius: 1,
                width: 399,
                objectFit: "contain",
              }}
              height="194"
              image={`${constant.appBaseUrl}/users/packages/${name?.image}`}
              alt=""
            />
          </Box>
        )}
        <Box display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 3, ml: 3, display: "block" }}
          >
            {" "}
            Plan :&nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 2.5, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name.types}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Gender :&nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.gender}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Name Hindi :&nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name.name.hindi}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Header :&nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.header}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            FILTER LIMIT :&nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.filter_view_limit}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            MRP :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.mrp}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            MRP One:&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.mrpone}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            MRP Two:&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.mrptwo}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            MRP Three:&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.mrpthree}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Price :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.price}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Price One :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.priceone}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Price Two :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.pricetwo}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Price Three :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.pricethree}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            User Referral Amount :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.user_referral_amount}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            User Referral Amount One :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.user_referral_amount_one}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            User Referral Amount Two :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.user_referral_amount_two}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            User Referral Amount Three :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.user_referral_amount_three}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Partner Referral Amount :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name.partner_referral_amount}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Partner Referral Amount One :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name.partner_referral_amount}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Partner Referral Amount Two :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.partner_referral_amount_two}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Partner Referral Amount Three :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.partner_referral_amount_three}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Partner User Referral Amount :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name.partner_user_referral_amount}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Month :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name.month}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Month 2:&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name.month_one}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Month 3:&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name.month_two}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Per Day View Limit :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.view_limit}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Per Day View Limit One :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.view_limit_one || 0}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Per Day View Limit Two :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.view_limit_two || 0}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Per Day View Limit Three :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.view_limit_three || 0}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block", height: 100 }}
          >
            {" "}
            Descripton &nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 1, ml: 3, display: "block", color: "text.secondary" }}
            dangerouslySetInnerHTML={{ __html: name.description.english }}
          />
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Discount Value :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name.discount_value}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Discount Value One :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.discount_value_one}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Discount Value Two :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.discount_value_two}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Discount Value Three :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.discount_value_three}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Per Month Amount :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.per_month_amount}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Per Month Amount One :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.per_month_amount_one}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Per Month Amount Two :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.per_month_amount_two}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Per Month Amount Three :&nbsp;
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.per_month_amount_three}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Color Code :&nbsp;{" "}
          </Typography>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.color}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            Top Value :&nbsp;
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 0.6, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name.is_top_value ? "true" : "false"}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            Top Value One :&nbsp;
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 0.6, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.is_top_value_one ? "true" : "false"}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            Top Value Two :&nbsp;
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 0.6, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.is_top_value_two ? "true" : "false"}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            Top Value Three :&nbsp;
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 0.6, ml: 2, display: "block", color: "text.secondary" }}
          >
            {" "}
            {name?.is_top_value_three ? "true" : "false"}{" "}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Top Seller :&nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 0.6, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name.is_top_seller ? "True" : "False"}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Top Seller One :&nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 0.6, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.is_top_seller_one ? "True" : "False"}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Top Seller Two :&nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 0.6, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.is_top_seller_two ? "True" : "False"}
          </Typography>
        </Box>
        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mt: 1, ml: 3, display: "block" }}
          >
            {" "}
            Top Seller Three :&nbsp;{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mt: 0.6, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name?.is_top_seller_three ? "True" : "False"}
          </Typography>
        </Box>

        <Box variant="subtitle1" display="flex" sx={{ flexDirection: "row" }}>
          <Typography
            variant="overline"
            sx={{ mb: 3, mt: 1, ml: 3, display: "block" }}
          >
            VIP :&nbsp;
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{ mt: 0.6, ml: 2, display: "block", color: "text.secondary" }}
          >
            {name.is_vip ? "True" : "False"}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "solid" }} />

        <ConfirmDialog
          className="deleteModal"
          open={openConfirm}
          onClose={handleCloseConfirm}
          title="Delete"
          content="Are you sure want to delete?"
          action={
            <div>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseConfirm}
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
              <Button variant="contained" color="error" onClick={onDelete}>
                Delete
              </Button>
            </div>
          }
        />
      </Card>
      {filter?.status === undefined ? (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={"md"}
          key={name?.id}
        >
          <CardHeader
            action={
              <Tooltip title="Close">
                <IconButton
                  color="error"
                  className="CloseBtn"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <Iconify icon="mdi:cancel-circle-outline" />
                </IconButton>
              </Tooltip>
            }
            title={name.name.english}
            sx={{ textAlign: "center" }}
          />
          <DialogContent>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Box
                    rowGap={3}
                    columnGap={2}
                    // display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(2, 1fr)",
                    }}
                  >
                    <Stack spacing={3} sx={{ mt: 4, mx: "auto", width: "75%" }}>
                      <RHFTextField
                        fullWidth
                        select
                        name="community"
                        label="Community"
                        value={selectedCommunities}
                        onChange={handleSelectChange}
                        SelectProps={{
                          multiple: true,
                          renderValue: (selected) => selected.join(", "),
                          MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                        }}
                        sx={{
                          maxWidth: { sm: "auto" },
                          textTransform: "capitalize",
                        }}
                      >
                        <MenuItem value="all">
                          <Checkbox
                            checked={
                              selectedCommunities.length === community.length
                            }
                            indeterminate={
                              selectedCommunities.length > 0 &&
                              selectedCommunities.length < community.length
                            }
                            sx={{
                              color: "red",
                              "&.Mui-checked": { color: "red" },
                            }}
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {community.map((option) => (
                          <MenuItem key={option.id} value={option.name}>
                            <Checkbox
                              checked={selectedCommunities.includes(
                                option.name
                              )}
                              sx={{
                                color: "red",
                                "&.Mui-checked": { color: "red" },
                              }}
                            />
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </RHFTextField>

                      {/* <RHFTextField
                        native
                        fullWidth
                        select
                        multiple
                        name="community"
                        label="Community"
                        SelectProps={{
                          multiple: true,
                          value: [],
                          renderValue: (selected) => {
                            if (selected.length === 0) {
                              return ''
                            }
                            return selected.join(', ')
                          },
                          MenuProps: {
                            PaperProps: { sx: { maxHeight: 260 } },
                          }
                        }}
                        sx={{
                          maxWidth: { sm: "auto" },
                          textTransform: "capitalize",
                        }}
                      >
                        {community?.map((i) => (
                          <MenuItem
                            key={i?.id}
                            value={i.name}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            <Checkbox
                              sx={{
                                color: 'grey.500',
                                '&.Mui-checked': {
                                  color: 'error.main',
                                }
                              }}
                            />
                            <ListItemText primary={i.name} />
                          </MenuItem>
                        ))}
                      </RHFTextField>                       */}

                      <RHFTextField
                        native
                        fullWidth
                        select
                        name="types"
                        label="Select Plan"
                        SelectProps={{
                          MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                        }}
                        sx={{
                          maxWidth: { sm: "auto" },
                          textTransform: "capitalize",
                        }}
                      >
                        {packagesType?.map((i) => (
                          <MenuItem
                            key={i?.id}
                            value={i.name}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {i.name}
                          </MenuItem>
                        ))}
                      </RHFTextField>
                      <RHFTextField
                        native
                        fullWidth
                        select
                        name="gender"
                        label="Select Gender"
                        SelectProps={{
                          MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                        }}
                        sx={{
                          maxWidth: { sm: "auto" },
                          textTransform: "capitalize",
                        }}
                      >
                        {gender?.map((i) => (
                          <MenuItem
                            key={i?.id}
                            value={i.name}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {i.name}
                          </MenuItem>
                        ))}
                      </RHFTextField>
                      <RHFTextField name="nameEnglish" label="Name" />
                      <RHFTextField name="header" label="Header" />
                      <RHFTextField name="nameHindi" label="Name Hindi" />
                      <RHFTextField
                        name="filter_view_limit"
                        label="Filter Limit"
                        type="tel"
                        helperText="you can enter maximum 11 limit"
                      />
                      {/* <Typography variant="overline" name="status" sx={{ ml: 3, display: 'block' }} className='checkBox' >
                                            Status
                                            <Switch {...label} checked={name?.status} onChange={onStatus} />
                                        </Typography> */}

                      {/* 1st package 3 MOnths */}
                      <Card>
                        <CardHeader
                          title={"1st Package"}
                          sx={{ textAlign: "center" }}
                        />
                        <CardContent spacing={10}>
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="mrp"
                            type="number"
                            label="MRP FOR 3 MONTHS"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="amount"
                            type="number"
                            label="Amount For 3 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="price"
                            type="number"
                            label="Price For 3 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="user_referral_amount"
                            type="number"
                            label="User Referral Amount For 3 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="partner_referral_amount_one"
                            type="number"
                            label="Partner Referral Amount For 3 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="view_limit"
                            type="number"
                            label="View Limit For 3 Months"
                          />
                          <RHFTextArea
                            sx={{ my: 1 }}
                            name="discount_value"
                            type="number"
                            label="Discount Value For 3 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="month"
                            type="number"
                            label="Month For 3 Months"
                          />
                          <Typography className="fw-bold">
                            Timer
                            <Switch
                              checked={showTimer1}
                              onClick={() => setShowTimer1(!showTimer1)}
                            />
                          </Typography>
                          {showTimer1 && (
                            // <DateTimePicker
                            //   sx={{ my: 1 }}
                            //   name="offer_end_datetime"
                            //   label="Offer End Date & Time For 3 Months"
                            //   value={datePickerValue}
                            //   onChange={(newValue) => {
                            //     setValue(newValue);
                            //   }}
                            //   renderInput={(params) => (
                            //     <TextField
                            //       {...params}
                            //       fullWidth
                            //       margin="normal"
                            //       helperText={null}
                            //     />
                            //   )}
                            // />
                            <TimeOffsetPicker
                              // value={datePickerValue}
                              onDateChange={handleDateChange}
                              // hasHour={true}
                            />
                          )}
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="discount_type"
                            label="Discount Type For 3 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {discount?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="per_month_amount"
                            label="Per Month Amount For 3 Months"
                          />
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="is_top_value"
                            label="Is Top Value For 3 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {value?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="is_top_seller"
                            label="Is Top Seller For 3 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {value?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                        </CardContent>
                      </Card>
                      {/*2nd package 6 Months */}
                      <Card>
                        <CardContent>
                          <CardHeader
                            title={"2nd Package"}
                            sx={{ textAlign: "center" }}
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="mrpone"
                            type="number"
                            label="MRP FOR 6 MONTHS"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="amountone"
                            type="number"
                            label="Amount For 6 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="priceone"
                            type="number"
                            label="Price For 6 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="user_referral_amount_one"
                            type="number"
                            label="User Referral Amount For 6 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="partner_referral_amount_two"
                            type="number"
                            label="Partner Referral Amount For 6 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="month_one"
                            type="number"
                            label="Month For 6 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="view_limit_one"
                            type="number"
                            label="View Limit For 6 Months"
                          />
                          <RHFTextArea
                            name="discount_value_one"
                            type="number"
                            label="Discount Value For 6 Months"
                          />
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="discount_type_one"
                            label="Discount Type For 6 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {discountOne?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                          <Typography className="fw-bold">
                            Timer
                            <Switch
                              checked={showTimer2}
                              onClick={() => setShowTimer2(!showTimer2)}
                            />
                          </Typography>
                          {showTimer2 && (
                            // <DateTimePicker
                            //   sx={{ my: 1 }}
                            //   name="offer_end_date"
                            //   label="Offer End Date For 6 Months"
                            //   value={datePickerValueOne}
                            //   onChange={(newValue) => {
                            //     setValueOne(newValue);
                            //   }}
                            //   renderInput={(params) => (
                            //     <TextField
                            //       {...params}
                            //       fullWidth
                            //       margin="normal"
                            //       helperText={null}
                            //     />
                            //   )}
                            // />
                            <TimeOffsetPicker
                              // defaultDate={datePickerValueOne}
                              onDateChange={handleDateChangeOne}
                              // hasHour={true}
                            />
                          )}
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="is_top_value_one"
                            label="Is Top Value For 6 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {valueOne?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="per_month_amount_one"
                            label="Per Month Amount For 6 Months"
                          />
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="is_top_seller_one"
                            label="Is Top Seller For 6 Month"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {value?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                        </CardContent>
                      </Card>
                      {/*3rd package 12Months  */}
                      <Card>
                        <CardHeader
                          title={"3rd Package"}
                          sx={{ textAlign: "center" }}
                        />
                        <CardContent>
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="mrptwo"
                            type="number"
                            label="MRP FOR 12 MONTHS"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="amounttwo"
                            type="number"
                            label="Amount For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="pricetwo"
                            type="number"
                            label="Price For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="user_referral_amount_two"
                            type="number"
                            label="User Referral Amount For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="partner_referral_amount"
                            type="number"
                            label="Partner Referral Amount"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="partner_user_referral_amount"
                            type="number"
                            label="Partner User Referral Amount For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="month_two"
                            type="number"
                            label="Month For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="view_limit_two"
                            type="number"
                            label="View Limit For 12 Months"
                          />
                          <RHFTextArea
                            sx={{ my: 1 }}
                            name="discount_value_two"
                            type="number"
                            label="Discount Value For 12 Months"
                          />
                          <Typography className="fw-bold">
                            Timer
                            <Switch
                              checked={showTimer3}
                              onClick={() => setShowTimer3(!showTimer3)}
                            />
                          </Typography>
                          {showTimer3 && (
                            // <DateTimePicker
                            //   sx={{ my: 1 }}
                            //   name="offer_end_date"
                            //   label="Offer End Date For 12 Months"
                            //   value={datePickerValueTwo}
                            //   onChange={(newValue) => {
                            //     setValueTwo(newValue);
                            //   }}
                            //   renderInput={(params) => (
                            //     <TextField
                            //       {...params}
                            //       fullWidth
                            //       margin="normal"
                            //       helperText={null}
                            //     />
                            //   )}
                            // />
                            <TimeOffsetPicker
                              // defaultDate={datePickerValueTwo}
                              onDateChange={handleDateChangeTwo}
                              // hasHour={true}
                            />
                          )}
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="discount_type_two"
                            label="Discount Type For 12 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {discountTwo?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="per_month_amount_two"
                            label="Per Month Amount For 12 Months"
                          />
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="is_top_value_two"
                            label="Is Top Value For 12 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {valueTwo?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="is_top_seller_two"
                            label="Is Top Seller For 12 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {value?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                        </CardContent>
                      </Card>

                      {/* 4th package */}
                      <Card>
                        <CardHeader
                          title={"Fourth packages"}
                          sx={{ textAlign: "center" }}
                        />
                        <CardContent>
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="mrpthree"
                            type="number"
                            label="MRP FOR 12 MONTHS"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="amountthree"
                            type="number"
                            label="Amount For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="pricethree"
                            type="number"
                            label="Price For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="user_referral_amount_three"
                            type="number"
                            label="User Referral Amount For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="partner_referral_amount"
                            type="number"
                            label="Partner Referral Amount"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="partner_user_referral_amount"
                            type="number"
                            label="Partner User Referral Amount For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="month_three"
                            type="number"
                            label="Month For 12 Months"
                          />
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="view_limit_three"
                            type="number"
                            label="View Limit For 12 Months"
                          />
                          <RHFTextArea
                            sx={{ my: 1 }}
                            name="discount_value_three"
                            type="number"
                            label="Discount Value For 12 Months"
                          />
                          <Typography className="fw-bold">
                            Timer
                            <Switch
                              checked={showTimer4}
                              onClick={() => setShowTimer4(!showTimer4)}
                            />
                          </Typography>
                          {showTimer4 && (
                            <DateTimePicker
                              sx={{ my: 1 }}
                              name="offer_end_date"
                              label="Offer End Date For 12 Months"
                              value={datePickerValueTwo}
                              onChange={(newValue) => {
                                setValueTwo(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  margin="normal"
                                  helperText={null}
                                />
                              )}
                            />
                          )}
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="discount_type_three"
                            label="Discount Type For 12 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {discountTwo?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                          <RHFTextField
                            sx={{ my: 1 }}
                            name="per_month_amount_two"
                            label="Per Month Amount For 12 Months"
                          />
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="is_top_value_three"
                            label="Is Top Value For 12 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {valueTwo?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                          <RHFTextField
                            native
                            fullWidth
                            select
                            name="is_top_seller_three"
                            label="Is Top Seller For 12 Months"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: { sx: { maxHeight: 260 } },
                              },
                            }}
                            sx={{
                              maxWidth: { sm: "auto" },
                              textTransform: "capitalize",
                              my: 1,
                            }}
                          >
                            {value?.map((i) => (
                              <MenuItem
                                key={i?.id}
                                value={i.name}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </RHFTextField>
                        </CardContent>
                      </Card>
                      <Typography>Description English</Typography>
                      <RHFEditor simple name="descriptionEnglish" />
                      <Typography>Description Hindi</Typography>
                      <RHFEditor simple name="descriptionHindi" />

                      <Button
                        sx={{ mr: 10 }}
                        variant="outlined"
                        onClick={() =>
                          setShowColorPicker(
                            (showColorPicker) => !showColorPicker
                          )
                        }
                      >
                        Select Color - &nbsp;{color}
                      </Button>
                      {showColorPicker && (
                        <SketchPicker
                          name="color"
                          color={color}
                          onChange={(updatedColor) => {
                            setColor(updatedColor.hex);
                            setShowColorPicker(
                              (showColorPicker) => !showColorPicker
                            );
                          }}
                        />
                      )}
                      <RHFTextField
                        native
                        fullWidth
                        select
                        name="is_vip"
                        label="VIP"
                        SelectProps={{
                          MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                        }}
                        sx={{
                          maxWidth: { sm: "auto" },
                          textTransform: "capitalize",
                        }}
                      >
                        {payValue?.map((i) => (
                          <MenuItem
                            key={i?.id}
                            value={i.name}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {i.label}
                          </MenuItem>
                        ))}
                      </RHFTextField>
                      <Typography variant="h5">Add Image</Typography>
                      <label
                        htmlFor="file"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          backgroundColor: "#f7fbfe",
                          padding: "0.5em",
                          width: "50%",
                          borderRadius: 4,
                        }}
                      >
                        <PermMediaIcon
                          color="success"
                          sx={{ marginRight: 1 }}
                        />
                        <Typography variant="body1" color="textSecondary">
                          Choose File
                        </Typography>
                        <input
                          type="file"
                          id="file"
                          ref={fileRef}
                          accept=".png, .jpg, .jpeg"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                      </label>
                      {filePreview && (
                        <Card sx={{ maxWidth: "300px", m: "1em" }}>
                          <CardContent>
                            <div>
                              <img
                                src={filePreview}
                                alt="Selected"
                                style={{ maxWidth: "200px", height: "auto" }}
                              />
                            </div>
                          </CardContent>
                        </Card>
                      )}
                    </Stack>
                    <Stack
                      justifyContent="center"
                      direction="row"
                      sx={{ mt: 1, mb: 1 }}
                      spacing={3}
                    >
                      <Button variant="contained" color="error">
                        Clear
                      </Button>

                      <LoadingButton
                        color="inherit"
                        size="large"
                        type="submit"
                        variant="contained"
                        // onClick={(e) => {
                        //     userCreated();
                        // }}
                        // loading={isSubmitSuccessful || isSubmitting}
                        sx={{
                          bgcolor: "text.primary",
                          width: "20%",
                          justifyContent: "center",
                          typography: "body1",
                          color: (theme) =>
                            theme.palette.mode === "light"
                              ? "common.white"
                              : "grey.800",
                          "&:hover": {
                            bgcolor: "text.primary",
                            color: (theme) =>
                              theme.palette.mode === "light"
                                ? "common.white"
                                : "grey.800",
                          },
                        }}
                      >
                        ADD
                      </LoadingButton>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </FormProvider>
          </DialogContent>
        </Dialog>
      ) : (
        filter?.status === name?.status && (
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={"md"}
            key={name?.id}
          >
            <CardHeader
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={name.name.english}
              sx={{ textAlign: "center" }}
            />
            <DialogContent>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Box
                      rowGap={3}
                      columnGap={2}
                      // display="grid"
                      gridTemplateColumns={{
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                      }}
                    >
                      <Stack
                        spacing={3}
                        sx={{ mt: 4, mx: "auto", width: "75%" }}
                      >
                        <RHFTextField
                          fullWidth
                          select
                          name="community"
                          label="Community"
                          value={selectedCommunities}
                          onChange={handleSelectChange}
                          SelectProps={{
                            multiple: true,
                            renderValue: (selected) => selected.join(", "),
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {community.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.name}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Checkbox
                                checked={selectedCommunities.includes(
                                  option.name
                                )}
                                sx={{
                                  color: "red",
                                  "&.Mui-checked": { color: "red" },
                                  marginRight: 2, // Add space between checkbox and text
                                }}
                              />
                              <ListItemText primary={option.name} />
                            </MenuItem>
                          ))}
                        </RHFTextField>
                        <RHFTextField
                          native
                          fullWidth
                          select
                          name="types"
                          label="Select Plan"
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: "auto" },
                            textTransform: "capitalize",
                          }}
                        >
                          {packagesType?.map((i) => (
                            <MenuItem
                              key={i?.id}
                              value={i.name}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {i.name}
                            </MenuItem>
                          ))}
                        </RHFTextField>
                        <RHFTextField
                          native
                          fullWidth
                          select
                          name="gender"
                          label="Select Gender"
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: "auto" },
                            textTransform: "capitalize",
                          }}
                        >
                          {gender?.map((i) => (
                            <MenuItem
                              key={i?.id}
                              value={i.name}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {i.name}
                            </MenuItem>
                          ))}
                        </RHFTextField>
                        <RHFTextField name="nameEnglish" label="Name" />
                        <RHFTextField name="header" label="Header" />
                        <RHFTextField name="nameHindi" label="Name Hindi" />
                        <RHFTextField
                          name="filter_view_limit"
                          label="Filter Limit"
                          type="tel"
                          helperText="you can enter maximum 11 limit"
                        />
                        {/* <Typography variant="overline" name="status" sx={{ ml: 3, display: 'block' }} className='checkBox' >
                                          Status
                                          <Switch {...label} checked={name?.status} onChange={onStatus} />
                                      </Typography> */}

                        {/* 1st package 3 MOnths */}
                        <Card>
                          <CardHeader
                            title={"1st Package"}
                            sx={{ textAlign: "center" }}
                          />
                          <CardContent spacing={10}>
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="mrp"
                              type="number"
                              label="MRP FOR 3 MONTHS"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="amount"
                              type="number"
                              label="Amount For 3 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="price"
                              type="number"
                              label="Price For 3 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="user_referral_amount"
                              type="number"
                              label="User Referral Amount For 3 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="partner_referral_amount_one"
                              type="number"
                              label="Partner Referral Amount For 3 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="view_limit"
                              type="number"
                              label="View Limit For 3 Months"
                            />
                            <RHFTextArea
                              sx={{ my: 1 }}
                              name="discount_value"
                              type="number"
                              label="Discount Value For 3 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="month"
                              type="number"
                              label="Month For 3 Months"
                            />
                            <Typography className="fw-bold">
                              Timer
                              <Switch
                                checked={showTimer1}
                                onClick={() => setShowTimer1(!showTimer1)}
                              />
                            </Typography>
                            {showTimer1 && (
                              // <DateTimePicker
                              //   sx={{ my: 1 }}
                              //   name="offer_end_date"
                              //   label="Offer End Date For 3 Months"
                              //   value={datePickerValue}
                              //   onChange={(newValue) => {
                              //     setValue(newValue);
                              //   }}
                              //   renderInput={(params) => (
                              //     <TextField
                              //       {...params}
                              //       fullWidth
                              //       margin="normal"
                              //       helperText={null}
                              //     />
                              //   )}
                              // />
                              <TimeOffsetPicker
                                // defaultDate={datePickerValue}
                                onDateChange={handleDateChange}
                                // hasHour={true}
                              />
                            )}
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="discount_type"
                              label="Discount Type For 3 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {discount?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="per_month_amount"
                              label="Per Month Amount For 3 Months"
                            />
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="is_top_value"
                              label="Is Top Value For 3 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {value?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="is_top_seller"
                              label="Is Top Seller For 3 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {value?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                          </CardContent>
                        </Card>
                        {/*2nd package 6 Months */}
                        <Card>
                          <CardContent>
                            <CardHeader
                              title={"2nd Package"}
                              sx={{ textAlign: "center" }}
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="mrpone"
                              type="number"
                              label="MRP FOR 6 MONTHS"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="amountone"
                              type="number"
                              label="Amount For 6 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="priceone"
                              type="number"
                              label="Price For 6 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="user_referral_amount_one"
                              type="number"
                              label="User Referral Amount For 6 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="partner_referral_amount_two"
                              type="number"
                              label="Partner Referral Amount For 6 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="month_one"
                              type="number"
                              label="Month For 6 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="view_limit_one"
                              type="number"
                              label="View Limit For 6 Months"
                            />
                            <RHFTextArea
                              name="discount_value_one"
                              type="number"
                              label="Discount Value For 6 Months"
                            />
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="discount_type_one"
                              label="Discount Type For 6 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {discountOne?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                            <Typography className="fw-bold">
                              Timer
                              <Switch
                                checked={showTimer2}
                                onClick={() => setShowTimer2(!showTimer2)}
                              />
                            </Typography>
                            {showTimer2 && (
                              // <DateTimePicker
                              //   sx={{ my: 1 }}
                              //   name="offer_end_date"
                              //   label="Offer End Date For 6 Months"
                              //   value={datePickerValueOne}
                              //   onChange={(newValue) => {
                              //     setValueOne(newValue);
                              //   }}
                              //   renderInput={(params) => (
                              //     <TextField
                              //       {...params}
                              //       fullWidth
                              //       margin="normal"
                              //       helperText={null}
                              //     />
                              //   )}
                              // />
                              <TimeOffsetPicker
                                // defaultDate={datePickerValueOne}
                                onDateChange={handleDateChangeOne}
                                // hasHour={true}
                              />
                            )}
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="is_top_value_one"
                              label="Is Top Value For 6 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {valueOne?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="per_month_amount_one"
                              label="Per Month Amount For 6 Months"
                            />
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="is_top_seller_one"
                              label="Is Top Seller For 6 Month"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {value?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                          </CardContent>
                        </Card>
                        {/*3rd package 12Months  */}
                        <Card>
                          <CardHeader
                            title={"3rd Package"}
                            sx={{ textAlign: "center" }}
                          />
                          <CardContent>
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="mrptwo"
                              type="number"
                              label="MRP FOR 12 MONTHS"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="amounttwo"
                              type="number"
                              label="Amount For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="pricetwo"
                              type="number"
                              label="Price For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="user_referral_amount_two"
                              type="number"
                              label="User Referral Amount For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="partner_referral_amount"
                              type="number"
                              label="Partner Referral Amount"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="partner_user_referral_amount"
                              type="number"
                              label="Partner User Referral Amount For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="month_two"
                              type="number"
                              label="Month For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="view_limit_two"
                              type="number"
                              label="View Limit For 12 Months"
                            />
                            <RHFTextArea
                              sx={{ my: 1 }}
                              name="discount_value_two"
                              type="number"
                              label="Discount Value For 12 Months"
                            />
                            <Typography className="fw-bold">
                              Timer
                              <Switch
                                checked={showTimer3}
                                onClick={() => setShowTimer3(!showTimer3)}
                              />
                            </Typography>
                            {showTimer3 && (
                              // <DateTimePicker
                              //   sx={{ my: 1 }}
                              //   name="offer_end_date"
                              //   label="Offer End Date For 12 Months"
                              //   value={datePickerValueTwo}
                              //   onChange={(newValue) => {
                              //     setValueTwo(newValue);
                              //   }}
                              //   renderInput={(params) => (
                              //     <TextField
                              //       {...params}
                              //       fullWidth
                              //       margin="normal"
                              //       helperText={null}
                              //     />
                              //   )}
                              // />
                              <TimeOffsetPicker
                                // defaultDate={datePickerValueTwo}
                                onDateChange={handleDateChangeTwo}
                                // hasHour={true}
                              />
                            )}
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="discount_type_two"
                              label="Discount Type For 12 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {discountTwo?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="per_month_amount_two"
                              label="Per Month Amount For 12 Months"
                            />
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="is_top_value_two"
                              label="Is Top Value For 12 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {valueTwo?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="is_top_seller_two"
                              label="Is Top Seller For 12 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {value?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                          </CardContent>
                        </Card>

                        {/* 4th package */}
                        <Card>
                          <CardHeader
                            title={"Fourth packages"}
                            sx={{ textAlign: "center" }}
                          />
                          <CardContent>
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="mrpthree"
                              type="number"
                              label="MRP FOR 12 MONTHS"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="amountthree"
                              type="number"
                              label="Amount For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="pricethree"
                              type="number"
                              label="Price For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="user_referral_amount_three"
                              type="number"
                              label="User Referral Amount For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="partner_referral_amount"
                              type="number"
                              label="Partner Referral Amount"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="partner_user_referral_amount"
                              type="number"
                              label="Partner User Referral Amount For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="month_three"
                              type="number"
                              label="Month For 12 Months"
                            />
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="view_limit_three"
                              type="number"
                              label="View Limit For 12 Months"
                            />
                            <RHFTextArea
                              sx={{ my: 1 }}
                              name="discount_value_three"
                              type="number"
                              label="Discount Value For 12 Months"
                            />
                            <Typography className="fw-bold">
                              Timer
                              <Switch
                                checked={showTimer4}
                                onClick={() => setShowTimer4(!showTimer4)}
                              />
                            </Typography>
                            {showTimer4 && (
                              <DateTimePicker
                                sx={{ my: 1 }}
                                name="offer_end_date"
                                label="Offer End Date For 12 Months"
                                value={datePickerValueTwo}
                                onChange={(newValue) => {
                                  setValueTwo(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    margin="normal"
                                    helperText={null}
                                  />
                                )}
                              />
                            )}
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="discount_type_three"
                              label="Discount Type For 12 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {discountTwo?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                            <RHFTextField
                              sx={{ my: 1 }}
                              name="per_month_amount_two"
                              label="Per Month Amount For 12 Months"
                            />
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="is_top_value_three"
                              label="Is Top Value For 12 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {valueTwo?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                            <RHFTextField
                              native
                              fullWidth
                              select
                              name="is_top_seller_three"
                              label="Is Top Seller For 12 Months"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: { sx: { maxHeight: 260 } },
                                },
                              }}
                              sx={{
                                maxWidth: { sm: "auto" },
                                textTransform: "capitalize",
                                my: 1,
                              }}
                            >
                              {value?.map((i) => (
                                <MenuItem
                                  key={i?.id}
                                  value={i.name}
                                  sx={{
                                    mx: 1,
                                    my: 0.5,
                                    borderRadius: 0.75,
                                    typography: "body2",
                                    textTransform: "capitalize",
                                    "&:first-of-type": { mt: 0 },
                                    "&:last-of-type": { mb: 0 },
                                  }}
                                >
                                  {i.name}
                                </MenuItem>
                              ))}
                            </RHFTextField>
                          </CardContent>
                        </Card>
                        <Typography>Description English</Typography>
                        <RHFEditor simple name="descriptionEnglish" />
                        <Typography>Description Hindi</Typography>
                        <RHFEditor simple name="descriptionHindi" />

                        <Button
                          sx={{ mr: 10 }}
                          variant="outlined"
                          onClick={() =>
                            setShowColorPicker(
                              (showColorPicker) => !showColorPicker
                            )
                          }
                        >
                          Select Color - &nbsp;{color}
                        </Button>
                        {showColorPicker && (
                          <SketchPicker
                            name="color"
                            color={color}
                            onChange={(updatedColor) => {
                              setColor(updatedColor.hex);
                              setShowColorPicker(
                                (showColorPicker) => !showColorPicker
                              );
                            }}
                          />
                        )}
                        <RHFTextField
                          native
                          fullWidth
                          select
                          name="is_vip"
                          label="VIP"
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: "auto" },
                            textTransform: "capitalize",
                          }}
                        >
                          {payValue?.map((i) => (
                            <MenuItem
                              key={i?.id}
                              value={i.name}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {i.label}
                            </MenuItem>
                          ))}
                        </RHFTextField>
                        <Typography variant="h5">Add Image</Typography>
                        <label
                          htmlFor="file"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            backgroundColor: "#f7fbfe",
                            padding: "0.5em",
                            width: "50%",
                            borderRadius: 4,
                          }}
                        >
                          <PermMediaIcon
                            color="success"
                            sx={{ marginRight: 1 }}
                          />
                          <Typography variant="body1" color="textSecondary">
                            Choose File
                          </Typography>
                          <input
                            type="file"
                            id="file"
                            ref={fileRef}
                            accept=".png, .jpg, .jpeg"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                        </label>
                        {filePreview && (
                          <Card sx={{ maxWidth: "300px", m: "1em" }}>
                            <CardContent>
                              <div>
                                <img
                                  src={filePreview}
                                  alt="Selected"
                                  style={{ maxWidth: "200px", height: "auto" }}
                                />
                              </div>
                            </CardContent>
                          </Card>
                        )}
                      </Stack>
                      <Stack
                        justifyContent="center"
                        direction="row"
                        sx={{ mt: 1, mb: 1 }}
                        spacing={3}
                      >
                        <Button variant="contained" color="error">
                          Clear
                        </Button>

                        <LoadingButton
                          color="inherit"
                          size="large"
                          type="submit"
                          variant="contained"
                          // onClick={(e) => {
                          //     userCreated();
                          // }}
                          // loading={isSubmitSuccessful || isSubmitting}
                          sx={{
                            bgcolor: "text.primary",
                            width: "20%",
                            justifyContent: "center",
                            typography: "body1",
                            color: (theme) =>
                              theme.palette.mode === "light"
                                ? "common.white"
                                : "grey.800",
                            "&:hover": {
                              bgcolor: "text.primary",
                              color: (theme) =>
                                theme.palette.mode === "light"
                                  ? "common.white"
                                  : "grey.800",
                            },
                          }}
                        >
                          ADD
                        </LoadingButton>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </FormProvider>
            </DialogContent>
          </Dialog>
        )
      )}
    </Grid>
  );
}
