import PropTypes from "prop-types";
import {
  Stack,
  Switch,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
  Box,
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Iconify from "../../../../components/iconify";
import { useEffect, useState } from "react";
import {
  getCityListAPI,
  getCountryListAPI,
  getStateListAPI,
  gotraApi,
  subSectApi,
} from "src/service";
import Label from "../../../../components/label";

// ----------------------------------------------------------------------

BioDataUserFilter.propTypes = {
  isFiltered: PropTypes.bool,
  filterMobile: PropTypes.string,
  filterRole: PropTypes.string,
  onFilterMobile: PropTypes.func,
  onFilterRole: PropTypes.func,
  onResetFilter: PropTypes.func,
  optionsRole: PropTypes.arrayOf(PropTypes.string),
};

export default function BioDataUserFilter({
  isFiltered,
  filter,
  setFilter,
  getBiodataDetail,
  totalUsers,
  onResetFilter,
  filterDetail,
  setShowDifferent,
  setShowSuspend,
  staffName,
  handleFilterApply,
}) {
  const [startId, setStartId] = useState("");
  const [endId, setEndId] = useState("");
  const [subsect, setSubsect] = useState([]);
  const [show, setShow] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [year, setYear] = useState([]);
  const [dobEnd, setDobEnd] = useState([]);
  const [mobvalue, setMobvalue] = useState([]);
  const [gotraList, setGotraList] = useState([]);

  useEffect(() => {
    selectSubSect(filter?.sect);
    getCountryList();
    birthYear();
  }, [filter?.sect]);

  const ageArray = [];
  for (let index = 14; index < 81; index++) {
    ageArray?.push({ label: index + " yrs", value: index });
  }

  const jainType = [
    { label: "Maheswari Jain", value: "Maheswari Jain" },
    { label: "Digamber Jain", value: "Digamber Jain" },
    { label: "Shwetamber Jain", value: "Shwetamber Jain" },
    { label: "Taran tarn", value: "Taran tarn" },
    { label: "Khandelwal Jain", value: "Khandelwal Jain" },
    { label: "Agrawal Jain", value: "Agrawal Jain" },
    { label: "Gujrati Jain", value: "Gujrati Jain" },
    { label: "Marwadi Jain", value: "Marwadi Jain" },
    { label: "Baniya Jain", value: "Baniya Jain" },
    { label: "Saraf Jain", value: "Saraf Jain" },
    { label: "Gupta Jain", value: "Gupta Jain" },
    { label: "Other Cast", value: "Other Cast" },
  ];

  const notJain = [
    { label: "Hindu", value: "Hindu" },
    { label: "Muslim", value: "Muslim" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Sindhi", value: "Sindhi" },
    { label: "Isai", value: "Isai" },
  ];

  const specialCase = [
    { label: "Surajmukhi", value: "Surajmukhi" },
    { label: "Out of india ", value: "Out of india " },
    { label: "High profile", value: "High profile" },
    { label: "Divyang", value: "Divyang" },
    {
      label: "2nd Marriage (Divorce,widow)",
      value: "2nd Marriage (Divorce,widow)",
    },
    {
      label: "Not working(10thpass , 12thpass, House Wife)",
      value: "Not working(10thpass , 12thpass, House Wife)",
    },
  ];

  const impLeads = [
    { label: "Agree to Join", value: "agree_to_join" },
    { label: "Agree to Register", value: "agree_to_register" },
    { label: "Agree to Share", value: "agree_to_share" },
    { label: "Not Agree", value: "not_agree" },
  ];

  // const birthYear = () => {
  //   let currentYear = (new Date()).getFullYear();
  //   let range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
  //   setYear(range(currentYear - 18, currentYear - 79, -1))
  //   setDobEnd(range(currentYear - 17, currentYear - 79, -1))
  // };

  useEffect(() => {
    getStateList("India");
  }, []);

  const birthYear = () => {
    let currentYear = new Date().getFullYear();
    let range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );

    const selectAllAgesOption = "Select All Ages";
    const selectAllDobEndOption = "Select All";
    const yearOptions = [
      selectAllAgesOption,
      ...range(currentYear - 18, currentYear - 79, -1),
    ];
    const dobEndOptions = [
      selectAllDobEndOption,
      ...range(currentYear - 17, currentYear - 79, -1),
    ];
    const dobEndDisabled = filter.startAge === selectAllAgesOption;
    setYear(yearOptions);
    setDobEnd(dobEndOptions);
    setFilter({ ...filter, dobEndDisabled }); // Set the dobEndDisabled flag
  };

  const task = [
    { label: "all", value: "all" },
    { label: "Entry", value: "entry_by_login_sta" },
    { label: "Whatsapp", value: "whatsapp_done_by_staff" },
    { label: "Call", value: "call" },
    { label: "No Call", value: "not_call" },
    { label: "Not-Pickup", value: "not_pick" },
    { label: "Not-Connected", value: "not_connect" },
    { label: "Sambandh", value: "samband" },
    { label: "Shaadi Not Done", value: "not_shadi_done" },
    { label: "Register Not Done", value: "not_register" },
  ];
  const days = [
    { label: "all", value: "all" },
    { label: "today", value: "today" },
    { label: "yesterday", value: "yesterday" },
    { label: "Before Yesterday", value: "beforeYesterday" },
    { label: "Last 3 Days", value: "lastThreeDays" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },
  ];
  const gender = [
    { label: "all", value: "all" },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const Sect = [
    { label: "all", value: "all" },
    { label: "Digambar", value: "Digambar" },
    { label: "Shwetambar", value: "Shwetambar" },
  ];
  const physicalStatus = [
    { label: "all", value: "all" },
    { label: "Normal", value: "narmal" },
    { label: "Some Medical Problem", value: "someMedicalProblem" },
    { label: "Physically Challenged", value: "physicallyChallenged" },
  ];
  const maritalStatus = [
    { label: "all", value: "all" },
    { label: "Unmarried", value: "Unmarried" },
    { label: "Widow/widower", value: "Widow" },
    { label: "Divorced", value: "Divorce" },
    { label: "Awaiting divorce", value: "Awaiting divorce" },
  ];
  const ratings = [
    { label: "all", value: "all" },
    { label: "W", value: "w" },
    { label: "S", value: "s" },
    { label: "L", value: "l" },
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
  ];
  const dosh = [
    { label: "all", value: "all" },
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "Ansik Mangal", value: "Ansik Mangal" },
    { label: "DON`T KNOW", value: "DON`T KNOW" },
  ];
  const Diiffrent = [
    { label: "Diffrent", value: "offline" },
    { label: "Suspend", value: "offline" },
  ];

  const methods = useForm({});

  const { getValues } = methods;

  const getCountryList = async () => {
    let res = await getCountryListAPI();
    setCountryList(res?.data?.data);
  };
  //   const getCountryList = async () => {
  //   let res = await getCountryListAPI();
  //   const countries = res?.data?.data;

  //   // Add a "Select All" option to the country list
  //   const selectAllOption = { id: -1, name_english: "Select All" };
  //   setCountryList([selectAllOption, ...countries]);
  // };

  const selectSubSect = async (sub) => {
    let res = await subSectApi(sub);
    if (res?.data?.status) {
      setSubsect(res?.data?.data);
    }
  };

  const getGotraBySubsect = async (data) => {
    const res = await gotraApi(data);
    setGotraList(res?.data?.data);
  };

  const getStateList = async (value) => {
    let res = await getStateListAPI(value);
    setStateList(res?.data?.data);
  };

  const getcityList = async (value) => {
    let res = await getCityListAPI(value);
    setCityList(res?.data?.data);
  };

  const styles = {
    smallNormalText: {
      marginTop: "0px",
      fontWeight: "normal",
      color: "rgb(105, 105, 105)",
      fontSize: 12.5,
      whiteSpace: "nowrap",
    },
  };

  return (
    <>
      {filterDetail && (
        <>
          <Grid container spacing={2}>
            <Grid item lg={2} md={3} sm={6} xs={6}>
              <TextField
                value={startId}
                label="From (से)"
                onChange={(e) => {
                  setStartId(e.target.value);
                  setFilter({ ...filter, startId: e.target.value });
                }}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={6}>
              <TextField
                value={endId}
                label="To (तक)"
                onChange={(e) => {
                  setEndId(e.target.value);
                  setFilter({ ...filter, endId: e.target.value });
                }}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item lg={5} md={4} sm={10} xs={10}>
              <div style={{ position: "relative" }}>
                <TextField
                  fullWidth
                  value={mobvalue}
                  onChange={(e) => {
                    setMobvalue(e.target.value);
                    setFilter({ ...filter, mobile: e.target.value });
                  }}
                  placeholder="Search Mobile..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <Label
                  sx={{ position: "absolute", right: 10, top: 14 }}
                  onClick={() => {
                    onResetFilter();
                    getBiodataDetail();
                  }}
                >
                  {totalUsers}
                </Label>
              </div>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              {isFiltered && (
                <Button
                  color="error"
                  sx={{ flexShrink: 0, mt: 1 }}
                  onClick={() => {
                    onResetFilter();
                    getBiodataDetail();
                  }}
                  startIcon={<Iconify icon="mdi:cancel-circle-outline" />}
                >
                  Clear
                </Button>
              )}
            </Grid>
          </Grid>

          <Stack
            spacing={2}
            alignItems="center"
            direction={{
              xs: "column",
              sm: "row",
            }}
            sx={{ px: 0 }}
          >
            <Grid container spacing={2}>
              <Grid item lg={2} md={3} xs={4} sm={3}>
                <TextField
                  native
                  fullWidth
                  select
                  name=""
                  label="Timing (समय)"
                  onChange={(e) => {
                    setFilter({ ...filter, created_at: e.target.value });
                  }}
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                  }}
                  sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                >
                  {days?.map((option) => (
                    <MenuItem
                      key={option?.value}
                      value={option?.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={2} md={3} xs={4} sm={3}>
                <TextField
                  native
                  fullWidth
                  select
                  name=""
                  label="Task (कार्य)"
                  onChange={(e) => {
                    task.map((option) => (filter[option.value] = false));
                    filter[e.target.value] = true;
                    setFilter({ ...filter });
                  }}
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                  }}
                  sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                >
                  {task?.map((option) => (
                    <MenuItem
                      key={option?.value}
                      value={option?.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={2} md={3} xs={4} sm={3}>
                <TextField
                  native
                  fullWidth
                  select
                  name=""
                  label="Staff (कर्मचारी)"
                  onChange={(e) => {
                    setFilter({ ...filter, staff: e.target.value });
                  }}
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                  }}
                  sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                >
                  {staffName?.map((option) => (
                    <MenuItem
                      key={option?.value}
                      value={option?.id}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option?.nameEnglish}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={2} md={3} xs={4} sm={3}>
                <TextField
                  native
                  fullWidth
                  select
                  name="ratings"
                  label="Ratings (रेटिंग्स)"
                  onChange={(e) => {
                    setFilter({ ...filter, rating: e.target.value });
                  }}
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                  }}
                  sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                >
                  {ratings?.map((option) => (
                    <MenuItem
                      key={option?.value}
                      value={option?.label}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={2} md={3} xs={4} sm={3}>
                <TextField
                  native
                  fullWidth
                  select
                  name="gender"
                  label="Select Gender (लिंग)"
                  onChange={(e) => {
                    setFilter({ ...filter, gender: e.target.value });
                  }}
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                  }}
                  sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                >
                  {gender.map((option) => (
                    <MenuItem
                      key={option?.value}
                      value={option?.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={2} md={3} xs={4} sm={3}>
                <TextField
                  native
                  fullWidth
                  select
                  name="isScannerData"
                  label="Cron Data (क्रोन डेटा)"
                  onChange={(e) => {
                    setFilter({ ...filter, scanner_data: e.target.value });
                  }}
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                  }}
                  sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                >
                  {[
                    { label: "All", value: false },
                    { label: "Cron Data", value: true },
                  ].map((option) => (
                    <MenuItem
                      key={option?.value}
                      value={option?.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={2} md={3} xs={4} sm={3}>
                <TextField
                  native
                  fullWidth
                  select
                  name="isScannerData"
                  label="Gpt Data"
                  onChange={(e) => {
                    setFilter({ ...filter, gpt_data: e.target.value });
                  }}
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                  }}
                  sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                >
                  {[
                    { label: "All", value: "" },
                    { label: "Gpt Data", value: "gpt" },
                  ].map((option) => (
                    <MenuItem
                      key={option?.value}
                      value={option?.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={2} md={3} xs={4} sm={3}>
                <TextField
                  native
                  fullWidth
                  select
                  name="dataAssign"
                  label="Data Assign (डेटा सौंपना)"
                  onChange={(e) => {
                    setFilter({ ...filter, assign_data: e.target.value });
                  }}
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                  }}
                  sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                >
                  {[
                    { label: "Assigned Data", value: "assign" },
                    { label: "Not Assigned Data", value: "not_assign" },
                  ].map((option) => (
                    <MenuItem
                      key={option?.value}
                      value={option?.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={2} md={3} xs={4} sm={3}>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setShow(!show);
                    }}
                    sx={{ py: 2, mt: 1, whiteSpace: "nowrap" }}
                  >
                    {show ? "Hide Advance Filter" : "Show Advance Filter"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
          {show && (
            <div>
              <Stack
                spacing={2}
                alignItems="center"
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                sx={{ px: 0, py: 1 }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="employedIn"
                      label="Select Profession (व्यवसाय)"
                      onChange={(e) => {
                        setFilter({ ...filter, employedIn: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {[{ label: "all", value: "all" }]
                        .concat(filterDetail?.employedIn?.english)
                        ?.map((option) => (
                          <MenuItem
                            key={option?.value}
                            value={option?.value}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {option?.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      defaultValue={"India"}
                      name="second_formCountryEnglish"
                      label="Select Country (देश)"
                      onChange={(e) => {
                        setFilter({ ...filter, country: e.target.value });
                        getStateList(e.target.value);
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: "auto" },
                        textTransform: "capitalize",
                      }}
                    >
                      <MenuItem value="" />
                      {countryList?.map((i) => (
                        <MenuItem
                          key={i?.id}
                          value={i?.name_english}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {i?.name_english}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="state"
                      label="Select State (राज्य)"
                      onChange={(e) => {
                        setFilter({ ...filter, state: e.target.value });
                        getcityList(e.target.value);
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {StateList?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.english}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.english}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="state"
                      label="Select City (शहर)"
                      onChange={(e) => {
                        setFilter({ ...filter, city: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {cityList?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.name_english}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.name_english}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="education"
                      label="Select Education (शिक्षा)"
                      onChange={(e) => {
                        setFilter({ ...filter, education: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {[{ name_english: "all", value: "all" }]
                        .concat(filterDetail?.education)
                        ?.map((option) => (
                          <MenuItem
                            key={option?.value}
                            value={option?.name_english}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {option?.name_english}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="height"
                      label="Select Height (ऊंचाई))"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          heightOfUser: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {[{ label: "all", value: "all", data: ["all"] }]
                        .concat(filterDetail?.height?.english)
                        ?.map((option) => (
                          <MenuItem
                            key={option?.value}
                            value={option?.data[0]}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {option?.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="mother_tongue"
                      label="Select Mother Tongue (आम भाषा))"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          mother_tongue: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {[{ label: "all", value: "all" }]
                        .concat(filterDetail?.motherTongueList?.english)
                        ?.map((option) => (
                          <MenuItem
                            key={option?.value}
                            value={option?.label}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {option?.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="eating_habits"
                      label="Select Eating Habits (इटिंग हैबिट्स))"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          eating_habits: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {[{ label: "all", value: "all" }]
                        .concat(filterDetail?.eatingList?.english)
                        ?.map((option) => (
                          <MenuItem
                            key={option?.value}
                            value={option?.label}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {option?.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="special_collage"
                      label="Select Special Collage (स्पेशल कॉलेज))"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          special_collage: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {[
                        { label: "all", value: "all" },
                        { label: "NIT", value: "NIT" },
                        { label: "IIT", value: "IIT" },
                        { label: "IIM", value: "IIM" },
                      ]?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.label}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      name="gmail"
                      label="Enter Email (ईमेल))"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          gmail: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    ></TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      name="address"
                      label="Enter Address (पता))"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          address: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    ></TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="dosh"
                      label="Manglik (मांगलिक)"
                      onChange={(e) => {
                        setFilter({ ...filter, manglik: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {dosh?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Stack>

              <Stack
                spacing={2}
                alignItems="center"
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                sx={{ px: 0 }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="sect"
                      label="Select Community (सम्प्रदाय)"
                      onChange={(e) => {
                        setFilter({ ...filter, sect: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {Sect?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.label}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="subSect"
                      label="Sub-Community (उप-सम्प्रदाय)"
                      onChange={(e) => {
                        setFilter({ ...filter, subSect: e.target.value });
                        getGotraBySubsect(e.target.value);
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {[{ english: "all", english: "all" }]
                        .concat(subsect)
                        ?.map((option) => (
                          <MenuItem
                            key={option?.english}
                            value={option?.english}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {option?.english}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="gotra"
                      label="Gotra(गोत्र)"
                      onChange={(e) => {
                        setFilter({ ...filter, gotra: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: { sx: { maxHeight: 260 } },
                        },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {[{ english: "all", english: "all" }]
                        .concat(gotraList)
                        ?.map((option) => (
                          <MenuItem
                            key={option?.english}
                            value={option?.english}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: "body2",
                              textTransform: "capitalize",
                              "&:first-of-type": { mt: 0 },
                              "&:last-of-type": { mb: 0 },
                            }}
                          >
                            {option?.english}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name=""
                      label="Physical Status (शारीरिक-स्थिति)"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          physical_status: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {physicalStatus?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.label}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="maritalStatus"
                      label="Marital Status (वैवाहिक-स्थिति)"
                      onChange={(e) => {
                        setFilter({ ...filter, maritalStatus: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {maritalStatus?.map((option) => (
                        <MenuItem
                          key={option?.label}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name=""
                      onChange={(e) => {
                        setFilter({ ...filter, startAge: e.target.value });
                      }}
                      label="Start Age (जन्म तिथि शुरू)"
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: "auto" },
                        textTransform: "capitalize",
                      }}
                    >
                      {ageArray?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name=""
                      label="End Age (जन्म तिथि समाप्त)"
                      onChange={(e) => {
                        setFilter({ ...filter, endAge: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: "auto" },
                        textTransform: "capitalize",
                      }}
                    >
                      {ageArray?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="Diffrent Language"
                      label="Different (अलग)"
                      onChange={(e) => {
                        setFilter({ ...filter, rating: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {Diiffrent?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.label}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                          onClick={() => {
                            if (option.label === "Diffrent") {
                              setShowSuspend(false);
                              setShowDifferent(true);
                            } else if (option.label === "Suspend") {
                              setShowDifferent(false);
                              setShowSuspend(true);
                            }
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* uploaded by filter  */}
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="Uploaded Biodata Staff"
                      label="Uploaded Biodata Staff"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          Biodata_upload_by: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {staffName.map((staff) => (
                        <MenuItem
                          key={staff.id}
                          value={staff.id}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {staff.nameEnglish}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* income filter */}
                  {/* <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="Income"
                      label="Income"
                      onChange={(e) => {
                        setFilter({ ...filter, rating: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {Diiffrent?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.label}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                          onClick={() => {
                            if (option.label === "Diffrent") {
                              setShowSuspend(false);
                              setShowDifferent(true);
                            } else if (option.label === "Suspend") {
                              setShowDifferent(false);
                              setShowSuspend(true);
                            }
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  {/* height filter  */}
                  {/* <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="height"
                      label="Height"
                      onChange={(e) => {
                        setFilter({ ...filter, rating: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {Diiffrent?.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.label}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                          onClick={() => {
                            if (option.label === "Diffrent") {
                              setShowSuspend(false);
                              setShowDifferent(true);
                            } else if (option.label === "Suspend") {
                              setShowDifferent(false);
                              setShowSuspend(true);
                            }
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  {/* JAIN TYPE */}
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name=""
                      label="Jain Type"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          other_cast_type: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {jainType.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* Not Jain  */}
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name=""
                      label="Not Jain"
                      onChange={(e) => {
                        setFilter({ ...filter, not_jain_type: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {notJain.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* specail case */}
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name=""
                      label="Special case"
                      onChange={(e) => {
                        setFilter({ ...filter, special_case: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {specialCase.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* Imp Leads Dropdown */}
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="imp_leads"
                      label="Imp Leads"
                      onChange={(e) => {
                        setFilter({ ...filter, imp_leads: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {impLeads.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="image_upload_at"
                      label="Image Upload"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          image_upload_at: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {days.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="image_scaned_at"
                      label="Image Scan"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          image_scaned_at: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {days.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Stack>
            </div>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={handleFilterApply}
              sx={{ typography: "body1", py: 2, mt: 1 }}
            >
              Apply Filter
            </Button>
          </div>

          <Paper
            sx={{ borderRadius: 1, p: 1, mt: 1, bgcolor: "background.neutral" }}
          >
            <Stack direction={{ xs: "column", md: "row" }}>
              <Stack direction="row" spacing={3}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={styles.smallNormalText}
                  >
                    <Switch
                      checked={filter?.whatsapp_status ? true : false}
                      onChange={() => {
                        setFilter({
                          ...filter,
                          whatsapp_status: !filter?.whatsapp_status,
                        });
                      }}
                      size="small"
                    />
                    WAPP
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={styles.smallNormalText}
                  >
                    <Switch
                      checked={filter?.call_status ? true : false}
                      onChange={() => {
                        setFilter({
                          ...filter,
                          call_status: !filter?.call_status,
                        });
                      }}
                      size="small"
                    />
                    Call
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={styles.smallNormalText}
                  >
                    <Switch
                      checked={filter?.not_pickup_status ? true : false}
                      onChange={() => {
                        setFilter({
                          ...filter,
                          not_pickup_status: !filter?.not_pickup_status,
                        });
                      }}
                      size="small"
                    />
                    Not Pickup
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={3}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={styles.smallNormalText}
                  >
                    <Switch
                      checked={filter?.not_connected_status ? true : false}
                      onChange={() => {
                        setFilter({
                          ...filter,
                          not_connected_status: !filter?.not_connected_status,
                        });
                      }}
                      size="small"
                    />
                    Not Connected
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={styles.smallNormalText}
                  >
                    <Switch
                      checked={filter?.shadi_done_status ? true : false}
                      onChange={() => {
                        setFilter({
                          ...filter,
                          shadi_done_status: !filter?.shadi_done_status,
                        });
                      }}
                      size="small"
                    />
                    Shadi Done
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={styles.smallNormalText}
                  >
                    <Switch
                      checked={filter?.register_done_status ? true : false}
                      onChange={() => {
                        setFilter({
                          ...filter,
                          register_done_status: !filter?.register_done_status,
                        });
                      }}
                      size="small"
                    />
                    Register Done
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={3}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={styles.smallNormalText}
                  >
                    <Switch
                      checked={filter?.nonIndian ? true : false}
                      onChange={() => {
                        setFilter({ ...filter, nonIndian: !filter?.nonIndian });
                      }}
                      size="small"
                    />
                    Non-Indian
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Paper>
        </>
      )}
    </>
  );
}
