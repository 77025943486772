import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { setSession } from "src/auth/utils";
import {
  getDeviceDataById,
  logoutUser,
  updateloginStatusApi,
} from "src/service";

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  deviceInfo: "",
};

const reducer = (state, action) => {

  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const LoginDevices = ({ type, item }) => {
  const [list, setList] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getDeviceHistoryById();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    return `${day}/${month}/${year}, ${formattedHours}:${minutes}${ampm}`;
  }

  const getDeviceHistoryById = async () => {
    const res = await getDeviceDataById(item?.id, {
      roles: type === "staff" ? type : null,
    });
    setList(res?.data?.data?.LoginDeviceMaintain);
  };

  const updateLogoutStatus = async (id) => {
    const res = await updateloginStatusApi(id);

    console.log("res", res);
    getDeviceHistoryById();
  };
  const handleLogoutUser = async (user_id, login_device_detail) => {
    try {
      const data = { id: user_id, login_device_detail };
  
      const res = await logoutUser(data);

      if (res.status === 200) {
        setSession(null);
        dispatch({
          type: "LOGOUT",
        });
      } else {
        console.error("Logout failed:", res?.message);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Modal Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Logout Status</TableCell>
              <TableCell>Login Date & time</TableCell>
              <TableCell>Last active Date & time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item?.login_device_detail}</TableCell>
                <TableCell>
                  {!item?.log_out_status ? (
                    <p className="p-1 rounded-2 text-center bg-success text-white">
                      Logged In
                    </p>
                  ) : (
                    <p className="p-1 rounded-2 text-center bg-danger text-white">
                      Logged Out
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell>
                  <button
                    className="btn btn-success btn-sm rounded-2"
                    onClick={() =>
                      handleLogoutUser(
                        item?.users_id,
                        item?.login_device_detail
                      )
                    }
                  >
                    Logged Out
                  </button>
                  {/* <p onClick={() => updateLogoutStatus(item.id)}></p> */}
                </TableCell>

                <TableCell>{formatDate(item?.created_at)}</TableCell>
                <TableCell>{formatDate(item?.updated_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LoginDevices;
