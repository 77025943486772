import { DeleteOutline, SearchRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { AnalyticsWidgetSummary } from "src/sections/@dashboard/general/analytics";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { useAuthContext } from "src/auth/useAuthContext";
import Label from "src/components/label";
import constant from "src/Constant";
import ScannerBioDataUserCard from "src/sections/@dashboard/user/cards/ScannerBiodataUserCard";
import {
  getCityListAPI,
  getCountryListAPI,
  getDetailList,
  getHinduSectsByStateIdApi,
  getHinduSubSectsBySectIdApi,
  getStateListAPI,
  mobileCheckPy,
  selfAssignScannerData,
  subSectApi,
  updateScannerForm,
  loadWFHData,
  removeScannerTask,
  moveToData,
  staffAssignTaskReportData,
} from "src/service";
import FullScreenOptions from "src/components/settings/drawer/FullScreenOptions";

const disability = [
  { id: 1, name: "Normal" },
  { id: 2, name: "Some Medical Problem" },
  { id: 3, name: "Physically Challenged" },
];

const timingOption = [
  { label: "All", value: "all" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Before Yesterday", value: "beforeYesterday" },
  { label: "Last 3 Days", value: "lastThreeDays" },
  { label: "Last Week", value: "lastWeek" },
  { label: "Last Month", value: "lastMonth" },
  { label: "Last Year", value: "lastYear" },
];

const maritalStatus = [
  { id: 1, name: "Unmarried" },
  { id: 2, name: "Widow/widower" },
  { id: 3, name: "Divorce" },
  { id: 4, name: "Awiting divorce" },
];

const ViewWorkFromHomeStaffTask = () => {
  const { type, role } = useParams();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [filterDetail, setFilterDetail] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState("");

  const [zoom, setZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const [image, setImage] = useState({});
  const [todayUpdateReport, setTodayUpdateReport] = useState({});

  const [currentId, setCurrentId] = useState(0);
  const [pendingTask, setPendingTask] = useState(0);
  const [completedTask, setCompletedTask] = useState(0);
  const [totalAssignedTask, setTotalAssignedTask] = useState(0);
  const [mobileNumbers, setMobileNumbers] = useState([""]);
  const [cityList, setCityList] = useState([]);
  const [prefoundData, setPrefoundData] = useState({
    jain_data: [],
    hindu_data: [],
  });
  const [prefoundDataCount, setPrefoundDataCount] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [subSectList, setSubSectList] = useState([]);
  const [isNumberChecking, setIsNumberChecking] = useState(false);
  const [count, setCount] = useState({});
  const [reportPopup, setReportPopup] = useState(false);
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: 80 - 18 + 1 },
    (_, i) => currentYear - (18 + i)
  );
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    gender: "",
    profession: "",
    education: [],
    community: "",
    height: "",
    motherTongue: "",
    income: "",
    sub_community: "",
    manglik: "DONT KNOW",
    country: "India",
    state: "Madhya Pradesh",
    city: "",
    maritalStatus: "Unmarried",
    physicalStatus: "Normal",
    dob: "",
    familyStatus: "",
    age: "",
    gmail_id: "",
  });

  const scannerDataKey =
    role === "office" && type === "jain"
      ? "biodataDownloadUpdate"
      : role === "office" && type === "hindu"
      ? "hinduBiodataDownloadUpdate"
      : "ScannerData";

  const initialFilter = {
    role:
      role === "office"
        ? user?.sections?.[scannerDataKey]?.isAdmin
          ? "admin"
          : "staff"
        : user?.sections?.[scannerDataKey]?.WorkFromHomeStaff
        ? "WorkFromHomeStaff"
        : user?.sections?.[scannerDataKey]?.isAdmin
        ? "admin"
        : "staff",
    user_id: user?.id,
  };

  const [filter, setFilter] = useState(initialFilter);

  const handleZoomIn = () => setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1));
    if (zoom === 1) setPosition({ x: 0, y: 0 });
  };

  const handleDragStart = (e) => {
    if (zoom <= 1) return;
    setDragging(true);
    setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleDrag = (e) => {
    if (!dragging || zoom <= 1) return;

    const newX = e.clientX - startPosition.x;
    const newY = e.clientY - startPosition.y;

    const maxOffsetX = ((zoom - 1) * window.innerWidth) / 2;
    const maxOffsetY = ((zoom - 1) * window.innerHeight) / 2;

    setPosition({
      x: Math.min(Math.max(newX, -maxOffsetX), maxOffsetX),
      y: Math.min(Math.max(newY, -maxOffsetY), maxOffsetY),
    });
  };

  const handleDragEnd = () => setDragging(false);

  const getWorkFromHomeStaffTask = async () => {
    let obj = {
      user_id: user?.id,
      user_name: user?.nameEnglish,
      user_email: user?.email,
      assign_by: "Self",
      assign_quantity: 5,
    };
    try {
      const response = await selfAssignScannerData(type, obj);
      if (response?.status === 200) {
        loadData();
      } else {
        enqueueSnackbar(response?.data?.message || "Something went wrong!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error during self-assign scanner data:", error);
      enqueueSnackbar(
        "You have incomplete tasks. Please complete them before new assignments.",
        {
          variant: "error",
        }
      );
    }
  };

  const loadData = async () => {
    const obj = {
      user_id: user?.id,
      user_name: user?.nameEnglish,
      user_email: user?.email,
    };

    try {
      const response = await loadWFHData(type, obj);

      const {
        onTask,
        completed_task,
        pending_task,
        today_update_report,
        total_assign_task,
      } = response?.data?.data;

      setCompletedTask(completed_task);
      setPendingTask(pending_task);
      setTodayUpdateReport(today_update_report);
      setTotalAssignedTask(total_assign_task);

      if (onTask) {
        setMobileNumbers(onTask?.mobiles ? onTask?.mobiles?.split(",") : []);
        setCurrentId(onTask?.id);
        setImage(type === "jain" ? onTask.jain_image : onTask.hindu_image);
        setFormData({
          id: onTask?.id,
          name: onTask?.name,
          gender: onTask?.gender,
          profession: onTask?.employed_in,
          education: onTask?.education?.split(","),
          sect: onTask?.community,
          subSect: onTask?.sub_community,
          manglik: onTask?.manglik ? onTask?.manglik : "",
          country: onTask?.country ? onTask?.country : "",
          state: onTask?.state ? onTask?.state : "",
          city: onTask?.city,
          maritalStatus: onTask?.marital_status ? onTask?.marital_status : "",
          physicalStatus: onTask?.physical_status
            ? onTask?.physical_status
            : "",
          familyStatus: onTask?.familyStatus ? onTask?.familyStatus : "",
          dob: onTask?.dob === "-" ? 0 : onTask?.dob,
          height: onTask?.height,
          motherTongue: onTask?.mother_tongue,
          income: onTask?.income,
          description: onTask?.description,
          age: onTask?.age,
          gmail_id: onTask?.gmail_id,
        });
      } else {
        setFormData({
          id: "",
          name: "",
          gender: "",
          profession: "",
          education: [],
          community: "",
          height: "",
          motherTongue: "",
          income: "",
          sub_community: "",
          manglik: "",
          country: "",
          state: "",
          city: "",
          maritalStatus: "",
          physicalStatus: "",
          dob: "",
          familyStatus: "",
          description: "",
          age: "",
          gmail_id: "",
        });
      }

      // Handle string response as an error
      if (typeof response?.data?.data === "string") {
        enqueueSnackbar(response?.data?.data, { variant: "error" });
        return;
      }

      // Handle successful response here (if needed)
    } catch (error) {
      console.error("Error during self-assign scanner data:", error);
    }
  };

  const fetchStaffAssignTaskReport = async () => {
    try {
      const obj = {
        staff_id: user?.id,
        timing: filter?.staff_filter_timing,
        source_table: type == "jain" ? "jain_data" : "hindu_data",
        report_type: "update",
      };
      const response = await staffAssignTaskReportData(obj);
      // console.log(response);
      if (response?.status === 200) {
        const responseData = response?.data?.data[0] || [];
        setCount(responseData);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const getCountryList = useCallback(async () => {
    let res = await getCountryListAPI();
    setCountryList(res?.data?.data);
  }, []);

  useEffect(() => {
    getCountryList();
  }, [getCountryList]);

  const getStateList2 = useCallback(async () => {
    var filterDetails = await getDetailList();
    let stateList = await getStateListAPI("India");
    setFilterDetail({
      ...filterDetails?.data?.data,
      stateList: stateList?.data.data,
      sects: type === "hindu" ? [] : filterDetails?.data?.data?.sects,
    });
  }, [type]);

  useEffect(() => {
    getStateList2();
  }, [getStateList2]);

  const handleReport = async () => {
    setReportPopup(true);
  };

  const getCityList = async (val) => {
    let res = await getCityListAPI(val);
    setCityList(res?.data?.data);
  };

  const getHinduCommunityList = async (val) => {
    const stateId =
      filterDetail?.stateList?.find((item) => item.english === val)?.id || null;
    if (!stateId) return;
    let res = await getHinduSectsByStateIdApi(stateId);
    let sect = [];
    res?.data?.data.forEach((item) => {
      sect.push({
        id: item.id,
        english: item.name_english,
        hindi: item.name_english,
        master_status: true,
      });
    });
    setFilterDetail({ ...filterDetail, sects: sect });
  };

  const getHinduSubCommunity = async (sectId) => {
    const sect =
      filterDetail?.sects?.find((item) => item.english === sectId)?.id || null;
    const res = await getHinduSubSectsBySectIdApi(sect);
    let subCommunity = [];
    res?.data?.data?.forEach((item) => {
      subCommunity.push({
        id: item.id,
        english: item.name_english,
        hindi: item.name_english,
      });
    });
    setSubSectList(subCommunity);
  };

  const getSubSect = async (sub) => {
    let res = await subSectApi(sub);
    if (res?.data?.status) {
      setSubSectList(res?.data?.data);
    }
  };

  useEffect(() => {
    if (formData?.community) {
      if (type === "hindu") getHinduSubCommunity(formData?.community);
      if (type === "jain") getSubSect(formData?.community);
    }
  }, [formData?.community]);

  useEffect(() => {
    if (formData.state) {
      getCityList(formData.state);
      if (type === "hindu") getHinduCommunityList(formData.state);
    }
  }, [formData.state, type]);

  const checkMobileNumber = async (number) => {
    setIsNumberChecking(true);
    try {
      const res = await mobileCheckPy(number);
      if (res?.data?.status) {
        setPrefoundData(res?.data?.data?.result);
        const count =
          res?.data?.data?.result?.jain_data?.length +
          res?.data?.data?.result?.hindu_data?.length;
        setPrefoundDataCount(count);
      }
    } catch (error) {
      console.error("error ::::", error);
    } finally {
      setIsNumberChecking(false);
    }
  };

  useEffect(() => {
    if (
      mobileNumbers.length > 0 &&
      mobileNumbers[0] &&
      mobileNumbers[0] !== "-"
    ) {
      const timer = setTimeout(() => {
        checkMobileNumber(mobileNumbers[0]);
      }, 1200);

      return () => clearTimeout(timer);
    }
  }, [mobileNumbers?.length, mobileNumbers]);

  const addMobileField = () => {
    setMobileNumbers([...mobileNumbers, ""]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleEducation = (event, value) => {
    setFormData((prev) => ({
      ...prev,
      education: value,
    }));
    setErrors((prev) => ({
      ...prev,
      education: "", // Clear the error for education
    }));
  };

  const handleChange = (index, value) => {
    const updatedNumbers = [...mobileNumbers];
    updatedNumbers[index] = value;
    setMobileNumbers(updatedNumbers);
  };

  const removeMobileField = (index) => {
    const updatedNumbers = mobileNumbers.filter((_, i) => i !== index);
    setMobileNumbers(updatedNumbers);
  };

  const handleSubmit = async (submit_type) => {
    try {
      const formObj = {
        id: formData?.id,
        name: formData?.name,
        gender: formData?.gender,
        dob: formData?.dob,
        education: formData?.education?.join(","),
        marital_status: formData?.maritalStatus,
        physical_status: formData?.physicalStatus,
        family_status: formData?.familyStatus,
        community: formData?.community,
        sub_community: formData?.sub_community,
        manglik: formData?.manglik,
        country: formData?.country,
        state: formData?.state,
        city: formData?.city,
        height: formData?.height,
        mother_tongue: formData?.motherTongue,
        income: formData?.income,
        employed_in: formData?.profession,
        mobiles: mobileNumbers?.join(","),
        update_staff_id: user?.id,
        update_staff_name: user?.nameEnglish,
        update_staff_email: user?.email,
        submit_type: submit_type,
        age: formData?.age,
        gmail_id: formData?.gmail_id,
        section: "work_from_home",
      };

      const newErrors = {};
      if (!formObj.name?.trim()) {
        newErrors.name = "Name is required";
      }

      if (!formObj.education?.trim()) {
        newErrors.education = "Education is required";
      }
      if (!formObj.state?.trim()) {
        newErrors.state = "State is required";
      }
      if (!formObj.city?.trim()) {
        newErrors.city = "City is required";
      }
      if (!formObj.community?.trim()) {
        newErrors.community = "Community is required";
      }
      if (!formObj.employed_in?.trim()) {
        newErrors.profession = "Profession is required";
      }
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
      const response = await updateScannerForm(formObj, type);

      if (response?.status === 200) {
        loadData();
        setFormData({
          id: "",
          name: "",
          gender: "",
          profession: "",
          education: [],
          community: "",
          height: "",
          motherTongue: "",
          income: "",
          sub_community: "",
          manglik: "DONT KNOW",
          country: "India",
          state: "Madhya Pradesh",
          city: "",
          maritalStatus: "Unmarried",
          physicalStatus: "Normal",
          dob: "",
          familyStatus: "",
        });
        enqueueSnackbar("Biodata Updated Successfully!");
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleMoveToData = async (submit_type) => {
    try {
      const formObj = {
        id: [formData?.id],
        user_id: user?.id,
        submit_type: submit_type,
        section: "work_from_home",
      };

      const response = await moveToData(type, formObj);
      if (response?.status === 200) {
        loadData();
        enqueueSnackbar("Moved Data Successfully!");
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRemove = async () => {
    try {
      const formObj = {
        id: formData?.id,
        user_id: user?.id,
        user_name: user?.nameEnglish,
        notes: reason,
        status: 1,
        section: "work_from_home",
      };
      const response = await removeScannerTask(formObj, type);
      if (response?.status === 200) {
        loadData();
        setOpenModal(false);
        setReason("");
        enqueueSnackbar("Removed Task Successfully!");
        return;
      }
    } catch (error) {
      console.error("error ::::", error);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleDelete = async () => {
    // try {
    //   const res =
    //     type === "hindu"
    //       ? await deleteBiodataHinduPy({ ids: [id] })
    //       : await deleteBiodataPy({ ids: [id] });
    //   enqueueSnackbar(res?.data?.message);
    //   navigate(-1);
    // } catch (error) {
    //   enqueueSnackbar("Something went wrong", { variant: "error" });
    // }
  };

  const handleWheelZoom = (event) => {
    // event.preventDefault();
    const zoomFactor = 0.1;

    setZoom((prevZoom) => {
      const newZoom = Math.max(
        1,
        Math.min(3, prevZoom - event.deltaY * zoomFactor * 0.01)
      );

      if (newZoom === 1) {
        setPosition({ x: 0, y: 0 });
      }

      return newZoom;
    });
  };

  return (
    <>
      <Helmet>
        <title>{type === "jain" ? "JAIN" : "HINDU"} : Biodata</title>
      </Helmet>
      <Box sx={{ padding: "0px 20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Heading */}
          <Typography variant="h4">
            {type === "jain" ? "Jain" : "Hindu"} Biodata Details Updating
          </Typography>

          {/* View Report Button */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <Button variant="contained" sx={{ mr: 1, my: 2 }}>
              Pending ({pendingTask})
            </Button>

            <Button variant="contained" sx={{ mr: 1, my: 2 }} color="success">
              Completed Task ({completedTask})
            </Button>

            {/* <Button variant="contained" sx={{ mr: 1, my: 2 }} >
          Approved Task ({todayUpdateReport?.approve_task})
        </Button>

        <Button variant="outlined" sx={{ mr: 1, my: 2 }} >
          Remove Task ({todayUpdateReport?.remove_task})
        </Button>

        {type == "jain" ? (
          <Button variant="contained" sx={{ mr: 1, my: 2 }} >
            Move To Hindu ({todayUpdateReport?.move_to_hindu})
          </Button>
        ) : (
          <Button variant="contained" sx={{ mr: 1, my: 2 }} >
            Move To Jain ({todayUpdateReport?.move_to_jain})
          </Button>
        )} */}

            <Button variant="outlined" sx={{ mr: 1, my: 2 }} color="success">
              Id ({currentId})
            </Button>

            <Button
              variant="contained"
              sx={{ mr: 1, my: 2 }}
              color="success"
              onClick={getWorkFromHomeStaffTask}
            >
              Assign Task ({totalAssignedTask})
            </Button>
            <div>
              <IconButton onClick={handleZoomIn} aria-label="Zoom In">
                <ZoomInIcon sx={{ fontSize: "35px", mt: "4px" }} />
              </IconButton>
              <IconButton onClick={handleZoomOut} aria-label="Zoom Out">
                <ZoomOutIcon sx={{ fontSize: "35px", mt: "4px" }} />
              </IconButton>
            </div>
            <div>
              <FullScreenOptions
                style={{
                  fontSize: "10px",
                  padding: "10px",
                  height: "10px !important",
                }}
              />
            </div>
          </Box>
          <Button
            variant="contained"
            size="small"
            sx={{ float: "right" }}
            onClick={handleReport}
          >
            View Report
          </Button>
        </Box>

        {formData?.id && (
          <Grid container spacing={2}>
            {/* image */}
            <Grid item md={5} lg={7} xs={12} padding={2}>
              <Stack
                spacing={2}
                direction={"column"}
                sx={{ position: "relative", overflow: "hidden" }}
                // onWheel={handleWheelZoom}
              >
                <div
                  style={{
                    width: "100%",
                    maxHeight: "100%",
                    transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                    transformOrigin: "center center",
                    cursor: dragging ? "grabbing" : "grab",
                    transition: dragging ? "none" : "transform 0.3s ease",
                  }}
                  onMouseDown={handleDragStart}
                  onMouseMove={handleDrag}
                  onMouseUp={handleDragEnd}
                  onMouseLeave={handleDragEnd}
                >
                  <img
                    src={`${constant.scannerUrl}/images/${image?.image}`}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                    draggable={false}
                  />
                </div>
              </Stack>
            </Grid>

            {/* prefetchedData */}
            <Grid item md={3} lg={2} xs={12}>
              <Stack spacing={2} direction={"column"} alignItems="center">
                <p style={{ color: "blue", fontWeight: "bold" }}>
                  Description{" "}
                </p>
                <span>
                  {formData?.description ? formData?.description : "N/A"}
                </span>
              </Stack>
            </Grid>

            {/* form */}
            <Grid item md={4} lg={3} xs={12}>
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  maxHeight: "450px",
                  overflowY: "auto",
                  marginBottom: "30px",
                }}
              >
                {mobileNumbers.map((number, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      position: "relative",
                    }}
                  >
                    <TextField
                      label="Mobile Number"
                      variant="outlined"
                      value={number}
                      onChange={(e) => handleChange(index, e.target.value)}
                      fullWidth
                    />
                    {index === 0 && (
                      <span
                        style={{
                          position: "absolute",
                          right: 5,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Label sx={{ mx: 1 }}>
                          {isNumberChecking ? (
                            <CircularProgress size={20} />
                          ) : (
                            prefoundDataCount
                          )}
                        </Label>
                        <IconButton
                          color="error"
                          sx={{
                            border: "1px solid red",
                            borderRadius: "50%",
                            padding: "2px 5px",
                          }}
                          onClick={() =>
                            checkMobileNumber(mobileNumbers[index])
                          }
                        >
                          <SearchRounded />
                        </IconButton>
                      </span>
                    )}

                    {index > 0 && (
                      <IconButton
                        color="error"
                        onClick={() => removeMobileField(index)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    )}
                  </Box>
                ))}

                <Button
                  variant="contained"
                  color="inherit"
                  onClick={addMobileField}
                >
                  Add Number
                </Button>

                <TextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={formData?.name}
                  onChange={handleInputChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  fullWidth
                />
                <TextField
                  label="Mail Id"
                  type="email"
                  name="gmail_id"
                  variant="outlined"
                  value={formData?.gmail_id}
                  onChange={handleInputChange}
                  fullWidth
                />

                <TextField
                  select
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  label="Gender"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </TextField>

                <Autocomplete
                  freeSolo
                  value={formData.profession}
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "profession", value: newValue },
                    })
                  }
                  options={
                    filterDetail?.employedIn?.english?.map(
                      (opt) => opt?.label
                    ) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Profession"
                      name="profession"
                      error={!!errors.profession}
                      helperText={errors.profession}
                    />
                  )}
                />

                <Autocomplete
                  freeSolo
                  multiple
                  options={
                    filterDetail?.education?.map((opt) => opt?.name_english) ||
                    []
                  }
                  value={
                    Array.isArray(formData?.education)
                      ? formData?.education
                      : []
                  }
                  onChange={(event, value) => handleEducation(event, value)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} style={{ marginRight: 8 }} />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Education"
                      placeholder="Select education"
                      error={!!errors.education}
                      helperText={errors.education}
                    />
                  )}
                />

                <TextField
                  select
                  name="manglik"
                  value={formData.manglik}
                  onChange={handleInputChange}
                  label="Manglik"
                >
                  {filterDetail?.doshList?.english?.map((opt) => (
                    <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  label="Country"
                  error={!!errors.country}
                  helperText={errors.country}
                >
                  {countryList?.map((opt) => (
                    <MenuItem value={opt?.name_english}>
                      {opt?.name_english}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  error={!!errors.state}
                  helperText={errors.state}
                  label="State"
                >
                  {filterDetail?.stateList?.map((opt) => (
                    <MenuItem value={opt?.english}>{opt?.english}</MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  freeSolo
                  value={formData.city}
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "city", value: newValue },
                    })
                  }
                  options={cityList?.map((opt) => opt?.name_english) || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      name="city"
                      error={!!errors.city}
                      helperText={errors.city}
                    />
                  )}
                />

                <Autocomplete
                  freeSolo
                  value={formData.community}
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "community", value: newValue },
                    })
                  }
                  options={
                    filterDetail?.sects?.map((opt) => opt?.english) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Community"
                      name="community"
                      error={!!errors.community}
                      helperText={errors.community}
                    />
                  )}
                />

                <Autocomplete
                  freeSolo
                  value={formData.sub_community}
                  onChange={(event, newValue) =>
                    handleInputChange({
                      target: { name: "sub_community", value: newValue },
                    })
                  }
                  options={subSectList?.map((opt) => opt?.english) || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Sub Community"
                      name="sub_community"
                    />
                  )}
                />

                <TextField
                  select
                  name="maritalStatus"
                  value={formData.maritalStatus}
                  onChange={handleInputChange}
                  label="Marital Status"
                >
                  {maritalStatus?.map((opt) => (
                    <MenuItem value={opt?.name}>{opt?.name}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="physicalStatus"
                  value={formData.physicalStatus}
                  onChange={handleInputChange}
                  label="Physical Status"
                >
                  {disability?.map((opt) => (
                    <MenuItem value={opt?.name}>{opt?.name}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="motherTongue"
                  value={formData.motherTongue}
                  onChange={handleInputChange}
                  label="Mother Tongue"
                >
                  {filterDetail?.motherTongueList?.english?.map((opt) => (
                    <MenuItem value={opt?.label}>{opt?.label}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="height"
                  value={formData.height}
                  onChange={handleInputChange}
                  label="Height"
                >
                  {filterDetail?.height?.english?.map((opt) => (
                    <MenuItem value={opt?.label}>{opt?.label}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="income"
                  value={formData.income}
                  onChange={handleInputChange}
                  label="Income"
                >
                  {filterDetail?.incomeList?.english?.map((opt) => (
                    <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
                  ))}
                </TextField>

                <Grid item xs={12}>
                  <TextField
                    select
                    label="Age"
                    name="age"
                    variant="outlined"
                    value={formData?.age || ""}
                    onChange={handleInputChange}
                    fullWidth
                  >
                    {Array.from({ length: 38 }, (_, i) => 18 + i).map((age) => (
                      <MenuItem key={age} value={age}>
                        {age}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <TextField
                  select
                  label="Year of Birth"
                  name="dob"
                  variant="outlined"
                  value={formData?.dob || ""}
                  onChange={handleInputChange}
                  fullWidth
                >
                  {yearOptions.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  name="familyStatus"
                  value={formData.familyStatus}
                  onChange={handleInputChange}
                  label="Family Status"
                >
                  {filterDetail?.familyStatusList?.english?.map((opt) => (
                    <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
                  ))}
                </TextField>
              </Box>
              <Grid container spacing={1.5} sx={{ paddingBottom: "10px" }}>
                <Grid item xs={4}>
                  <Button
                    onClick={() => handleSubmit("update")}
                    variant="contained"
                    fullWidth
                    color="primary"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Add Details
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  {type === "jain" ? (
                    <Button
                      onClick={() => handleMoveToData("move_to_hindu")}
                      variant="contained"
                      color="success"
                      fullWidth
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Move To Hindu
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleMoveToData("move_to_jain")}
                      variant="contained"
                      color="success"
                      fullWidth
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Move To Jain
                    </Button>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <Button
                    onClick={handleOpen}
                    variant="contained"
                    color="error"
                    fullWidth
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Dialog open={openModal} onClose={handleClose}>
          <DialogTitle>Confirm Remove</DialogTitle>
          <DialogContent>
            <TextField
              label="Reason"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleRemove} variant="contained" color="error">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* cards */}
        <Grid container spacing={2}>
          {prefoundData?.jain_data?.length > 0 &&
            prefoundData?.jain_data?.map((bioData) => {
              return (
                <Grid item lg={4} key={bioData?.id} md={6} sm={6} xs={12}>
                  <ScannerBioDataUserCard bioData={bioData} type="jain" />
                </Grid>
              );
            })}
          {prefoundData?.hindu_data?.length > 0 &&
            prefoundData?.hindu_data?.map((bioData) => {
              return (
                <Grid item lg={4} key={bioData?.id} md={6} sm={6} xs={12}>
                  <ScannerBioDataUserCard bioData={bioData} type="hindu" />
                </Grid>
              );
            })}
        </Grid>

        <Dialog
          open={reportPopup}
          onClose={() => setReportPopup(false)}
          maxWidth={"lg"}
          fullWidth
        >
          <DialogTitle> {user?.nameEnglish} Performance Report </DialogTitle>
          <DialogContent>
            <Card>
              <CardContent sx={{ pb: 3 }}>
                <Grid container spacing={2}>
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>Timing</InputLabel>
                        <Select
                          value={filter?.staff_filter_timing || ""}
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              staff_filter_timing: e.target.value,
                            })
                          }
                        >
                          {timingOption.map((item) => (
                            <MenuItem key={item?.value} value={item?.value}>
                              {item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={fetchStaffAssignTaskReport}
                      >
                        Apply Filter
                      </Button>
                    </Grid>
                  </>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Total Assign Task"
                      total={count?.total_assign_task}
                      color="secondary"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Pending Task"
                      total={count?.pending_task}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Total Update Task"
                      total={count.update_task}
                      color="info"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Total Approve Task"
                      total={count.approve_task}
                      color="success"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <AnalyticsWidgetSummary
                      title="Total Remove Task"
                      total={count.remove_task}
                    />
                  </Grid>
                  {type == "hindu" && (
                    <Grid item xs={12} sm={6} md={2}>
                      <AnalyticsWidgetSummary
                        title="Move To Jain"
                        total={count?.move_to_jain}
                        color="warning"
                      />
                    </Grid>
                  )}
                  {type == "jain" && (
                    <Grid item xs={12} sm={6} md={2}>
                      <AnalyticsWidgetSummary
                        title="Move To Hindu"
                        total={count?.move_to_hindu}
                        color="warning"
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setReportPopup(false)} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ViewWorkFromHomeStaffTask;
