import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Button,
  TextField,
  Autocomplete,
  CardContent,
  Typography,
  CardHeader,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
} from "@mui/material";

import { staffNameApi, staffAssignTaskReportData } from "src/service";
import { useAuthContext } from "src/auth/useAuthContext";
import { AnalyticsWidgetSummary } from "src/sections/@dashboard/general/analytics";

const timingOption = [
  { label: "All", value: "all" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Before Yesterday", value: "beforeYesterday" },
  { label: "Last 3 Days", value: "lastThreeDays" },
  { label: "Last Week", value: "lastWeek" },
  { label: "Last Month", value: "lastMonth" },
  { label: "Last Year", value: "lastYear" },
];

const sourceTable = [
  { label: "All", value: "all" },
  { label: "Jain", value: "jain_data" },
  { label: "Hindu", value: "hindu_data" },
];

const reportType = [
  { label: "All", value: "all" },
  { label: "Upload", value: "upload" },
  { label: "Update", value: "update" },
];

export default function ViewStaffAssignTaskReport() {
  const contxt = useAuthContext();
  const [staffName, setStaffName] = useState([]);
  const [permission, setPermission] = useState(null);
  const [filter, setFilter] = useState({
    role: contxt?.user?.sections?.ScannerData?.WorkFromHomeStaff
      ? "WorkFromHomeStaff"
      : contxt?.user?.sections?.ScannerData?.isAdmin
      ? "admin"
      : "staff",
    user_id: contxt?.user?.id,
  });

  const [count, setCount] = useState([]);
  const [staffNameMap, setStaffNameMap] = useState({});

  useEffect(() => {
    fetchStaffName();
    setPermission(contxt?.user?.sections?.ScannerData);
  }, []);

  // const fetchStaffName = async () => {
  //   try {
  //     const res = await staffNameApi();
  //     setStaffName(res?.data?.data || []);
  //   } catch (error) {
  //     console.error("Error fetching staff names:", error);
  //   }
  // };

  const fetchStaffName = async () => {
    try {
      const res = await staffNameApi();
      const staffData = res?.data?.data || [];
      setStaffName(res?.data?.data || []);
      // Create a map of user_id to nameEnglish
      const staffNameMap = staffData.reduce((acc, staff) => {
        acc[staff.id] = staff.nameEnglish; // Map user_id to nameEnglish
        return acc;
      }, {});

      setStaffNameMap(staffNameMap); // Set the staffNameMap for lookups
    } catch (error) {
      console.error("Error fetching staff names:", error);
    }
  };

  console.log(count);
  const fetchStaffAssignTaskReport = async () => {
    try {
      const obj = {
        staff_id: filter?.staff_filter_id,
        timing: filter?.staff_filter_timing,
        source_table: filter?.source_table,
        report_type: filter?.report_type,
      };

      const response = await staffAssignTaskReportData(obj);

      const responseData = response?.data?.data || [];

      if (response?.status === 200) {
        setCount(responseData);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Scanner Bio Data Users : Master Admin</title>
      </Helmet>
      <Card>
        <CardHeader
          title={<Typography variant="h4">Staff Report</Typography>}
        />
        <CardContent sx={{ pb: 3 }}>
          <Grid container spacing={2}>
            {/* Staff Filter */}
            <Grid item xs={12} sm={6} md={5}>
              <Autocomplete
                multiple
                options={staffName || []} // Options from staffName
                getOptionLabel={(option) => option.nameEnglish} // Show name in dropdown
                value={
                  staffName?.filter((item) =>
                    filter?.staff_filter_id?.includes(item.id)
                  ) || []
                } // Selected values
                onChange={(event, newValue) => {
                  setFilter({
                    ...filter,
                    staff_filter_id: newValue.map((item) => item.id), // Store selected IDs
                  });
                }}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} style={{ marginRight: 8 }} />
                    {option.nameEnglish}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Staff"
                    placeholder="Select Staff"
                  />
                )}
              />
            </Grid>

            {permission?.isAdmin && (
              <>
                {/* Timing Filter */}
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Timing</InputLabel>
                    <Select
                      value={filter?.staff_filter_timing || ""}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          staff_filter_timing: e.target.value,
                        })
                      }
                    >
                      {timingOption.map((item) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Type Filter */}
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={filter?.source_table || ""}
                      onChange={(e) =>
                        setFilter({ ...filter, source_table: e.target.value })
                      }
                    >
                      {sourceTable.map((item) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Report Type Filter */}
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Report Type</InputLabel>
                    <Select
                      value={filter?.report_type || ""}
                      onChange={(e) =>
                        setFilter({ ...filter, report_type: e.target.value })
                      }
                    >
                      {reportType.map((item) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Apply Filter Button */}
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={fetchStaffAssignTaskReport}
                  >
                    Apply Filter
                  </Button>
                </Grid>
              </>
            )}
          </Grid>

          {/* Upload Report */}
          {filter?.report_type === "upload" && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {count.map((item, index) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={`update-task-${index}`}
                    >
                      <Typography variant="h6" sx={{ my: 2 }}>
                        {staffNameMap[item.user_id] || "Unknown"} Upload Report
                        ({item.user_id})
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AnalyticsWidgetSummary
                        title="Total Upload Data"
                        total={item.upload_data}
                        color="secondary"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AnalyticsWidgetSummary
                        title="Total Failed To Upload Data"
                        total={item.failed_to_upload}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AnalyticsWidgetSummary
                        title="Total Duplicate Data"
                        total={item.duplicate_data}
                        color="warning"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AnalyticsWidgetSummary
                        title="Total Unique Data"
                        total={item.unique_data}
                        color="success"
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          )}

          {/* Update Report */}
          {filter?.report_type === "update" && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {count.map((item, index) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={`update-task-${index}`}
                    >
                      <Typography variant="h6" sx={{ my: 2 }}>
                        {staffNameMap[item.user_id] || "Unknown"} Update Report
                        ({item.user_id})
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AnalyticsWidgetSummary
                        title="Total Assign Task"
                        total={item.total_assign_task}
                        color="secondary"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AnalyticsWidgetSummary
                        title="Total Update Task"
                        total={item.update_task}
                        color="info"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AnalyticsWidgetSummary
                        title="Total Approve Task"
                        total={item.approve_task}
                        color="success"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <AnalyticsWidgetSummary
                        title="Total Remove Task"
                        total={item.remove_task}
                      />
                    </Grid>
                    {filter?.source_table === "hindu_data" && (
                      <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                          title="Move To Jain"
                          total={item?.move_to_jain}
                          color="warning"
                        />
                      </Grid>
                    )}
                    {filter?.source_table === "jain_data" && (
                      <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                          title="Move To Hindu"
                          total={item?.move_to_hindu}
                          color="warning"
                        />
                      </Grid>
                    )}
                    {filter?.source_table === "all" && (
                      <>
                        <Grid item xs={12} sm={6} md={3}>
                          <AnalyticsWidgetSummary
                            title="Move To Jain"
                            total={item?.move_to_jain}
                            color="warning"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <AnalyticsWidgetSummary
                            title="Move To Hindu"
                            total={item?.move_to_hindu}
                            color="warning"
                          />
                        </Grid>
                      </>
                    )}
                  </>
                ))}
              </Grid>
            </>
          )}

          {/* Combined Report */}
          {filter?.report_type === "all" && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {count.map((item, index) => (
                  <>
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        key={`update-task-${index}`}
                      >
                        <Typography variant="h6" sx={{ my: 2 }}>
                          {staffNameMap[item.user_id] || "Unknown"} Update
                          Report ({item.user_id})
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={`update-task-${index}`}
                      >
                        <AnalyticsWidgetSummary
                          title="Total Assign Task"
                          total={item.total_assign_task}
                          color="secondary"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={`update-task-${index}`}
                      >
                        <AnalyticsWidgetSummary
                          title="Total Update Task"
                          total={item.update_task}
                          color="info"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={`update-task-${index}`}
                      >
                        <AnalyticsWidgetSummary
                          title="Total Approve Task"
                          total={item.approve_task}
                          color="success"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={`update-task-${index}`}
                      >
                        <AnalyticsWidgetSummary
                          title="Total Remove Task"
                          total={item.remove_task}
                        />
                      </Grid>
                      {filter?.source_table === "hindu_data" && (
                        <Grid item xs={12} sm={6} md={3}>
                          <AnalyticsWidgetSummary
                            title="Move To Jain"
                            total={item?.move_to_jain}
                            color="warning"
                          />
                        </Grid>
                      )}
                      {filter?.source_table === "jain_data" && (
                        <Grid item xs={12} sm={6} md={3}>
                          <AnalyticsWidgetSummary
                            title="Move To Hindu"
                            total={item?.move_to_hindu}
                            color="warning"
                          />
                        </Grid>
                      )}
                      {filter?.source_table === "all" && (
                        <>
                          <Grid item xs={12} sm={6} md={3}>
                            <AnalyticsWidgetSummary
                              title="Move To Jain"
                              total={item?.move_to_jain}
                              color="warning"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AnalyticsWidgetSummary
                              title="Move To Hindu"
                              total={item?.move_to_hindu}
                              color="warning"
                            />
                          </Grid>
                        </>
                      )}
                    </>
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        key={`update-task-${index}`}
                      >
                        <Typography variant="h6" sx={{ my: 2 }}>
                          {staffNameMap[item.user_id] || "Unknown"} Upload
                          Report ({item.user_id})
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={`upload-data-${index}`}
                      >
                        <AnalyticsWidgetSummary
                          title="Total Upload Data"
                          total={item.upload_data}
                          color="secondary"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={`upload-data-${index}`}
                      >
                        <AnalyticsWidgetSummary
                          title="Total Failed To Upload Data"
                          total={item.failed_to_upload}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={`upload-data-${index}`}
                      >
                        <AnalyticsWidgetSummary
                          title="Total Duplicate Data"
                          total={item.duplicate_data}
                          color="warning"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={`upload-data-${index}`}
                      >
                        <AnalyticsWidgetSummary
                          title="Total Unique Data"
                          total={item.unique_data}
                          color="success"
                        />
                      </Grid>
                    </>
                  </>
                ))}
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}
