import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Tooltip,
  Button,
  TextField,
  CardHeader,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import {
  updateOfflineUsers,
  deleteOfflineUsers,
  updateIncomingUsers,
} from "src/service";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Iconify from "../../../../components/iconify";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { useSnackbar } from "../../../../components/snackbar";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAuthContext } from "src/auth/useAuthContext";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import Image from "../../../../components/image";
import constant from "src/Constant";
import blankUSer from "../../../../assets/images/blank-profile-picture-973460__340.webp";
import StaffToPayNewUser from "src/pages/UserFormData/StaffToPayNewUser";
// ----------------------------------------------------------------------

const ratingArray = [
  { key: 4, title: "A=80-100%" },
  { key: 3, title: "B=40-80%" },
  { key: 2, title: "C=1-40%" },
  { key: 1, title: "D=0%" },
];

export default function IncomingCard({
  getPendingRavcListData,
  selectedId,
  handleToggleAll,
  bioData,
  setUpdate,
  setShowUpdate,
  getIncomingCallData,
  update,
  goToTop,
  getOffineUsers,
  filterDetail,
}) {
  const contxt = useAuthContext();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [costModal, setCostModal] = useState(false);
  const [mobileModel, setMobileModel] = useState(false);
  const [noteModalValue, setNoteModalValue] = useState("");
  const [costValue, setCostValue] = useState("");
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const [newRow, setNewRow] = useState(bioData);
  const [mobileValue, setMobileValue] = useState(newRow?.mobile || "");
  const [item, setItem] = useState({ item: update });
  const [note, setNote] = useState([]);
  const [cost, setCost] = useState([]);
  const [date, setDate] = useState([]);
  const [open, setOpen] = useState(false);

  const sections = [
    { id: "biodata_entries", label: "Biodata" },
    { id: "new_offline_user_two", label: "Offline" },
    { id: "whatsapp_response", label: "Whatsapp Response" },
    { id: "paid_promotion", label: "Pending" },
    { id: "social_media", label: "Social Media" },
    { id: "group_service", label: "Group Service" },
    { id: "online_user", label: "Online" },
  ];

  const availableSections = sections
    .filter(
      (section) =>
        newRow[`${section.id}_id`] !== null && newRow[`${section.id}_id`] !== 0
    )
    .map((section) => section.label);

  const maskNumber = (phoneNumber) => {
    const number = contxt?.maskPhoneNumber(phoneNumber);
    if (number) return number;
  };

  useEffect(() => {
    setItem({ item: update });
    if (update?.notes) setNote(update?.notes);
    if (update?.final_cost) setCost(update?.final_cost);
  }, [update]);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleCopyMobile = (item) => {
    copy(item);
    enqueueSnackbar("Copied!");
  };
  const handleCopyName = () => {
    copy(newRow.name);
    enqueueSnackbar("Copied!");
  };

  const styles = {
    smallNormalText: {
      marginTop: "0px",
      fontWeight: "normal",
      color: "rgb(105, 105, 105)",
      fontSize: 12.5,
      whiteSpace: "nowrap",
    },
  };

  // const ratingArray = [
  //     { key: 0, title: "0" },
  //     { key: 1, title: "1" },
  //     { key: 2, title: "2" },
  //     { key: 3, title: "3" },
  //     { key: 4, title: "4" },
  //     { key: 5, title: "5" },
  //     // { key: 6, title: "W " },
  //     { key: 7, title: "S" },
  //     { key: 8, title: "L" },

  // ]

  const onStatus = async (obj) => {
    obj.id = newRow?.id;
    let res = await updateOfflineUsers(obj.id, obj);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
    } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    setNewRow({ ...newRow, ...obj });
  };

  const onDelete = async () => {
    let obj = {
      id: bioData?.id,
    };
    try {
      let res = await deleteOfflineUsers(obj, bioData?.id);

      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.message);
        getOffineUsers();
      } else {
        enqueueSnackbar(res?.data?.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      enqueueSnackbar("An error occurred while deleting the user.", {
        variant: "error",
      });
    }
  };
  // send whatsapp msg
  const whatsappNow = (mobile) => {
    window.location.href = "https://wa.me/91" + `${mobile}`;
  };
  function callNow(mobile) {
    window.location.href = `tel:${mobile}`;
  }

  useEffect(() => {
    currentDate();
  }, []);

  const handleClose = () => {
    setOpen(!open);
    getIncomingCallData();
  };

  const currentDate = () => {
    let today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    setDate(today);
  };

  const handleProfileDone = async () => {
    let obj = {
      // verify_process: "Done",
      id: newRow?.id,
      is_online_user: 1,
    };
    const res = await updateIncomingUsers(obj);
    if (res?.data?.status) {
      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.message);
      } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    }
  };

  return (
    <div
      className={`${
        newRow?.OnlineUserShadiAndDeletedIncoming
          ? "offlineCard-Isonline"
          : "offlineCard"
      } m-2`}
    >
      {
        newRow?.OnlineUserShadiAndDeletedIncoming &&
        newRow?.OnlineUserShadiAndDeletedIncoming?.deleted_at === null &&
        newRow?.OnlineUserShadiAndDeletedIncoming?.shadi_done === null ? (
          <div className="goldenBadge">
            <Iconify icon="entypo:shield" />
            On App
          </div>
        ) : newRow?.OnlineUserShadiAndDeletedIncoming?.deleted_at &&
          newRow?.OnlineUserShadiAndDeletedIncoming?.shadi_done === null ? (
          <div className="goldenBadge" style={{ color: "#FF3030" }}>
            <Iconify
              icon="material-symbols:close-small"
              style={{
                background: "#FF3030",
                color: "#fff",
                borderRadius: "50%",
              }}
            />
            Deleted
          </div>
        ) : newRow?.OnlineUserShadiAndDeletedIncoming?.shadi_done ? (
          <div className="goldenBadge" style={{ color: "#32BE8F" }}>
            <Iconify
              icon="material-symbols:check-small"
              style={{
                background: "#32BE8F",
                color: "#fff",
                borderRadius: "50%",
              }}
            />
            Shadi Done
          </div>
        ) : null // Add this to handle other cases
      }
      <div
        className="displayBtwPro primaryBg light"
        style={{
          padding: "8px",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <div className={`${newRow?.is_online_user ? "ms-4" : ""}`}>
          {newRow?.id && (
            <Typography
              className={`${newRow?.is_online_user ? "ms-4" : ""}`}
              variant="subtitle1"
            >
              {" "}
              #{newRow?.id} &nbsp;
            </Typography>
          )}
          {newRow?.name && (
            <Typography
              variant="subtitle1"
              sx={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                handleCopyName(newRow?.name);
              }}
            >
              <AccountCircleIcon
                className="fs-16"
                style={{ marginRight: "5px" }}
              />
              {newRow?.name} <ContentCopyOutlinedIcon className="fs-16 ms-2" />
            </Typography>
          )}
        </div>
        <div className="d-flex align-items-center">
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleToggleAll(newRow?.id)}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              background: selectedId?.includes(newRow?.id) ? "#fff" : "none",
              border: "1px solid #fff",
              borderRadius: "5px",
              color: selectedId?.includes(newRow?.id) ? "#32be8f" : "#fff",
            }}
          >
            {selectedId === newRow?.id ? "Unselect" : "Select"}
          </Button>
          {contxt?.user?.sections?.offlineUsers?.update && (
            <EditNoteOutlinedIcon
              onClick={() => {
                setUpdate({ ...newRow });
                setMobileModel(true);
              }}
            />
          )}{" "}
        </div>
      </div>
      <div style={{ textAlign: "end" }}>
        {newRow?.is_online_user ? (
          <span className="OnlineHighlight">Online</span>
        ) : (
          ""
        )}
      </div>
      {/* avalible cards show  */}

      {availableSections.length > 0 && (
        <p
          style={{
            backgroundColor: "#50C878",
            color: "white",
            padding: "0 5px",
            wordWrap: "break-word",
            borderTop: "2px solid white",
            borderBottom: "2px solid white",
            borderLeft: "2px solid white",
            borderRight: "2px solid white",
          }}
        >
          Available in - {availableSections.join(" | ")}
        </p>
      )}
      {/* end avalible cards show  */}
      <div className="p-2 px-3" style={{ background: "#fff" }}>
        {/* Header Section start*/}
        <div className="row pb-2">
          <div className="col-lg-4">
            <span className="secondaryColor fw-500 fs-14 px-2">
              ES :{" "}
              <span style={{ color: "#000" }}>
                {newRow?.ESTAFFS?.name?.english}
              </span>
            </span>
            <hr className="my-1" />
            <span className="fs-12">{`${moment(newRow?.created_at).format(
              "DD-MM-YY, h:mm"
            )}`}</span>
          </div>
          {newRow?.whatsapp_status && (
            <div className="col-lg-4">
              <span className="secondaryColor fw-500 fs-14">
                WS :{" "}
                <span style={{ color: "#000" }}>
                  {newRow?.WSTAFFS?.name?.english}
                </span>
              </span>
              <hr className="my-1" />
              <span className="fs-12">{`${moment(
                newRow?.whatsapp_date_time
              ).format("DD-MM-YY, h:mm")}`}</span>
            </div>
          )}
          {newRow?.call_status && (
            <div className="col-lg-4">
              <span className="secondaryColor fw-500 fs-14 px-2">
                CS :
                <span style={{ color: "#000" }}>
                  {newRow?.CSTAFFS?.name?.english}
                </span>
              </span>
              <hr className="my-1" />
              <span className="fs-12">{`${moment(newRow?.call_date_time).format(
                "DD-MM-YY, h:mm"
              )}`}</span>
            </div>
          )}
        </div>
        {/* Header Section end */}

        {/* Second section start */}
        <div className="displayBtwPro">
          <div>
            {newRow?.mobile?.split(",").map((item) => (
              <Button
                className="roundBtn"
                variant="outlined"
                size="small"
                onClick={() => {
                  handleCopyMobile(maskNumber(item));
                }}
              >
                {maskNumber(item)}
                <ContentCopyOutlinedIcon className="fs-16 ms-2" />
              </Button>
            ))}
          </div>
          <div>
            <button
              className="WtsBtn"
              style={{
                borderColor: "blue",
                color: newRow?.whatsapp_status ? "#fff" : "#525FFE",
                background: newRow?.whatsapp_status ? "#525FFE" : "transparent",
              }}
              onClick={() => {
                onStatus({
                  whatsapp_status: !newRow?.whatsapp_status,
                  whatsapp_date_time: date,
                  whatsapp_action_id: contxt?.user?.id,
                });
              }}
            >
              WA
            </button>

            <button
              className="WtsBtn"
              style={{ borderColor: "#32BE8F", color: "#32BE8F" }}
              onClick={() => {
                whatsappNow(newRow?.mobile);
              }}
            >
              <WhatsAppIcon />
            </button>
            <button
              className="WtsBtn"
              style={{ borderColor: "blue", color: "blue" }}
              onClick={() => {
                callNow(newRow?.mobile);
              }}
            >
              <CallIcon />
            </button>
          </div>
        </div>
        {/* Second Section end */}

        {/* Third section start */}
        <div className="displayBtwPro " style={{ marginLeft: "-16px" }}>
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ minWidth: "37px" }}
              // onClick={handleNpOpenHistory} // Corrected inline style
            >
              {newRow?.total_count_calls}
            </Button>
            <Switch
              checked={newRow?.call_status ? true : false}
              onChange={() => {
                onStatus({
                  call_status: newRow?.call_status ? false : true,
                  call_date_time: date,
                  calling_action_id: contxt?.user?.id,
                });
              }}
            />
            <Typography
              variant="subtitle2"
              className="primaryColor fw-500 primaryColor"
            >
              Call
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ minWidth: "37px" }}
              // onClick={handleNpOpenHistory} // Corrected inline style
            >
              {newRow?.not_pick_call_history?.length}
            </Button>
            <Switch
              checked={newRow?.pickup_status ? true : false}
              onChange={() => {
                onStatus({
                  pickup_status: newRow?.pickup_status ? false : true,
                  pickup_date_time: date,
                });
              }}
            />
            <Typography
              variant="subtitle2"
              className="primaryColor fw-500 primaryColor"
            >
              NP
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ minWidth: "37px" }}
              // onClick={handleNpOpenHistory} // Corrected inline style
            >
              {newRow?.total_count_calls}
            </Button>
            <Switch
              checked={newRow?.not_connected_status ? true : false}
              onChange={() => {
                onStatus({
                  not_connected_status: newRow?.not_connected_status
                    ? false
                    : true,
                  not_connected_date_time: date,
                });
              }}
            />
            <Typography
              variant="subtitle2"
              className="primaryColor fw-500 primaryColor"
            >
              NC
            </Typography>
          </Box>
        </div>
        {/* Third section end  */}

        {/* Fourth Section start */}
        {newRow?.call_status && (
          <div>
            <div
              className="d-flex align-items-center flex-wrap"
              style={{ gap: "10px" }}
            >
              <Button
                size="small"
                variant={newRow?.sambandh_click ? "contained" : "outlined"}
                className="btnPadding"
              >
                Sambandh
              </Button>
              <Button
                size="small"
                variant={newRow?.share_status ? "contained" : "outlined"}
                className="btnPadding"
              >
                Share
              </Button>
              <Button
                size="small"
                variant={newRow?.later_status ? "contained" : "outlined"}
                className="btnPadding"
              >
                Later
              </Button>
              <Button
                size="small"
                variant={newRow?.special ? "contained" : "outlined"}
                className="btnPadding"
              >
                Special
              </Button>
              <Button
                size="small"
                variant={
                  newRow?.not_interested_status ? "contained" : "outlined"
                }
                className="btnPadding"
              >
                Not Interested
              </Button>
              <Button
                size="small"
                variant={newRow?.is_hindu ? "contained" : "outlined"}
                className="btnPadding"
              >
                Hindu
              </Button>
              <Button
                size="small"
                variant={newRow?.other_cast ? "contained" : "outlined"}
                className="btnPadding"
              >
                Other Cast
              </Button>
              <Button
                size="small"
                variant={newRow?.shadi_done_status ? "contained" : "outlined"}
                className="btnPadding"
              >
                Shadi Done
              </Button>
              <Button
                size="small"
                variant={newRow?.different_language ? "contained" : "outlined"}
                className="btnPadding"
              >
                Diff Language
              </Button>
            </div>
            {/* rating section start */}
            <div className="my-2">
              {ratingArray?.map((i) => (
                <Button
                  className="ratingSec"
                  key={i?.key}
                  variant={newRow?.rating === i?.key ? "contained" : "outlined"}
                  size="small"
                >
                  {i?.title}
                </Button>
              ))}
            </div>
            {/* rating section end */}

            {/* Sambandh section preview */}
            {newRow?.sambandh?.length > 0 && (
              <>
                {" "}
                {newRow?.sambandh?.map((val, ind) => {
                  return (
                    <div>
                      {val?.type == "0" && (
                        <div className="row align-items-center">
                          <div className="col-10 pe-0">
                            <p className="fs-12 darkText border-bottom px-1 ">
                              <span style={{ color: "#eb445a" }}>
                                <b>SF </b>{" "}
                              </span>
                              {val?.created_for ? ": " + val?.created_for : ""}
                              {val?.name ? "- " + val?.name : ""}
                              {val?.mobile
                                ? "- " + maskNumber(val?.mobile)
                                : ""}
                              {val?.relative_name.trim()
                                ? "- " + val?.relative_name
                                : ""}
                              {val?.age ? "- " + val?.age : ""}
                              {val?.employed_in ? "- " + val?.employed_in : ""}
                              {val?.marital_status
                                ? "- " + val?.marital_status
                                : ""}
                              {val?.sect ? "- " + val?.sect : ""}
                              {val?.state ? "- " + val?.state : ""}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                {newRow?.sambandh?.map((val, ind) => {
                  return (
                    <div>
                      {val?.type == "1" && (
                        <div className="row align-items-center">
                          <div className="col-10">
                            <p className="fs-12 darkText border-bottom px-1 ">
                              <span style={{ color: "#eb445a" }}>
                                <b>OF </b>{" "}
                              </span>
                              {val?.created_for ? ": " + val?.created_for : ""}
                              {val?.name ? ": " + val?.name : ""}
                              {val?.mobile
                                ? ": " + maskNumber(val?.mobile)
                                : ""}
                              {val?.relative_name
                                ? ": " + val?.relative_name
                                : ""}
                              {val?.employed_in ? "- " + val?.employed_in : ""}
                              {val?.marital_status
                                ? "- " + val?.marital_status
                                : ""}
                              {val?.sect ? "- " + val?.sect : ""}
                              {val?.state ? "- " + val?.state : ""}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            )}
            <div className="row ">
              <div className="col-12">
                {newRow?.partner_preference ? (
                  <span className="fs-12 ">
                    <b style={{ color: "#eb445a" }}>Partner Preference : </b>
                    {newRow?.partner_preference}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                {newRow?.different_language ? (
                  <span className="fs-12 ">
                    <b style={{ color: "#eb445a" }}>Different Language : </b>
                    {newRow?.different_language} |{" "}
                    {newRow?.different_language_notes}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                {newRow?.not_interested_notes ? (
                  <span className="fs-12 ">
                    <b style={{ color: "#eb445a" }}>Not Interested Reason : </b>
                    {newRow?.not_interested_notes}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* notes show */}
            <div className="row">
              {newRow?.other && (
                <div className="col-12 fs-14" style={{ color: "#525ffe" }}>
                  {newRow?.other
                    ? "Notes : " + newRow?.other + newRow?.notes
                    : "" + <br />}
                  <span className="fs-12 fw-500" style={{ color: "red" }}>
                    {" "}
                    [
                    {newRow?.notes_date_time
                      ? moment(newRow?.notes_date_time)?.format("DD-MM-YY") +
                        " , " +
                        moment(newRow?.notes_date_time)?.format("HH:mm")
                      : ""}
                    ]
                  </span>
                </div>
              )}
            </div>

            {/* notes show */}
            <div className="row ">
              {(newRow?.special || newRow?.special_notes) && (
                <div className="col-12 fs-14">
                  <span style={{ color: "#525ffe" }}>
                    {" "}
                    {newRow?.special
                      ? "Special Share : " + newRow?.special
                      : ""}{" "}
                    |{" "}
                  </span>
                  <span style={{ color: "#EB445A" }}>
                    {newRow?.special_notes ? newRow?.special_notes : ""}
                  </span>
                </div>
              )}
            </div>
            {/* notes show */}
            <div className="row">
              {newRow?.normal_share && (
                <div className="col-12 fs-14" style={{ color: "#EB445A" }}>
                  {newRow?.normal_share
                    ? "Share : " + newRow?.normal_share
                    : "" + <br />}
                </div>
              )}
            </div>

            {/* followup section */}
            {newRow?.later_status && (
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-14">
                  <span style={{ color: "red" }}>Follow up :</span>{" "}
                  {newRow?.follow_date ? (
                    <span style={{ fontSize: "13px" }}>
                      Date : {newRow?.follow_date}
                    </span>
                  ) : (
                    <span>Date : --- </span>
                  )}
                </div>
                <div className="fs-14">
                  {newRow?.follow_time ? (
                    <span style={{ fontSize: "13px" }}>
                      Time : {newRow?.follow_time}
                    </span>
                  ) : (
                    <span>Time : ---</span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        <div>
          <hr />
          {/* Share to section */}
          <div className="ShareSec">
            {newRow?.share_section ? (
              <p className="m-0">{newRow?.share_section}</p>
            ) : (
              <span>Share to</span>
            )}
          </div>

          {/* Add Pay/Bio/Pic section */}
          <div className="displayBtwPro">
            <Box display="flex" alignItems="center">
              <Switch
                checked={newRow?.biodataImage || newRow?.image ? true : false}
              />
              <Typography
                variant="subtitle2"
                className="primaryColor fw-500 primaryColor"
              >
                Add Bio/Pic
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Switch
                checked={newRow?.online_user_payment_status ? true : false}
              />
              <Typography
                variant="subtitle2"
                className="primaryColor fw-500 primaryColor"
              >
                Add Pay
              </Typography>
            </Box>
          </div>
          <div className="row">
            <div className="col-6">
              {newRow?.biodataImage || newRow?.image ? (
                <div className="row">
                  <div className="col-12">
                    {newRow?.biodataImage ? (
                      <Image
                        alt={newRow.value}
                        src={`${constant.appBaseUrl}/users/offline_users/${newRow?.biodataImage}`}
                        sx={{ borderRadius: 1 }}
                        style={{
                          width: "120px",
                          height: 100,
                          borderRadius: 8,
                          objectFit: "fill",
                        }}
                      />
                    ) : (
                      <Image
                        src={blankUSer}
                        alt="about-vision"
                        style={{
                          width: "120px",
                          height: 100,
                          borderRadius: 8,
                          objectFit: "fill",
                        }}
                      />
                    )}
                  </div>
                  <div className="col-12">
                    <div>
                      {newRow?.image ? (
                        <Image
                          alt={newRow.value}
                          src={`${constant.appBaseUrl}/users/offline_users/${newRow?.image}`}
                          sx={{ borderRadius: 1 }}
                          style={{
                            width: "120px",
                            height: 100,
                            borderRadius: 8,
                            objectFit: "fill",
                          }}
                        />
                      ) : (
                        <Image
                          src={blankUSer}
                          alt="about-vision"
                          style={{
                            width: "120px",
                            height: 100,
                            borderRadius: 8,
                            objectFit: "fill",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-6">
              {newRow?.online_user_payment_status ? (
                <div>
                  <div className="ShareSec">
                    {" "}
                    {newRow?.online_user_package ? (
                      <p className="m-0">{newRow?.online_user_package}</p>
                    ) : (
                      <span>Select...</span>
                    )}{" "}
                  </div>
                  <p className="my-2" style={{ borderBottom: "1px solid" }}>
                    {" "}
                    {newRow?.online_user_done_amount ? (
                      <p className="m-0">{newRow?.online_user_done_amount}</p>
                    ) : (
                      ""
                    )}
                  </p>
                  {newRow?.online_user_payment_screenshot ? (
                    <Image
                      alt={newRow.value}
                      src={`${constant.appBaseUrl}/users/payment_screen_shot/${newRow?.online_user_payment_screenshot}`}
                      sx={{ borderRadius: 1 }}
                      style={{
                        width: "120px",
                        height: 100,
                        borderRadius: 8,
                        objectFit: "fill",
                      }}
                    />
                  ) : (
                    <Image
                      src={blankUSer}
                      alt="about-vision"
                      style={{
                        width: "120px",
                        height: 100,
                        borderRadius: 8,
                        objectFit: "fill",
                      }}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* Biodata Notes */}
          {newRow?.IbiodataResponses?.IbiodataNotHistory?.length > 0 &&
            newRow?.IbiodataResponses?.IbiodataNotHistory?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Biodata :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.IbiodataResponses ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.IbiodataResponses?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Bio Call :- &nbsp;
                  <span style={{ color: "Blue" }}>
                    {moment(newRow?.IbiodataResponses?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.IbiodataResponses?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "Blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.IbiodataResponses?.ICallbiodataStaff?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.IbiodataResponses?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Bio NP :- &nbsp;
                  <span style={{ color: "Blue" }}>
                    {moment(
                      newRow?.IbiodataResponses?.not_pickup_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.IbiodataResponses?.not_pickup_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "Blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.IbiodataResponses?.ICallbiodataStaff?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.IbiodataResponses?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Bio NC :- &nbsp;
                  <span style={{ color: "Blue" }}>
                    {moment(
                      newRow?.IbiodataResponses?.not_connected_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.IbiodataResponses?.not_connected_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "Blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.IbiodataResponses?.ICallbiodataStaff?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.IbiodataResponses?.normal_share && (
                <span style={{ color: "red" }}>
                  Bio Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.IbiodataResponses?.normal_share}
                    {newRow?.IbiodataResponses?.share_notes}
                  </span>{" "}
                  &nbsp;
                </span>
              )}
              {newRow?.IbiodataResponses?.special_notes && (
                <span style={{ color: "red" }}>
                  Bio Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.IbiodataResponses?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.IbiodataResponses?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Bio Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.IbiodataResponses?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.IbiodataResponses?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Bio Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.IbiodataResponses?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.IbiodataResponses?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Bio Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.IbiodataResponses?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.IbiodataResponses?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Bio Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.IbiodataResponses?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* offline start */}
          {newRow?.IofflineResponses?.IoffNoteHistory?.length > 0 &&
            newRow?.IofflineResponses?.IoffNoteHistory?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Offline :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.IOffStaffName?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.IOffStaffName?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.IofflineResponses ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.IofflineResponses?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Offline Call :- &nbsp;
                  <span style={{ color: "Blue" }}>
                    {moment(newRow?.IofflineResponses?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.IofflineResponses?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "Blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.IofflineResponses?.ICallOffStaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.IofflineResponses?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Offline NP :- &nbsp;
                  <span style={{ color: "Blue" }}>
                    {moment(
                      newRow?.IofflineResponses?.not_pickup_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.IofflineResponses?.not_pickup_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "Blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.IofflineResponses?.ICallOffStaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.IofflineResponses?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Offline NC :- &nbsp;
                  <span style={{ color: "Blue" }}>
                    {moment(
                      newRow?.IofflineResponses?.not_connected_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.IofflineResponses?.not_connected_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "Blue" }}>
                    &nbsp; Name :-{" "}
                    {
                      newRow?.IofflineResponses?.ICallOffStaffName?.name
                        ?.english
                    }
                  </span>
                </p>
              )}
              {newRow?.IofflineResponses?.normal_share && (
                <span style={{ color: "red" }}>
                  Offline Share Note : -{" "}
                  <span className="textDark">
                    {" "}
                    {newRow?.IofflineResponses?.normal_share}{" "}
                    {newRow?.IofflineResponses?.share_notes} &nbsp;
                  </span>
                </span>
              )}
              {newRow?.IofflineResponses?.special_notes && (
                <span style={{ color: "red" }}>
                  Offline Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.IofflineResponses?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.IofflineResponses?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Offline Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.IofflineResponses?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.IofflineResponses?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Offline Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.IofflineResponses?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.IofflineResponses?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Offline Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.IofflineResponses?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.IofflineResponses?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Offline Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.IofflineResponses?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* offline end */}

          {/* Whatsapp start */}
          {newRow?.IwhatsappResponses?.IwhatsappResponseshistory?.length > 0 &&
            newRow?.IwhatsappResponses?.IwhatsappResponseshistory?.map(
              (val) => {
                return (
                  <div className="d-flex align-items-center">
                    <div className="fs-14">
                      <span className="fw-500" style={{ color: "red" }}>
                        Whatsapp Response :-
                      </span>
                      {val?.other}
                      <span style={{ color: "#884A39", fontWeight: "500" }}>
                        {val?.notes}{" "}
                      </span>
                      {val?.notes_date_time ? (
                        <span style={{ color: "#EB445A", fontWeight: "500" }}>
                          [{val?.WbiodataStaff?.name?.english}{" "}
                          {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                          {moment(val?.notes_date_time).format("DD/MM/YY")}
                          {val?.WbiodataStaff?.public_view === 0 ? (
                            <span style={{ color: "blue" }}>(Old Staff)</span>
                          ) : null}
                          ]
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              }
            )}
          {newRow?.IwhatsappResponses ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.IwhatsappResponses?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.IwhatsappResponses?.call_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.IwhatsappResponses?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.IwhatsappResponses?.ICallstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.IwhatsappResponses?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(
                      newRow?.IwhatsappResponses?.not_pickup_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.IwhatsappResponses?.not_pickup_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.IwhatsappResponses?.ICallstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.IwhatsappResponses?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(
                      newRow?.IwhatsappResponses?.not_connected_date_time
                    ).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(
                      newRow?.IwhatsappResponses?.not_connected_date_time
                    ).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.IwhatsappResponses?.ICallstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.IwhatsappResponses?.normal_share && (
                <span style={{ color: "red" }}>
                  Whatsapp Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.IwhatsappResponses?.normal_share}
                    {newRow?.IwhatsappResponses?.share_notes}
                  </span>{" "}
                  &nbsp;
                </span>
              )}
              {newRow?.IwhatsappResponses?.special_notes && (
                <span style={{ color: "red" }}>
                  Whatsapp Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.IwhatsappResponses?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.IwhatsappResponses?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.IwhatsappResponses?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.IwhatsappResponses?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.IwhatsappResponses?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.IwhatsappResponses?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.IwhatsappResponses?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.IwhatsappResponses?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Whatsapp Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.IwhatsappResponses?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* Whatsapp end */}

          {/* group services */}
          {newRow?.IGroupS?.IGroupSer?.length > 0 &&
            newRow?.IGroupS?.IGroupSer?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Group Service :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

          {newRow?.IGroupS ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.IGroupS?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.IGroupS?.call_date_time).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(newRow?.IGroupS?.call_date_time).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.IGroupS?.ICallGstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.IGroupS?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Group NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.IGroupS?.not_pickup_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.IGroupS?.not_pickup_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.IGroupS?.ICallGstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.IGroupS?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Group NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.IGroupS?.not_connected_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.IGroupS?.not_connected_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.IGroupS?.ICallGstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.IGroupS?.normal_share && (
                <span style={{ color: "red" }}>
                  Group Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.IGroupS?.normal_share}
                    {newRow?.IGroupS?.share_notes}
                  </span>
                </span>
              )}
              {newRow?.IGroupS?.special_notes && (
                <span style={{ color: "red" }}>
                  Group Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.IGroupS?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.IGroupS?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Different Language : -
                  <span className="textDark">
                    {newRow?.IGroupS?.different_language_notes}
                  </span>{" "}
                </p>
              )}
              {newRow?.IGroupS?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.IGroupS?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.IGroupS?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.IGroupS?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.IGroupS?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Group Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.IGroupS?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* Paid Promotion */}
          {newRow?.IPaidPa?.IPaidPhistory?.length > 0 &&
            newRow?.IPaidPa?.IPaidPhistory?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Paid Promotion :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                    {newRow?.IPaidPa?.call_status && [
                      <p
                        className="m-0"
                        style={{
                          color: "blue",
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        Paid Promotion Call :- &nbsp;
                        <span>
                          {moment(newRow?.IPaidPa?.call_date_time).format(
                            "hh:mm a"
                          )}{" "}
                          ,{" "}
                          {moment(newRow?.IPaidPa?.call_date_time).format(
                            "DD/MM/YY"
                          )}
                        </span>
                        <span>
                          &nbsp; Name :-{" "}
                          {newRow?.IPaidPa?.ICallPstaffName?.name?.english}
                        </span>
                      </p>,
                    ]}
                    {newRow?.IPaidPa?.pickup_status && [
                      <p
                        className="m-0"
                        style={{
                          color: "blue",
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        Paid Promotion NP :- &nbsp;
                        <span>
                          {moment(newRow?.IPaidPa?.pickup_date_time).format(
                            "hh:mm a"
                          )}{" "}
                          ,{" "}
                          {moment(newRow?.IPaidPa?.pickup_date_time).format(
                            "DD/MM/YY"
                          )}
                        </span>
                        <span>
                          &nbsp; Name :-{" "}
                          {newRow?.IPaidPa?.ICallPstaffName?.name?.english}
                        </span>
                      </p>,
                    ]}
                    {newRow?.IPaidPa?.not_connected_status && [
                      <p
                        className="m-0"
                        style={{
                          color: "blue",
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        Paid Promotion NC :- &nbsp;
                        <span>
                          {moment(
                            newRow?.IPaidPa?.not_connected_date_time
                          ).format("hh:mm a")}{" "}
                          ,{" "}
                          {moment(
                            newRow?.IPaidPa?.not_connected_date_time
                          ).format("DD/MM/YY")}
                        </span>
                        <span>
                          &nbsp; Name :-{" "}
                          {newRow?.IPaidPa?.ICallPstaffName?.name?.english}
                        </span>
                      </p>,
                    ]}
                  </div>
                </div>
              );
            })}
          {newRow?.IPaidPa ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.IPaidPa?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.IPaidPa?.call_date_time).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(newRow?.IPaidPa?.call_date_time).format("DD/MM/YY")}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.IPaidPa?.ICallPstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.IPaidPa?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.IPaidPa?.not_pickup_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.IPaidPa?.not_pickup_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.IPaidPa?.ICallPstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.IPaidPa?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.IPaidPa?.not_connected_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.IPaidPa?.not_connected_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.IPaidPa?.ICallPstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.IPaidPa?.normal_share && (
                <span style={{ color: "red" }}>
                  Paid Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.IPaidPa?.normal_share}
                    {newRow?.IPaidPa?.share_notes}
                  </span>
                </span>
              )}
              {newRow?.IPaidPa?.special_notes && (
                <span style={{ color: "red" }}>
                  Paid Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.IPaidPa?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.IPaidPa?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.IPaidPa?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.IPaidPa?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.IPaidPa?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.IPaidPa?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.IPaidPa?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.IPaidPa?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Paid Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.IPaidPa?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* Social Media */}
          {newRow?.ISocialM?.ISocialHistory?.length > 0 &&
            newRow?.ISocialM?.ISocialHistory?.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span className="fw-500" style={{ color: "red" }}>
                      Social Media :-
                    </span>
                    {val?.other}
                    <span style={{ color: "#884A39", fontWeight: "500" }}>
                      {val?.notes}{" "}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.WbiodataStaff?.name?.english}{" "}
                        {moment(val?.notes_date_time).format("hh:mm")} ,{" "}
                        {moment(val?.notes_date_time).format("DD/MM/YY")}
                        {val?.WbiodataStaff?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          {newRow?.ISocialM ? (
            <div style={{ fontWeight: "600", fontSize: "12px" }}>
              {newRow?.ISocialM?.call_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Call :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.ISocialM?.call_date_time).format("hh:mm a")}{" "}
                    ,{" "}
                    {moment(newRow?.ISocialM?.call_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.ISocialM?.ICallSstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.ISocialM?.not_pickup_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Social NP :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.ISocialM?.not_pickup_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.ISocialM?.not_pickup_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.ISocialM?.ICallSstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.ISocialM?.not_connected_status && (
                <p className="m-0" style={{ color: "red" }}>
                  Social NC :- &nbsp;
                  <span style={{ color: "blue" }}>
                    {moment(newRow?.ISocialM?.not_connected_date_time).format(
                      "hh:mm a"
                    )}{" "}
                    ,{" "}
                    {moment(newRow?.ISocialM?.not_connected_date_time).format(
                      "DD/MM/YY"
                    )}
                  </span>
                  <span style={{ color: "blue" }}>
                    &nbsp; Name :-{" "}
                    {newRow?.ISocialM?.ICallSstaffName?.name?.english}
                  </span>
                </p>
              )}
              {newRow?.ISocialM?.normal_share && (
                <span style={{ color: "red" }}>
                  Social Share Note : -{" "}
                  <span className="textDark">
                    {newRow?.ISocialM?.normal_share}
                    {newRow?.ISocialM?.share_notes}
                  </span>
                </span>
              )}
              {newRow?.ISocialM?.special_notes && (
                <span style={{ color: "red" }}>
                  Social Special Share : -{" "}
                  <span className="textDark">
                    {newRow?.ISocialM?.special_notes}
                  </span>
                </span>
              )}
              {newRow?.ISocialM?.different_language_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Different Language : -{" "}
                  <span className="textDark">
                    {newRow?.ISocialM?.different_language_notes}
                  </span>
                </p>
              )}
              {newRow?.ISocialM?.not_interested_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Not Interested :-{" "}
                  <span className="textDark">
                    {newRow?.ISocialM?.not_interested_notes}
                  </span>
                </p>
              )}
              {newRow?.ISocialM?.sambandh_custom_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Sambandh Custom Note :-{" "}
                  <span className="textDark">
                    {newRow?.ISocialM?.sambandh_custom_notes}
                  </span>
                </p>
              )}
              {newRow?.ISocialM?.sambandh_notes && (
                <p className="m-0" style={{ color: "red" }}>
                  Social Sambandh :-{" "}
                  <span className="textDark">
                    {newRow?.ISocialM?.sambandh_notes}
                  </span>
                </p>
              )}
            </div>
          ) : null}
          {/* Incoming Notes */}
          {newRow?.IncomingUserNote &&
            newRow?.IncomingUserNote.map((val) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="fs-14">
                    <span style={{ color: "blue" }}>Incoming Note :-</span>
                    {val?.created_for}
                    {val.other}
                    <span
                      className="fs-14"
                      style={{ color: "red", fontWeight: "500" }}
                    >
                      {val?.notes}
                    </span>
                    {val?.notes_date_time ? (
                      <span style={{ color: "#EB445A", fontWeight: "500" }}>
                        [{val?.NotesByStaffIncoming?.name?.english}
                        {`${moment(val?.notes_date_time).format(
                          "DD/MM/YY, hh:mm"
                        )}`}
                        {val?.NotesByStaffIncoming?.public_view === 0 ? (
                          <span style={{ color: "blue" }}>(Old Staff)</span>
                        ) : null}
                        ]
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

          {/* Note Section */}
          <div>
            <Box
              display="flex"
              sx={{
                background: "#f2f2fe",
                color: "blue",
                padding: "5px",
                borderRadius: "8px",
              }}
            >
              <Typography variant="subtitle2"> Note : &nbsp;</Typography>
              {newRow?.notes && (
                <Typography variant="subtitle2">
                  {newRow?.notes} [{newRow?.ESTAFFS?.name?.english},
                  {`${moment(newRow?.created_at).format("DD-MM-YY, h:mm")}`}]
                </Typography>
              )}
            </Box>
            <Box display="flex" sx={{ padding: "5px" }}>
              <Typography variant="overline"> Cost : &nbsp;</Typography>
              {newRow?.final_cost && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "normal",
                    color: "rgb(105, 105, 105)",
                    fontSize: 12.5,
                    color: "text.secondary",
                  }}
                >
                  {newRow?.final_cost}
                </Typography>
              )}
            </Box>
          </div>
        </div>
        {/* Fourth Section end */}

        {/* Last Button Section start */}
        <Grid container sx={{ textAlign: "center", padding: "10px 0px" }}>
          {contxt?.user?.sections?.offlineUsers?.delete ? (
            <Grid item lg={3} md={3} sm={6} xs={6} mb={{ sm: 1, xs: 1 }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  handleOpenConfirm();
                }}
              >
                Delete
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {contxt?.user?.sections?.offlineUsers?.update ? (
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                onClick={() => {
                  setUpdate({ ...newRow });
                  setShowUpdate(false);
                  goToTop();
                }}
              >
                Update
              </Button>
            </Grid>
          ) : (
            <></>
          )}

          {contxt?.user?.sections?.offlineUsers?.update ? (
            <>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setNoteModal(true);
                    setUpdate({ ...newRow });
                  }}
                >
                  Notes
                </Button>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCostModal(true);
                    setUpdate({ ...newRow });
                  }}
                >
                  Cost
                </Button>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
        {/* Last Button Section end  */}

        {/* Create Profile */}
        {newRow?.createProfile && (
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Button
                  variant="outlined"
                  size={"small"}
                  className={"btnPadding"}
                  onClick={() => setOpen(!open)}
                >
                  Create Profile
                </Button>
                <Button
                  variant="outlined"
                  size={"small"}
                  className={"btnPadding"}
                  onClick={handleProfileDone}
                >
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </div>
      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={mobileModel}
        onClose={() => {
          setMobileModel(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setMobileModel(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Edit Mobile"}
            />

            <div>
              <TextField
                multiline
                fullWidth
                label="Mobile"
                type="number"
                placeholder="Type  here.."
                value={mobileValue}
                onChange={(e) => {
                  setMobileValue(e.target.value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    onStatus({
                      mobile: mobileValue,
                      mobiles: {
                        mobile: mobileValue,
                        is_whatsapp: update?.mobiles?.is_whatsapp,
                        is_call_done: update?.mobiles?.is_call_done,
                        time: new Date(),
                      },
                    });
                    setNewRow({ ...newRow, mobile: mobileValue });
                    setMobileModel(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />
      {/* add notes */}
      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={noteModal}
        onClose={() => {
          setNoteModal(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setNoteModal(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Add Notes"}
            />

            <div>
              <TextField
                multiline
                fullWidth
                label="Note"
                placeholder="Type  here.."
                value={note}
                onChange={(e, value) => {
                  setNoteModalValue(e.target.value);
                  setNote(value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    onStatus({ notes: noteModalValue });
                    setNewRow({ ...newRow, notes: noteModalValue });
                    setNoteModal(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />

      <ConfirmDialog
        className="onlineModal"
        maxWidth={"sm"}
        open={costModal}
        onClose={() => {
          setCostModal(false);
        }}
        content={
          <>
            <CardHeader
              style={{ padding: "15px 0px" }}
              action={
                <Tooltip title="Close">
                  <IconButton
                    color="error"
                    className="CloseBtn"
                    onClick={() => {
                      setCostModal(false);
                    }}
                  >
                    <Iconify icon="mdi:cancel-circle-outline" />
                  </IconButton>
                </Tooltip>
              }
              title={"Add Cost"}
            />

            <div>
              <TextField
                multiline
                fullWidth
                label="Cost"
                placeholder="Type  here.."
                value={cost}
                onChange={(e, value) => {
                  setCostValue(e.target.value);
                  setCost(value);
                }}
              />
              <div style={{ textAlign: "center" }}>
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  loadingPosition="center"
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 3,
                    width: "30%",
                    bgcolor: "text.primary",
                    typography: "body1",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                  onClick={() => {
                    onStatus({ final_cost: costValue });
                    setNewRow({ ...newRow, final_cost: costValue });
                    setCostModal(false);
                  }}
                >
                  Add
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />
      <StaffToPayNewUser
        open={open}
        cardType={"incoming"}
        getPendingRavcListData={getPendingRavcListData}
        // getOnlineUserListFunc={getOnlineUserListFunc}
        handleClose={handleClose}
        filterDetail={filterDetail}
      />
    </div>
  );
}
