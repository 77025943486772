import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  IconButton,
  MenuItem,
  Grid,
  Box,
  CardHeader,
  Tooltip,
  Dialog,
  DialogContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/iconify";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { newUserStaffToPay } from "src/service";
import { useSnackbar } from "../../components/snackbar";

const children = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
];

const sect = [
  { id: 1, name: "Digambar" },
  { id: 2, name: "Shwetambar" },
];

const gender = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
];

const createdFor = [
  { id: 1, name: "Self" },
  { id: 2, name: "Son" },
  { id: 3, name: "Daughter" },
  { id: 4, name: "Sister" },
  { id: 5, name: "Relative" },
  { id: 6, name: "Friend" },
];

const maritalStatus = [
  { id: 1, name: "Unmarried" },
  { id: 2, name: "Widow/widower" },
  { id: 3, name: "Divorce" },
  { id: 4, name: "Awiting divorce" },
];

export default function StaffToPayNewUser({
  open,
  cardType,
  handleClose,
  getOnlineUserListFunc,
  filterDetail,
}) {
  const [show, setShow] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setloading] = useState(false);

  const age = [];
  for (var i = 14; i <= 80; i++) {
    age.push(i);
  }

  const LoginSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile Number must be in 10 digit")
      .required("Mobile Number is required"),
    nameEnglish: Yup.string().required("Name is required"),
    first_formGenderEnglish: Yup.string().required("Gender is required"),
    first_formProfileEnglish: Yup.string().required("Created for is required"),
    second_formStateEnglish: Yup.string().required("State is required"),
    third_formSectEnglish: Yup.string().required("Sect is required"),
    _age: Yup.string().required("Age is required"),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    reset,
    watch,
    getValues,
    setError,
    handleSubmit,
    formState: {},
  } = methods;

  const watchChildren = watch("first_formMaritalStatusEnglish");
  useEffect(() => {
    let v = getValues("first_formMaritalStatusEnglish");
    if (v === "Widow/widower" || v === "Divorce" || v === "Awiting divorce")
      setShow(true);
    else setShow(false);
  }, [watchChildren]);

  const onSubmit = async (data) => {
    let { mobile } = data;

    if (mobile.startsWith("+91")) {
      mobile = mobile.slice(3);
    }

    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(mobile)) {
      alert("Please enter a valid mobile number with 10 digits without +91");
      return;
    }

    setloading(true);
    let obj = {
      mobile: data?.mobile,
      nameEnglish: data?.nameEnglish,
      first_formGenderEnglish: data?.first_formGenderEnglish,
      first_formMaritalStatusEnglish: data?.first_formMaritalStatusEnglish,
      first_formProfileEnglish: data?.first_formProfileEnglish,
      first_formNoOfChildren: data?.first_formNoOfChildren,
      second_formStateEnglish: data?.second_formStateEnglish,
      third_formSectEnglish: data?.third_formSectEnglish,
      _age: data?._age,
      staff_card_type: cardType,
    };

    try {
      let res = await newUserStaffToPay(obj);
      if (res?.data?.status) {
        getOnlineUserListFunc({ task: "pending" });
        enqueueSnackbar(res.data.message);
        handleClose();
      } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    } catch (error) {
      console.error(error);
      reset();
      setError("afterSubmit", {
        ...error,
        message: error.message,
      });
    }
    setloading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
      <CardHeader
        action={
          <Tooltip title="Close">
            <IconButton
              color="error"
              className="CloseBtn"
              onClick={() => {
                handleClose();
              }}
            >
              <Iconify icon="mdi:cancel-circle-outline" />
            </IconButton>
          </Tooltip>
        }
        title={"New User"}
        sx={{ ml: 47 }}
      />
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                rowGap={3}
                columnGap={2}
                // display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <Stack spacing={3} sx={{ mt: 4, ml: 25, width: "50%" }}>
                  <RHFTextField
                    name="mobile"
                    label="Mobile Number"
                    helperText="Number without +91"
                  />
                  <RHFTextField name="nameEnglish" label="Name" />
                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="first_formGenderEnglish"
                    label="Select Gender"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {gender?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="_age"
                    label="Select Age"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {age?.map((i) => (
                      <MenuItem
                        key={i}
                        value={i}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="third_formSectEnglish"
                    label="Select Sect"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {sect?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i?.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i?.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="first_formProfileEnglish"
                    label="Created For"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {createdFor?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="first_formMaritalStatusEnglish"
                    label="Marital status"
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {maritalStatus?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i.name}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  {show && (
                    <RHFTextField
                      native
                      fullWidth
                      select
                      name="first_formNoOfChildren"
                      label="Select No of Children"
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: "auto" },
                        textTransform: "capitalize",
                      }}
                    >
                      {children?.map((i) => (
                        <MenuItem
                          key={i?.id}
                          value={i.name}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {i.name}
                        </MenuItem>
                      ))}
                    </RHFTextField>
                  )}

                  <RHFTextField
                    native
                    fullWidth
                    select
                    name="second_formStateEnglish"
                    label="Select State"
                    // onChange={(e) => { getCityList(e.target.value) }}
                    SelectProps={{
                      MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                    }}
                    sx={{
                      maxWidth: { sm: "auto" },
                      textTransform: "capitalize",
                    }}
                  >
                    {/* <MenuItem value="" /> */}
                    {filterDetail?.stateList?.map((i) => (
                      <MenuItem
                        key={i?.id}
                        value={i?.english}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: "body2",
                          textTransform: "capitalize",
                          "&:first-of-type": { mt: 0 },
                          "&:last-of-type": { mb: 0 },
                        }}
                      >
                        {i?.english}
                      </MenuItem>
                    ))}
                  </RHFTextField>
                </Stack>
                <div style={{ textAlign: "center" }}>
                  <LoadingButton
                    color="inherit"
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    // onClick={(e) => {
                    //     userCreated();
                    // }}
                    // loading={isSubmitSuccessful || isSubmitting}
                    sx={{
                      my: 2,
                      bgcolor: "text.primary",
                      width: 220,
                      justifyContent: "center",
                      typography: "body1",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                      "&:hover": {
                        bgcolor: "text.primary",
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "common.white"
                            : "grey.800",
                      },
                    }}
                  >
                    ADD
                  </LoadingButton>
                </div>
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
