import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Grid, Card, CardContent, Box } from "@mui/material";
import LoadingScreenSmall from "src/components/loading-screen/LoadingScreenSmall";
import { useEffect } from "react";
import {
  getDetailList,
  getStaffNumberAPI,
  getStateListAPI,
  bioDataDetail,
  getNotes,
  DiffrentUserApi,
  SuspendUserApi,
} from "src/service";
import { TableNoData, useTable } from "src/components/table";
import BioDataCard from "./BioDataCard";

// ----------------------------------------------------------------------

export default function BiodataGobalCard({ bioDataUserList, type, show }) {
  const user = JSON.parse(localStorage.getItem("sections"));
  const { page, rowsPerPage, setPage } = useTable();
  const [filterDetail, setFilterDetail] = useState({});
  const [isNotFound, setIsNotFound] = useState(false);
  const [filterMobile, setFilterMobile] = useState([""]);
  const [filterRole, setFilterRole] = useState("mobile");
  const [filter, setFilter] = useState({});
  const [biodataDetail, setBioDataDetail] = useState([]);
  const [showDifferent, setShowDifferent] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  console.log("totalUsers ::::", totalUsers);
  const [totalDifferent, setTotalDifferent] = useState(0);
  const [totalSuspended, setTotalSuspended] = useState(0);
  const [staffNumber, setStaffNumber] = useState([]);
  const [showUpdate, setUpdate] = useState({ show: true });
  const [bioNotes, setNotes] = useState([]);
  const [selectedId, setSelectedId] = useState([]);

  useEffect(() => {
    var obj = {};
    if (filterMobile) obj.mobile = filterMobile;
    if (filterMobile[0]?.length > 9) getBiodataDetail(obj);
  }, [page, rowsPerPage, filterMobile, filterRole, type]);

  useEffect(() => {
    getStaffNumber();
    getStateList();
    getDifferentDetail();
    getSuspendedDetail();
  }, []);

  useEffect(() => {
    setFilterMobile("");
    getBiodataDetail(page, rowsPerPage, filter);
  }, [filter, page, rowsPerPage, type, showDifferent, showSuspend]);

  const getBiodataDetail = async (page, rowsPerPage, filter) => {
    setBioDataDetail([]);

    try {
      if (!showDifferent && !showSuspend) {
        let res = await bioDataDetail(page, rowsPerPage, filter);
        setBioDataDetail(res?.data?.data?.users);
        setTotalUsers(res?.data?.data?.total);
        if (res?.data?.data?.users?.length === 0) {
          setIsNotFound(true);
        } else {
          setIsNotFound(false);
        }
      }
      if (showDifferent) {
        let res = await DiffrentUserApi();
        const data = res?.data?.data?.diffrent.filter(
          (item) => item.types === "biodata"
        );
        setBioDataDetail(data);
      }
      if (showSuspend) {
        let res = await SuspendUserApi();
        const data = res?.data?.data?.diffrent.filter(
          (item) => item.types === "biodata"
        );
        setBioDataDetail(data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getDifferentDetail = async () => {
    try {
      let res = await DiffrentUserApi();
      setTotalDifferent(res.data?.data?.diffrentCount);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getSuspendedDetail = async () => {
    try {
      let res = await SuspendUserApi();
      setTotalSuspended(res?.data?.data?.total);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getStateList = async () => {
    var filterDetails = await getDetailList();
    let stateList = await getStateListAPI("India");
    setFilterDetail({
      ...filterDetails?.data?.data,
      stateList: stateList?.data.data,
    });
    let res1 = await getNotes();
    setNotes(res1?.data?.data);
  };

  const getStaffNumber = async () => {
    let res = await getStaffNumberAPI();
    setStaffNumber(
      res?.data?.data?.map((val) => ({
        label: val?.number,
        value: val?.number,
      }))
    );
  };

  const handleFilterMobile = (event) => {
    setPage(0);
    setFilterMobile([event?.target?.value]);
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleToggleAll = (rowId) => {
    if (selectedId.includes(rowId)) {
      setSelectedId(selectedId.filter((id) => id !== rowId));
    } else {
      setSelectedId([...selectedId, rowId]);
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          {show && (
            <div className="CardNameShow">
              <span>Biodata Section</span>
            </div>
          )}
          <Grid container spacing={4}>
            {bioDataUserList?.map((bioData) => {
              return (
                <Grid item lg={5} md={6} sm={6} xs={12}>
                  <BioDataCard
                    handleToggleAll={handleToggleAll}
                    selectedId={selectedId}
                    bioData={bioData}
                    setUpdate={setUpdate}
                    staffNumber={staffNumber}
                    getBiodataDetail={getBiodataDetail}
                    bioNotes={bioNotes}
                    goToTop={goToTop}
                    filterDetail={filterDetail}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Box display="flex" justifyContent="center" alignItems="center">
            <TableNoData isNotFound={isNotFound} />
          </Box>
          <Box display="grid">
            {!biodataDetail?.length && !isNotFound && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <LoadingScreenSmall />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------
