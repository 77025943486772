// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import { StyledChart } from "./components/chart";
import SnackbarProvider from "./components/snackbar";
import { ThemeSettings } from "./components/settings";
import { MotionLazyContainer } from "./components/animate";
import "./theme/global.css";
import "./theme/Style.css";
import { useAuthContext } from "./auth/useAuthContext";
import { useEffect, useState } from "react";
import { authorizeMe } from "./service";
import { me } from "./service";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";

// ----------------------------------------------------------------------

export default function App() {
  const contxt = useAuthContext();
  const [noChange] = useState("");
  const navigate = useNavigate();
  const [userData, setUser] = useState(null);

  useEffect(() => {
    handleRefresh();
  }, [noChange]);

  const compareAndMergeSections = (roles) => {
    const mergedSections = {};
    roles?.forEach((roleObj) => {
      roleObj.section.forEach((sectionObj) => {
        const sectionName = sectionObj.section;

        if (!mergedSections[sectionName]) {
          mergedSections[sectionName] = { ...sectionObj };
        } else {
          Object.keys(sectionObj).forEach((key) => {
            if (key === "section") return;

            if (!(key in mergedSections[sectionName])) {
              mergedSections[sectionName][key] = sectionObj[key];
            } else {
              mergedSections[sectionName][key] =
                mergedSections[sectionName][key] || sectionObj[key];
            }
          });
        }
      });
    });

    return mergedSections;
  };

  const handleRefresh = async () => {
    const accessToken =
      typeof window !== "undefined" ? localStorage.getItem("accessToken") : "";
    if (accessToken) {
      authorizeMe(accessToken);
      const response = await me();
      const { user, assignRole } = response.data.data;
      // var obj = {}
      // user?.assignRole?.section?.map(val => {
      //   obj[val.section] = val;
      // })
      // user.sections = obj;
      const mergedSections = compareAndMergeSections(assignRole);
      user.sections = mergedSections;
      setUser(user);
      contxt.setUserData(user);
    }
  };

  useEffect(() => {
    if (
      window.location.pathname.includes("app") &&
      contxt?.user?.sections?.ScannerData?.WorkFromHomeStaff
    ) {
      navigate("/viewWorkFromHomeStaffTask/jain/work");
    }

    if (
      window.location.pathname.includes("app") &&
      contxt?.user?.sections?.HinduScannerData?.WorkFromHomeStaff
    ) {
      navigate("/viewHinduWorkFromHomeStaffTask/hindu");
    }
  }, [
    contxt?.user?.sections?.HinduScannerData?.WorkFromHomeStaff,
    contxt?.user?.sections?.ScannerData?.WorkFromHomeStaff,
    window.location.pathname,
  ]);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <SnackbarProvider>
              <StyledChart />
              <Router />
            </SnackbarProvider>
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
